import axios from "axios";
import { deleteTask } from "../../redux/reduxStore";


function removeTask (csv, currentUser, task, dispatch) { 

    (async() => { 
        const removeTask  = await axios({
            method: 'post', 
            url: process.env.REACT_APP_SERVER_NAME,
            params: {tk: 'delete', csv: csv},
            data: {
                user_email: currentUser.email,
                user_teamUid: currentUser.teamUid,
                user_role: currentUser.role,
                task: {
                    taskId: task.id,
                }
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + currentUser.token
            }
        }).then((resp) => {
            if (resp.status === 200 && document.getElementById("csvDelete")) {
                document.getElementById("csvDelete").innerHTML = resp.data;
            }
        })
    })()
    if (csv === "no") {
        dispatch(deleteTask(task.id));
    }

    return;
}

export default removeTask;