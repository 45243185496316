import React, { useState } from "react";
import axios from "axios";

export default function PasswordReplace({currentUser, pwdChanging, setPwdChanging}) {

    const [validation, setValidation] = useState("");

    function changeActual(val) {
        setPwdChanging({...pwdChanging, actualPwd: val});
    }
    function changeNew1(val) {
        setPwdChanging({...pwdChanging, newPwd1: val});
    }
    function changeNew2(val) {
        setPwdChanging({...pwdChanging, newPwd2: val});
    }
    function changePwd () {
        if (pwdChanging.actualPwd.length < 6 || 
            pwdChanging.newPwd1.length < 6 || 
            pwdChanging.newPwd2.length < 6  ) {
            setValidation("6 caractères minimum");
        } else if (pwdChanging.newPwd1 !== pwdChanging.newPwd2) {
            setValidation("Confirmation érronnée");
        } else {
            setValidation("");
            (async() => {     
                const changePwd  = await axios({
                    method: 'post', 
                    url: process.env.REACT_APP_SERVER_NAME,
                    params: {pw: 'rp'},
                    data: {
                        user_email: currentUser.email,
                        old: pwdChanging.actualPwd,
                        new: pwdChanging.newPwd1
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + currentUser.token
                    }
                }).then ((resp) => {
                    if (resp.status === 200) {
                        
                    }
                })
            })()
            setPwdChanging({...pwdChanging, openWindow: !pwdChanging.openWindow})
        }
    }

    return (
        <div className="pwdInitializationContainer">
            <div className="pwdInitialization">
                <span 
                onClick={() => setPwdChanging({
                                                ...pwdChanging, 
                                                openWindow: !pwdChanging.openWindow
                                            })}>
                    Close
                </span>
                <div className="newPwdTitle">
                    <h3>Changez votre mot de passe</h3>
                    <span style={{"fontSize": "10px"}}>6 caractères minimum</span>
                </div>
                <div className="pwdFormUnity">
                    <label htmlFor="actualPwd" id="actualPwd" >Mot de passe actuel: </label>
                    <input 
                        type="password" 
                        id="actualPwd"
                        name="actualPwd"
                        value={pwdChanging.actualPwd}
                        onChange={(e) => changeActual(e.target.value)} /> 
                </div>
                <div className="pwdFormUnity">
                    <label htmlFor="newPwd1" id="newPwd1" >Nouveau mot de passe: </label>
                    <input 
                        type="password" 
                        id="newPwd1"
                        name="newPwd1"
                        value={pwdChanging.newPwd1}
                        onChange={(e) => changeNew1(e.target.value)} /> 
                </div>
                <div className="pwdFormUnity">
                    <label htmlFor="newPwd2" id="newPwd2" >Confirmez le: </label>
                    <input 
                        type="password" 
                        id="newPwd2"
                        name="newPwd2"
                        value={pwdChanging.newPwd2}
                        onChange={(e) => changeNew2(e.target.value)} /> 
                </div>
                <div style={{"color": "red"}}>
                    {validation}
                </div>
                <button className="accountSaving" onClick={changePwd}>
                    Remplacer
                </button>
            </div>
        </div>
    )
}
