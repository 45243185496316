import React, {useState} from "react";

export default function DispCheckQuiz({ currentUser, 
                                        setErrorMessage,
                                        quiz, 
                                        index,
                                        sendQuizToApi, 
                                        deployBuildForm,
                                        articleManagMode,
                                        quizType, 
                                        quizContent, 
                                        respContent,
                                        setRespContent, 
                                        sheetList, 
                                        setSheetList,
                                        infoColor,
                                        setInfoColor,
                                        sheetState }) {

    const [reloadSheet, setReloadSheet] = useState(true);                                       

    function presaveCheckData(tamponResp, checkNb) {
        setInfoColor("red");
        tamponResp.map((r, index) => {
            if (r[0].id === quiz[0].id) {
                if (checkNb === "check1") {       
                    tamponResp[index][0].check1 = document.getElementsByName(quiz[0].id + "check1")[0].checked ? '1' : '0';
                }
                if (checkNb === "check2") {
                    tamponResp[index][0].check2 = document.getElementsByName(quiz[0].id + "check2")[0].checked ? '1' : '0';
                }
                if (checkNb === "check3") {
                    tamponResp[index][0].check3 = document.getElementsByName(quiz[0].id + "check3")[0].checked ? '1' : '0';
                }
                if (checkNb === "check4") {
                    tamponResp[index][0].check4 = document.getElementsByName(quiz[0].id + "check4")[0].checked ? '1' : '0';
                }
                if (checkNb === "check5") {
                    tamponResp[index][0].check5 = document.getElementsByName(quiz[0].id + "check5")[0].checked ? '1' : '0';
                }
                if (checkNb === "check6") {
                    tamponResp[index][0].check6 = document.getElementsByName(quiz[0].id + "check6")[0].checked ? '1' : '0';
                }
                if (checkNb === "check7") {
                    tamponResp[index][0].check7 = document.getElementsByName(quiz[0].id + "check7")[0].checked ? '1' : '0';
                }
                if (checkNb === "check8") {
                    tamponResp[index][0].check8 = document.getElementsByName(quiz[0].id + "check8")[0].checked ? '1' : '0';
                }
                if (checkNb === "check9") {
                    tamponResp[index][0].check9 = document.getElementsByName(quiz[0].id + "check9")[0].checked ? '1' : '0';
                }
                if (checkNb === "check10") {
                    tamponResp[index][0].check10 = document.getElementsByName(quiz[0].id + "check10")[0].checked ? '1' : '0';
                }
            }
        }) 
        setRespContent(tamponResp);
        setReloadSheet(!reloadSheet);
    }
    
                                        
    return (
        <div className="sheetLine" key={quiz[0].id + "container"} 
           >
            <p style={{"padding": "0 15px", "color": infoColor}}>
                <span>{index + 1} - </span>
                {quiz[0].quiz_title}
                <span 
                    className="lengthIndicator"
                    style={{"color": quizContent.title && quizContent.title.length <= 2 ? "red" : "green"}}>
                    Choix multiple autorisé
                </span>
            </p>
            <div className="checkUnitContainer">
                { (sheetState === "en cours" && quiz[0].check_answer_1) && (
                    <div className="checkUnit">
                        {respContent[index][0].check1 === '1' && (
                            <input 
                                type="checkbox" name={quiz[0].id + "check1"}
                                onChange={() => presaveCheckData(respContent, "check1")} checked />
                        )}
                        { respContent[index][0].check1 !== '1' && (
                            <input
                                type="checkbox" name={quiz[0].id + "check1"}
                                onChange={() => presaveCheckData(respContent, "check1")} />
                        )}
                        <label>{quiz[0].check_answer_1}</label>
                    </div>
                )}
                { (sheetState !== "en cours" && quiz[0].check_answer_1 && respContent[index][0].check1 === '1') && (
                    <p>{quiz[0].check_answer_1}</p>
                )}
                { (sheetState === "en cours" && quiz[0].check_answer_2) && (
                    <div className="checkUnit">
                        { respContent[index][0].check2 === '1' && (
                            <input 
                                type="checkbox" name={quiz[0].id + "check2"}
                                onChange={() => presaveCheckData(respContent, "check2")} checked />
                        )}
                        { respContent[index][0].check2 !== '1' && (
                            <input 
                                type="checkbox" name={quiz[0].id + "check2"}
                                onChange={() => presaveCheckData(respContent, "check2")} />
                        )}
                        <label>{quiz[0].check_answer_2}</label>
                    </div>
                )}
                { (sheetState !== "en cours" && quiz[0].check_answer_2 && respContent[index][0].check2 === '1') && (
                    <p>{quiz[0].check_answer_2}</p>
                )}
                { (sheetState === "en cours" && quiz[0].check_answer_3) && (
                    <div className="checkUnit">
                        { respContent[index][0].check3 === '1' && (
                            <input 
                                type="checkbox" name={quiz[0].id + "check3"}
                                onChange={() => presaveCheckData(respContent, "check3")} checked />
                        )}
                        { respContent[index][0].check3 !== '1' && (
                            <input 
                                type="checkbox" name={quiz[0].id + "check3"}
                                onChange={() => presaveCheckData(respContent, "check3")} />
                        )}
                        <label>{quiz[0].check_answer_3}</label>
                    </div>
                )}
                { (sheetState !== "en cours" && quiz[0].check_answer_3 && respContent[index][0].check3 === '1') && (
                    <p>{quiz[0].check_answer_3}</p>
                )}
                { (sheetState === "en cours" && quiz[0].check_answer_4) && (
                    <div className="checkUnit">
                        { respContent[index][0].check4 === '1' && (
                            <input 
                                type="checkbox" name={quiz[0].id + "check4"} 
                                onChange={() => presaveCheckData(respContent, "check4")} checked />
                        )}
                        { respContent[index][0].check4 !== '1' && (
                            <input 
                                type="checkbox" name={quiz[0].id + "check4"} 
                                onChange={() => presaveCheckData(respContent, "check4")} />
                        )}
                        <label>{quiz[0].check_answer_4}</label>
                    </div>
                )}
                { (sheetState !== "en cours" && quiz[0].check_answer_4 && respContent[index][0].check4 === '1') && (
                    <p>{quiz[0].check_answer_4}</p>
                )}
                { (sheetState === "en cours" && quiz[0].check_answer_5) && (
                    <div className="checkUnit">
                        { respContent[index][0].check5 === '1' && (
                        <input 
                            type="checkbox" name={quiz[0].id + "check5"} 
                            onChange={() => presaveCheckData(respContent, "check5")} checked />
                        )}
                        { respContent[index][0].check5 !== '1' && (
                            <input 
                                type="checkbox" name={quiz[0].id + "check5"} 
                                onChange={() => presaveCheckData(respContent, "check5")} />
                        )}
                        <label>{quiz[0].check_answer_5}</label>
                    </div>
                )}
                { (sheetState !== "en cours" && quiz[0].check_answer_5 && respContent[index][0].check5 === '1') && (
                    <p>{quiz[0].check_answer_5}</p>
                )}
                { (sheetState === "en cours" && quiz[0].check_answer_6) && (
                    <div className="checkUnit">
                        { respContent[index][0].check6 === '1' && (
                            <input 
                                type="checkbox" name={quiz[0].id + "check6"} 
                                onChange={() => presaveCheckData(respContent, "check6")} checked />
                        )}
                        { respContent[index][0].check6 !== '1' && (
                            <input 
                                type="checkbox" name={quiz[0].id + "check6"} 
                                onChange={() => presaveCheckData(respContent, "check6")} />
                        )}
                        <label>{quiz[0].check_answer_6}</label>
                    </div>
                )}
                { (sheetState !== "en cours" && quiz[0].check_answer_6 && respContent[index][0].check6 === '1') && (
                    <p>{quiz[0].check_answer_6}</p>
                )}
                { (sheetState === "en cours" && quiz[0].check_answer_7) && (
                    <div className="checkUnit">
                        { respContent[index][0].check7 === '1' && (
                            <input 
                                type="checkbox" name={quiz[0].id + "check7"} 
                                onChange={() => presaveCheckData(respContent, "check7")} checked />
                        )}
                        { respContent[index][0].check7 !== '1' && (
                            <input 
                                type="checkbox" name={quiz[0].id + "check7"} 
                                onChange={() => presaveCheckData(respContent, "check7")} />
                        )}
                        <label>{quiz[0].check_answer_7}</label>
                    </div>
                )}
                { (sheetState !== "en cours" && quiz[0].check_answer_7 && respContent[index][0].check7 === '1') && (
                    <p>{quiz[0].check_answer_7}</p>
                )}
                { (sheetState === "en cours" && quiz[0].check_answer_8) && (
                    <div className="checkUnit">
                        { respContent[index][0].check8 === '1' && (
                            <input 
                                type="checkbox" name={quiz[0].id + "check8"} 
                                onChange={() => presaveCheckData(respContent, "check8")} checked />
                        )}
                        { respContent[index][0].check8 !== '1' && (
                            <input 
                                type="checkbox" name={quiz[0].id + "check8"} 
                                onChange={() => presaveCheckData(respContent, "check8")} />
                        )}
                        <label>{quiz[0].check_answer_8}</label>
                    </div>
                )}
                { (sheetState !== "en cours" && quiz[0].check_answer_8 && respContent[index][0].check8 === '1') && (
                    <p>{quiz[0].check_answer_8}</p>
                )}
                { (sheetState === "en cours" && quiz[0].check_answer_9) && (
                    <div className="checkUnit">
                        { respContent[index][0].check9 === '1' && (
                            <input 
                                type="checkbox" name={quiz[0].id + "check9"} 
                                onChange={() => presaveCheckData(respContent, "check9")} checked />
                        )}
                        { respContent[index][0].check9 !== '1' && (
                            <input 
                                type="checkbox" name={quiz[0].id + "check9"} 
                                onChange={() => presaveCheckData(respContent, "check9")} />
                        )}
                        <label>{quiz[0].check_answer_9}</label>
                    </div>
                )}
                { (sheetState !== "en cours" && quiz[0].check_answer_9 && respContent[index][0].check9 === '1') && (
                    <p>{quiz[0].check_answer_9}</p>
                )}
                { (sheetState === "en cours" && quiz[0].check_answer_10) && (
                    <div className="checkUnit">
                        { respContent[index][0].check10 === '1' && (
                            <input 
                                type="checkbox" name={quiz[0].id + "check10"} 
                                onChange={() => presaveCheckData(respContent, "check10")} checked />
                        )}
                        { respContent[index][0].check10 !== '1' && (
                            <input 
                                type="checkbox" name={quiz[0].id + "check10"} 
                                onChange={() => presaveCheckData(respContent, "check10")} />
                        )}
                        <label>{quiz[0].check_answer_10}</label>
                    </div>
                )}
                { (sheetState !== "en cours" && quiz[0].check_answer_10 && respContent[index][0].check10 === '1') && (
                    <p>{quiz[0].check_answer_10}</p>
                )}
            </div>
            {((currentUser.role === "ROLE_USER" || currentUser.role === "ROLE_MANAGER") && articleManagMode && deployBuildForm) && (
                <div style={{"transform": "scale(0.7)"}}>
                    <button 
                        className="deletionButton"
                        onClick={() => sendQuizToApi(   currentUser, 
                                                        setErrorMessage, 
                                                        articleManagMode,
                                                        quizType, 
                                                        quizContent, 
                                                        sheetList, 
                                                        setSheetList, 
                                                        "deleteQuiz", 
                                                        quiz[0].id ) }>
                            Supprimer cette question
                    </button>
                </div>
            )}
        </div>
    )
}
