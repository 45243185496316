import axios from "axios";

function updateQuantity (   currentUser, 
                            stockState, 
                            setStockState,
                            setQuantAutoriz, 
                            quantChange, 
                            setQuantChange, 
                            action, 
                            id, 
                            value = null) {
    let val = null;
    if (action === "change" && value.target.value) {
        val = (parseInt(value.target.value) >= 0) ? value.target.value : 0;
    }
    stockState.stock.map((t) => {
        if ((t.id === id &&  parseInt(val) !== t.quantity) ||
            (t.id === id && (action === "add" || (t.quantity > -1 && action === "sub")))) {
                setQuantAutoriz(false);
            (async() => {
                const updateQuant  = await axios({
                    method: 'post', 
                    url: process.env.REACT_APP_SERVER_NAME,
                    params: {st: 'upqu'},
                    data: {
                        user_email: currentUser.email,
                        user_teamUid: currentUser.teamUid,
                        user_role: currentUser.role,
                        action: action,
                        id: id,
                        value: val,
                        filter: {
                            column: stockState.filter.column,
                            value: stockState.filter.value,
                            minQuant: stockState.filter.minQuant
                        },
                        paginator: {
                            page: stockState.page,
                            perPage: stockState.perPage
                        }
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + currentUser.token
                    }
                }).then ((resp) => {
                    if (resp.status === 200) {
                        setQuantAutoriz(true);
                        setQuantChange(quantChange+1)
                        setStockState({...stockState, old_message: stockState.message, message: resp.data.message})
                    }
                })
            })()  
        } 
    })
}
export default updateQuantity;