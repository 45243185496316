import axios from "axios";

async function modifyStateSheet(currentUser,
                                respUid, 
                                setSheetState,
                                action ) {

    await axios({
        method: 'post', 
        url: process.env.REACT_APP_SERVER_NAME,
        params: {qz: 'crt'},
        data: {
            user_email: currentUser.email,
            user_teamUid: currentUser.teamUid,
            user_role: currentUser.role,
            action: action,
            respUid: respUid
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentUser.token
        }
    }).then((resp) => {
        if (resp.status === 200) {
            setSheetState(resp.data.respState);
        }
    })
}

export default modifyStateSheet;