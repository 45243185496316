import React from "react";

export default function CUDButtons({editMode, 
                                    taskId, 
                                    classes, 
                                    editTask, 
                                    removeTask, 
                                    opacityBut,
                                    icon, 
                                    text}) {

    return (
        <>
            { (editMode || icon === "delete") && (
                <span>
                    <button 
                        id={taskId}
                        className={classes} 
                        onClick={(icon !== "delete") ? editTask : removeTask} 
                        style={{"transform": "scale("+opacityBut+")"}} >
                        <span 
                            className="material-symbols-outlined butIcon" 
                            translate="no" >
                                {icon}
                        </span>
                        <span>
                            {text}
                        </span>
                    </button>
                </span>
            )}
        </>
    )
}
