import React from "react";
import RadioQuizForm from "../RadioQuizForm";

export default function DispRadioForm({quizContent, setQuizContent, buildQuizState}) {
  return (
    <div className="sheetLine">
            <span 
                className="lengthIndicator"
                style={{"color": quizContent.quiz.quiz && quizContent.quiz.quiz.length <= 2 ? "red" : "green"}}>
                {quizContent.quiz.quiz.length} / 200
            </span>
            <textarea cols="50" rows="4" id="radioTypeTitle"
                placeholder="Saisissez ici votre question (200 caractères)"
                onChange={() => buildQuizState("radio", quizContent, setQuizContent)} 
                value={quizContent.quiz.quiz ? quizContent.quiz.quiz : "" }/>
            <br/>
            <div className="inputsList">
                <RadioQuizForm 
                    id="radio_1"
                    indicatorColor={(!quizContent.quiz.radio_1 || quizContent.quiz.radio_1 === "") ? "red" : "green"}
                    content={quizContent.quiz.radio_1}
                    quizContent={quizContent}
                    setQuizContent={setQuizContent} />
                <RadioQuizForm 
                    id="radio_2"
                    indicatorColor={(!quizContent.quiz.radio_2 || quizContent.quiz.radio_2 === "") ? "red" : "green"}
                    content={quizContent.quiz.radio_2}
                    quizContent={quizContent}
                    setQuizContent={setQuizContent} />
                <RadioQuizForm 
                    id="radio_3"
                    indicatorColor={(!quizContent.quiz.radio_3 || quizContent.quiz.radio_3 === "") ? "red" : "green"}
                    content={quizContent.quiz.radio_3}
                    quizContent={quizContent}
                    setQuizContent={setQuizContent} />
                <RadioQuizForm 
                    id="radio_4"
                    indicatorColor={(!quizContent.quiz.radio_4 || quizContent.quiz.radio_4 === "") ? "red" : "green"}
                    content={quizContent.quiz.radio_4}
                    quizContent={quizContent}
                    setQuizContent={setQuizContent} />
                <RadioQuizForm 
                    id="radio_5"
                    indicatorColor={(!quizContent.quiz.radio_5 || quizContent.quiz.radio_5 === "") ? "red" : "green"}
                    content={quizContent.quiz.radio_5}
                    quizContent={quizContent}
                    setQuizContent={setQuizContent} />
            </div>
        </div>
    )
}
