import axios from "axios";

function updateTask(stateCounter = false, currentUser, task) {

        (async() => {     
            const sendTask  = await axios({
                method: 'post', 
                url: process.env.REACT_APP_SERVER_NAME,
                params: {tk: 'update'},
                data: {
                    user_email: currentUser.email,
                    user_teamUid: currentUser.teamUid,
                    user_role: currentUser.role,
                    task: {
                        taskId: task.id,
                        stateCounter: stateCounter,
                        taskTitle: task.title,
                        taskPriority: task.priority,
                        taskContent: task.content,
                        taskProgress: task.progress
                    }
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + currentUser.token
                }
            })
        })()
    
  return ;
}
export default updateTask;