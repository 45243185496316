import axios from "axios";

function exportFile(filename, currentUser, task, index) {
    
    (async() => {     
        const dlFile  = await axios({
            method: 'post', 
            url: process.env.REACT_APP_SERVER_NAME,
            params: {do: 'fi'},
            data: {
                user_email: currentUser.email,
                user_teamUid: currentUser.teamUid,
                user_role: currentUser.role,
                file: filename
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + currentUser.token
            }
        })
        .then((resp) => {
            if(resp.status === 200) {
                document.getElementById("showFileLink"+index+task.id).innerHTML = resp.data;
            }
        })
    })()

    return ;
}

export default exportFile;