import React from "react";
import controlText from "../articleFunctions/controlText";

export default function DispTextQuiz({  currentUser, 
                                        setErrorMessage,
                                        quiz, 
                                        index,
                                        sendQuizToApi, 
                                        deployBuildForm,
                                        articleManagMode,
                                        quizType, 
                                        quizContent,
                                        respContent,
                                        setRespContent,  
                                        sheetList, 
                                        setSheetList,
                                        infoColor,
                                        setInfoColor,
                                        sheetState }) {

    function pressaveTextData(value) {
        setInfoColor("red");
        var tamponResp = respContent;
        tamponResp.map((r, index) => {
            if (r[0].id === quiz[0].id) {
                tamponResp[index][0].textResponse = controlText(value);
            }
        })
        setRespContent(tamponResp);
    }

    return (
        <div className="sheetLine">
            <p id="radioTypeTitle" style={{"padding": "0 15px", "color": infoColor}}>
                <span>{index + 1} - </span>
                {quiz[0].quiz_title}
                <span 
                    className="lengthIndicator"
                    style={{"color": quizContent.title && quizContent.title.length <= 2 ? "red" : "green"}}>
                    60 caractéres max
                </span>
            </p>
            { sheetState === "en cours" && (
                <input type="text" maxLength="60" // size="40"
                    onChange={(e) => pressaveTextData(e.target.value)}
                    defaultValue={respContent[index][0].textResponse} />
            )}
            { sheetState !== "en cours" && (
                <p style={{"padding": "0 15px"}}>{respContent[index][0].textResponse}</p>
            )}
            {((currentUser.role === "ROLE_USER" || currentUser.role === "ROLE_MANAGER") && articleManagMode && deployBuildForm) && (
                <div style={{"transform": "scale(0.7)"}}>
                    <button 
                        className="deletionButton"
                        onClick={() => sendQuizToApi(   currentUser, 
                                                        setErrorMessage, 
                                                        articleManagMode,
                                                        quizType, 
                                                        quizContent, 
                                                        sheetList, 
                                                        setSheetList, 
                                                        "deleteQuiz", 
                                                        quiz[0].id ) }>
                            Supprimer cette question
                    </button>
                </div>
            )}
            {/* <hr style={{"width": "80%"}}/> */}
        </div>
    )
}
