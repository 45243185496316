function controlAccountFormValidity (currentUser) {
    var lastname = false;
    var firstname = false;
    var town = false;
    var company = false;
    var metier = false;
    var phone = false;
    if (currentUser.lastname && currentUser.lastname.length > 1) {
        document.getElementById("accountLastnameLb").style = "color:#33A748";
        document.getElementById("accountLastnameInp").style = "border-bottom:1px solid #33A748";
        lastname = true;
    } else {
        document.getElementById("accountLastnameLb").style.color = "red";
        document.getElementById("accountLastnameInp").style = "border-bottom:1px solid red";
    }
    if (currentUser.firstname && currentUser.firstname.length > 1) {
        document.getElementById("accountFirstnameLb").style = "color:#33A748";
        document.getElementById("accountFirstnameInp").style = "border-bottom:1px solid #33A748";
        firstname = true;
    } else {
        document.getElementById("accountFirstnameLb").style.color = "red";
        document.getElementById("accountFirstnameInp").style = "border-bottom:1px solid red";
    }
    if (currentUser.town && currentUser.town.length > 1) {
        document.getElementById("accountTownLb").style = "color:#33A748";
        document.getElementById("accountTownInp").style = "border-bottom:1px solid #33A748";
        town = true;
    } else {
        document.getElementById("accountTownLb").style.color = "red";
        document.getElementById("accountTownInp").style = "border-bottom:1px solid red";
    }
    if (currentUser.company && currentUser.company.length > 1) {
        document.getElementById("accountCompanyLb").style = "color:#33A748";
        document.getElementById("accountCompanyInp").style = "border-bottom:1px solid #33A748";
        company = true;
    } else {
        document.getElementById("accountCompanyLb").style.color = "red";
        document.getElementById("accountCompanyInp").style = "border-bottom:1px solid red";
    }
    if (currentUser.metier && currentUser.metier.length > 1) {
        document.getElementById("accountMetierLb").style = "color:#33A748";
        document.getElementById("accountMetierInp").style = "border-bottom:1px solid #33A748";
        metier = true;
    } else {
        document.getElementById("accountMetierLb").style.color = "red";
        document.getElementById("accountMetierInp").style = "border-bottom:1px solid red";
    }
    if (currentUser.phone && currentUser.phone.length > 9) {
        document.getElementById("accountPhoneLb").style = "color:#33A748";
        document.getElementById("accountPhoneInp").style = "border-bottom:1px solid #33A748";
        phone = true;
    } else {
        document.getElementById("accountPhoneLb").style.color = "red";
        document.getElementById("accountPhoneInp").style = "border-bottom:1px solid red";
    }
    if (lastname && firstname && town && company && metier && phone) {

        return true;
    } else {
        
        return false;
    }
}
export default controlAccountFormValidity;