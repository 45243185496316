import React from "react";
import controlText from "../articleFunctions/controlText";

export default function DispTextareaQuiz({  currentUser, 
                                            setErrorMessage,
                                            quiz, 
                                            index,
                                            sendQuizToApi, 
                                            deployBuildForm,
                                            articleManagMode,
                                            quizType, 
                                            quizContent,
                                            respContent,
                                            setRespContent, 
                                            sheetList, 
                                            setSheetList,
                                            infoColor,
                                            setInfoColor,
                                            sheetState }) {

    function pressaveTextareaData(tamponResp, value) {
        setInfoColor("red");
        tamponResp.map((r, index) => {
            if (r[0].id === quiz[0].id) {
                tamponResp[index][0].textResponse = controlText(value);
            }
        })
        return tamponResp;
    }
            
    return (
        <div className="sheetLine">
            <p id="radioTypeTitle" style={{"padding": "0 15px", "color": infoColor}}>
                <span>{index + 1} - </span>
                {quiz[0].quiz_title}
                <span
                    className="lengthIndicator"
                    style={{"color": quizContent.title && quizContent.title.length <= 2 ? "red" : "green"}}>
                    500 caractéres max
                </span>
            </p>
            { sheetState === "en cours" && (
                <textarea type="text" rows="5" maxLength="500"
                    id={index + "area"}
                    onChange={(e) => setRespContent(pressaveTextareaData(respContent, e.target.value))}
                    defaultValue={respContent[index][0].textResponse}>
                </textarea>
            )}
            { sheetState !== "en cours" && (
                <p style={{"padding": "0 15px"}}>{respContent[index][0].textResponse}</p>
            )}
            {((currentUser.role === "ROLE_USER" || currentUser.role === "ROLE_MANAGER") && articleManagMode && deployBuildForm) && (
                <div style={{"transform": "scale(0.7)"}}>
                    <button 
                        className="deletionButton"
                        onClick={() => sendQuizToApi(   currentUser, 
                                                        setErrorMessage, 
                                                        articleManagMode,
                                                        quizType, 
                                                        quizContent, 
                                                        sheetList, 
                                                        setSheetList, 
                                                        "deleteQuiz", 
                                                        quiz[0].id ) }>
                            Supprimer cette question
                    </button>
                </div>
            )}
        </div>
    )
}
