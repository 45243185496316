function controlText(inputText) { 
    if (inputText.length > 0) {
        return inputText
            .replace(/&/g, "")
            .replace(/\$/g, "")
            .replace(/\|/g, "")
            .replace(/</g, "")
            .replace(/>/g, "")
            .replace(/;/g, "")
            .replace(/"/g, "")
            .replace(/\*/g, "")
            .replace(/{/g, "")
            .replace(/}/g, "")
            .replace(/\[/g, "")
            .replace(/\]/g, "");
    } else {
        return "";
    }
}

export default controlText;