import React, {useState, useRef} from "react";
import controlText from './controlText';
import axios from "axios";
import { current } from "@reduxjs/toolkit";

export default function AssemblageCreation({setAssemblyList, currentUser, setAssemblCreateForm}) {

    const assemblage = useRef([]);
    const [validAssemblData, setValidAssemblData] = useState(false);

    function setAssData (el) {
        if(el && !assemblage.current.includes((el))) {
            assemblage.current.push(el)
        }
    }
    function closeCreationAssWindow () {
        assemblage.current = null;
        setAssemblCreateForm(false);
    }
    function createAssemblage () {
        setAssemblCreateForm(false);
        if (assemblage.current[1].value && assemblage.current[1].value.length > 0) {
            setValidAssemblData(true);
            (async() => {     
                const sendAss  = await axios({
                    method: 'post', 
                    url: process.env.REACT_APP_SERVER_NAME,
                    params: {ass: 'cr'},
                    data: {
                        user_email: currentUser.email,
                        user_teamUid: currentUser.teamUid,
                        user_role: currentUser.role,
                        fileUpload: assemblage.current[0].files[0],
                        reference: controlText(assemblage.current[1].value),
                        hint: controlText(assemblage.current[2].value),
                        designator: controlText(assemblage.current[3].value),
                        quantity: !isNaN(controlText(assemblage.current[4].value)) ? controlText(assemblage.current[4].value) : "0",
                        quantityMini: !isNaN(controlText(assemblage.current[5].value)) ? controlText(assemblage.current[5].value) : "0",
                        tpAppro: !isNaN(controlText(assemblage.current[6].value)) ? controlText(assemblage.current[6].value) : "0"
                    },
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Accept': 'multipart/form-data',
                        'Authorization': 'Bearer ' + currentUser.token
                    }
                }).then((resp) => {
                    if (resp.status === 200 && resp.data.assemblyList) {
                        setAssemblyList(resp.data.assemblyList);
                    }
                })
            })()
        }
    }


  return (
    <div className="modalContainer noselect">
        <div className="assemblCreateModale">
                <span 
                    className="material-symbols-outlined closeButton"
                    onClick={closeCreationAssWindow}>
                        cancel
                </span>
                <div className="assemblCreateForm">
                    <h3>Création d'un assemblage</h3>
                    <div className="assemblFileCreationInput">
                        <label>Image de l'assemblage (.png, 5Mo max)</label>
                        <input type="file" name="fileExploded" 
                            ref={setAssData}
                            // value={assemblage.current.fileExplodedName}
                            // onChange={(e) => setAssemblData("fileExploded", e)}
                            />
                    </div>
                    <div className="assemblCreationInput">
                        <label style={{"color": validAssemblData ? "#2BBD10" : "red"}}>Référence</label>
                        <input type="text" size="18" name="assRef"
                            ref={setAssData}
                            // value={assemblage.current.ref}
                            // onChange={(e) => setAssemblData("ref", e)}
                        />
                    </div>
                    <div className="assemblCreationInput">
                        <label>Indice de fabrication</label>
                        <input type="text" size="5" name="assHint"
                            style={{"marginRight": "50px"}}
                            ref={setAssData}
                            // value={assemblage.current.ind}
                            // onChange={(e) => setAssemblData("ind", e)}
                            />
                    </div>
                    <div className="assemblCreationInput">
                        <label>Désignation</label>
                        <input type="text" size="18" name="assDes"
                            ref={setAssData}
                            // value={assemblage.current.des}
                            // onChange={(e) => setAssemblData("des", e)}
                            />
                    </div>
                    <div className="assemblCreationInput">
                        <label>Quantité en stock</label>
                        <input type="number" max="999" min="0" step="1" size="3" name="assQuant"
                            ref={setAssData}
                            // value={assemblage.current.quant}
                            // onChange={(e) => setAssemblData("quant", e)}
                            />
                    </div>
                    <div className="assemblCreationInput">
                        <label>Quantité minimum</label>
                        <input type="number" max="999" min="0" step="1" size="3" name="assQuantMin"
                            ref={setAssData}
                            // value={assemblage.current.quantMin}
                            // onChange={(e) => setAssemblData("quantMin", e)}
                            />
                    </div>
                    <div className="assemblCreationInput">
                        <label>Temps de fabrication / fourniture</label>
                        <input type="number" max="999" min="0" step="1" size="3" name="assTpAppro"
                            ref={setAssData}
                            // value={assemblage.current.tpAppro}
                            // onChange={(e) => setAssemblData("tpAppro", e)}
                            />
                    </div>
                    <div>
                        <button 
                            className="validationButton"
                            onClick={createAssemblage}>
                                Valider
                        </button>
                    </div>
                </div>
                
        
        </div>
    </div>
  )
}
