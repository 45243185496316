import axios from "axios";

async function getProviderArticles (currentUser, 
                                    currentId, 
                                    currentProvider,
                                    commandState, 
                                    setCommandState, 
                                    stockState, 
                                    setStockState) {
    await axios({
        method: 'post', 
        url: process.env.REACT_APP_SERVER_NAME,
        params: {st: 'getOne'},
        data: {
            user_email: currentUser.email,
            id: currentId,
            provider: currentProvider,
            paginator: {
                page: stockState.page,
                perPage: stockState.perPage
            }
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentUser.token
        }
    }).then ((resp) => {
        if (resp.status === 200) {
            // setStockState({
            //     ...stockState,
            //     old_message: stockState.message,
            //     message: resp.data.message
            // });
            resp.data[1].map((rd, index) => {
                if (rd.ref === resp.data[0].ref) {
                    delete resp.data[1][index];
                }
            })
            resp.data[0].quant = 1;
            resp.data[1].map((data) => {
                data.quant = 1;
            })
            setCommandState({
                    ...commandState,
                    customerName: resp.data[3].trade_name,
                    customerAddressOne: resp.data[3].address_one,
                    customerAddressTwo: resp.data[3].address_two,
                    customerZipTown: resp.data[3].zip_town,
                    customerSiret: resp.data[3].siret,
                    customerTva: resp.data[3].tva,
                    customerPhone: resp.data[3].phone,
                    providerAddressOne: resp.data[2].address_one,
                    providerAddressTwo: resp.data[2].address_two,
                    providerZipTown: resp.data[2].zip_town,
                    providerName: resp.data[0].provider,
                    article: [resp.data[0]],
                    otherArticles: resp.data[1]
            })
        }
    })
}

export default getProviderArticles;