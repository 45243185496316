import axios from "axios";

async function addOnePartFile({
    currentUser,
    file,
    partFilesDetail, 
    setPartFilesDetail
}) {
    await axios({
            method: 'post', 
            url: process.env.REACT_APP_SERVER_NAME,
            params: {ass: 'addpf'},
            data: {
                user_email: currentUser.email,
                team_uid: currentUser.teamUid,
                user_role: currentUser.role,
                part_ref: partFilesDetail.ref,
                partFileUpload: file[0]
            },
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'multipart/form-data',
                'Authorization': 'Bearer ' + currentUser.token
            }
        }).then((resp) => {
            if (resp.status === 200) {
                // setPartFilesDetail({
                //     ...partFilesDetail, 
                //     message: resp.data.partFiles.message,
                //     existsPartsFiles: resp.data.partFiles.existsPartsFiles
                // });
            }
        })
}

export default addOnePartFile;