import axios from "axios";

async function sendQuizToApi (  currentUser, 
                                setErrorMessage,
                                articleManagMode,
                                quizType, 
                                quizContent, 
                                sheetList, 
                                setSheetList, 
                                action, 
                                name = null ) {
    await axios({
        method: 'post', 
        url: process.env.REACT_APP_SERVER_NAME,
        params: {qz: 'crt'},
        data: {
            user_email: currentUser.email,
            user_teamUid: currentUser.teamUid,
            user_role: currentUser.role,
            articleManagerMode: articleManagMode,
            action: action,
            quizType: quizType,
            quizContent: quizContent,
            name: name
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentUser.token
        }
    }).then((resp) => {
        if (resp.status === 200 && action !== "deleteQuiz") {
            var sheet = [];
            if (action === "createSheet" && resp.data.error) {
                setErrorMessage(resp.data.message);
            } else {
                setErrorMessage(null);
                resp.data.map((sheetUnit, index) => {
                        sheet.push({title: sheetUnit.sheet_form_title, uid: sheetUnit.sheet_form_uid});
                    
                })
                setSheetList(sheet);
            }
        }
    })
}

export default sendQuizToApi;