import React, { useRef } from 'react'

export default function DispPrettyTime({time}) {

    const prettyTime = useRef(["0", "00", "00", "00"])

    let day = parseInt(time / 86400) % 86400;
    prettyTime.current[0] = (day < 1) ? "0" : prettyTime.current[0];
    prettyTime.current[0] = (day > 0 && day < 10) ? day.toString() : prettyTime.current[0];
    prettyTime.current[0] = (day > 9) ? day.toString() : prettyTime.current[0];
    let hou = parseInt((time - day * 86400) / 3600) % 3600;
    prettyTime.current[1] = (hou < 1) ? "00" : prettyTime.current[1];
    prettyTime.current[1] = (hou > 0 && hou < 10) ? "0"+hou.toString() : prettyTime.current[1];
    prettyTime.current[1] = (hou > 9) ? hou.toString() : prettyTime.current[1];
    let min = parseInt(time / 60) % 60;
    prettyTime.current[2] = (min < 1) ? "00" : prettyTime.current[2];
    prettyTime.current[2] = (min > 0 && min < 10) ? "0"+min.toString() : prettyTime.current[2];
    prettyTime.current[2] = (min > 9) ? min.toString() : prettyTime.current[2];
    let sec = time % 60;
    prettyTime.current[3] = (sec < 1) ? "00" : prettyTime.current[3];
    prettyTime.current[3] = (sec > 0 && sec < 10) ? "0"+sec.toString() : prettyTime.current[3];
    prettyTime.current[3] = (sec > 9) ? sec.toString() : prettyTime.current[3];


    return (
        <>
            <span id="day">
                {prettyTime.current[0]}
            </span>j 
            <span id="hour">
                {prettyTime.current[1]}
            </span>:
            <span id="min"> 
                {prettyTime.current[2]}
            </span>:
            <span id="sec"> 
                {prettyTime.current[3]}
            </span> 
        </>
    )
}
