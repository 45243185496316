function changeQuantity (id, e, stockState, setStockState, quantAutoriz) {
    if (e.target.value >= 0 && quantAutoriz) {
        stockState.stock.map((t, index) => {
            if (t.id === id && t.quantity > -1) {
                t.quantity = e.target.value;
                setStockState({...stockState, t: {...t, quantity: e.target.value}});
            }
        })
    }
}

export default changeQuantity;