import React from 'react'

function FileIndicator({nbFile}) {
  return (
    <div className="jointFileIndicator">
        <span className="symb material-symbols-outlined">attach_file</span>
        <span id="nb">{nbFile}</span>
        <span id="tt"> fichiers</span>
    </div>
  )
}
export default FileIndicator