function controlText(inputText, size = null) { 
    if (inputText.length > 0) {
        var text = inputText
            .replace(/&/g, "")
            .replace(/\$/g, "")
            .replace(/\|/g, "")
            .replace(/</g, "")
            .replace(/>/g, "")
            .replace(/;/g, "")
            .replace(/"/g, "")
            .replace(/\*/g, "")
            .replace(/{/g, "")
            .replace(/}/g, "")
            .replace(/\[/g, "")
            .replace(/\]/g, "");
        if (size === null) {
            
            return text;
        }
        if (size > 0) {

            return text.slice(0, size);
        }
    } else {
        return "";
    }
}

export default controlText;