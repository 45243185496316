import { useState, useEffect,  useContext } from "react";
import { store } from "./redux/reduxStore";
import { UserContext } from './context/userContext'
import { Provider } from "react-redux";
import ApiConnexion from "./ApiConnexion";
import CGU from "./CGU";
import Header from "./Header";
import TutoTodos from "./Tuto/TutoTodos.js";
import MsTool from "./MsTool";
import ToolBar from "./todosComponents/ToolBar";
import TodosList from "./todosComponents/TodosList";
import SignUpModal from "./components/SignUpModal";
import SignInModal from "./components/SignInModal";
import Stock from "./stockComponents/Stock";
import Article from "./articleComponent/Article";
import AccountManagement from "./accountComponents/AccountManagement";
import noveltyValidation from "./accountComponents/noveltyValidation";

import themes from './globalStyle/themes.json'
import msToolContent from './msToolContent.json'
import './globalStyle/App.css';
import './globalStyle/msTool.css';
import './globalStyle/modalSignUpStyle.css';
import './globalStyle/noveltyModal.css';
import './todosComponents/todosStyle/loadingPageStyle.css';
import './headerStyle.css';
import './todosComponents/todosStyle/commentStyle.css';
import './stockComponents/stock.css';
import './todosComponents/todosStyle/toolbarStyle.css';
import './todosComponents/todosStyle/signinSignoutStyle.css';
import './todosComponents/todosStyle/buttonType3.css';
import "./todosComponents/todosStyle/tutoStyle.css";
import "./accountComponents/accountManagementStyle.css";

function App() {

    const [globalState, setGlobalState] = useState({
        theme: themes,
        themeNb: 0,
        dispName: "",
        maxTaskNb: 0,
        avatar: "personLg.png",
        cgu: false
    });
    const [toolChosen, setToolChosen] = useState("accueil");
    const { currentUser, setCurrentUser, setApiIdentifier, apiIdentifier } = useContext(UserContext);

    useEffect (() => {

        return ;
    }, [toolChosen, currentUser])

    function changeTool (rubriq) {
        if (currentUser.email) {
            setToolChosen(rubriq)
        }
    }
    function noveltyValid () {
        setCurrentUser({...currentUser, noveltyValidation: true});
        noveltyValidation(currentUser);
    }
    
    return (
        <Provider store={store}>
            <div className="App" style={{"--bgImage": globalState.theme.themes.appBgStyle[globalState.themeNb]}}>
                <SignUpModal />
                <SignInModal 
                setGlobalState={setGlobalState}
                setApiIdentifier={setApiIdentifier} />
                <Header 
                    toolChosen={toolChosen}
                    setToolChosen={setToolChosen}
                    globalState={globalState}
                    setGlobalState={setGlobalState}
                    />
                {/* { (!currentUser && !globalState.cgu) && (
                    <TutoTodos />
                )} */}
                { (!apiIdentifier) && (
                    <ApiConnexion
                        globalState={globalState}
                        setGlobalState={setGlobalState} />
                )}
                { globalState.cgu && (
                    <CGU />
                )}
                { (currentUser.email && currentUser.novelty && !currentUser.noveltyValidation) && (
                    <div className="modalContainer">
                        <div className="noveltyModal">
                        { currentUser.novelty.created_at && (
                            <span className="noveltyDate">date: {currentUser.novelty.created_at}</span>
                        )}
                            <h4>Bonjour {(currentUser.firstname !== "") ? currentUser.firstname : currentUser.email},</h4>
                            <p><i><span style={{"marginLeft":"20px"}}></span>Nous vous informons de la dernière nouveauté de votre outil Manager-système.</i></p>
                            { currentUser.novelty.novelty_tool && (
                                <p>Outil concerné: <strong>{currentUser.novelty.novelty_tool}</strong></p>
                            )}
                            { currentUser.novelty.novelty_text && (
                                <p><span style={{"marginLeft":"20px"}}></span>{currentUser.novelty.novelty_text}</p>
                            )}
                            <div className="noveltyImgContainer">
                                <img src={process.env.REACT_APP_SERVER_NAME.slice(0,-14) + currentUser.novelty.novelty_image} alt="novelty visual description" />
                            </div>
                            <div>
                                <button className="validationButton" onClick={() => noveltyValid()}>J'ai compris</button>
                            </div>
                        </div>
                    </div>
                )}
                { (toolChosen === "accueil") && (
                    <div className="displayToolsContainer">
                        <MsTool 
                            key="account"
                            rubriq="account"
                            changeTool={changeTool}
                            globalState={globalState}
                            content={msToolContent.accueil}
                            setToolChosen={setToolChosen}
                            toolChosen={toolChosen}
                            msToolContent={msToolContent} />
                        <MsTool 
                            key="task"
                            rubriq="task"
                            changeTool={changeTool}
                            globalState={globalState}
                            content={msToolContent.task}
                            toolChosen={toolChosen}
                            setToolChosen={setToolChosen}
                            msToolContent={msToolContent} />
                        <MsTool 
                            key="stock"
                            rubriq="stock"
                            changeTool={changeTool}
                            globalState={globalState}
                            content={msToolContent.stock}
                            toolChosen={toolChosen}
                            setToolChosen={setToolChosen}
                            msToolContent={msToolContent} />
                        <MsTool 
                            key="article"
                            rubriq="article"
                            changeTool={changeTool}
                            globalState={globalState}
                            content={msToolContent.article}
                            toolChosen={toolChosen}
                            setToolChosen={setToolChosen}
                            msToolContent={msToolContent} />
                    </div>
                )}
                { (currentUser && apiIdentifier && !globalState.cgu && toolChosen === "account") && (
                  <>
                    <AccountManagement
                        globalState={globalState}
                        setGlobalState={setGlobalState} />
                  </>
                )}
                { (currentUser && apiIdentifier && !globalState.cgu && toolChosen === "task") && (
                  <>
                    <ToolBar 
                        globalState={globalState}
                        setGlobalState={setGlobalState} />
                    <TodosList 
                        globalState={globalState}
                        setGlobalState={setGlobalState} />
                  </>
                )}
                { (currentUser && apiIdentifier && !globalState.cgu && toolChosen === "stock") && (
                  <>
                    <Stock
                        globalState={globalState}
                        setGlobalState={setGlobalState} />
                  </>
                )}
                { (currentUser && apiIdentifier && !globalState.cgu && toolChosen === "article") && (
                    <>
                        <Article 
                            globalState={globalState}
                            setGlobalState={setGlobalState} />
                    </>
                )}
            </div>
        </Provider>
    );
  }

export default App;
