import { configureStore, createSlice } from "@reduxjs/toolkit";



const todoSlice = createSlice({
    name: "todo",
    initialState: [],
    reducers: {
        addTask: (state, action) => {
            const newTask = {
                id: action.payload,
                createdAt: null,
                currentTimerStarting: false,
                docs: [],
                timeCount: 0,
                title: "Nouvelle tache",
                priority: 10,
                content:"",
                progress: "A faire"
                }
            state.unshift(newTask);
        },
        deleteAllTasks: (state, action) => {
            state.map((t) => state.shift(t))
            return state;
        },
        initTask: (state, action) => {
            state.unshift(action.payload);
        },
        getTasks: (state, action) => {
            return state;
        },
        updateTask: (state, action) => {
            return action.payload;
        },
        toggleTimeCountTask: (state, action) => {
            const task = state.find((t) => t.id === action.payload )
            task.timeCount = task.timeCount + 1;
            task.currentTimerStarting = "start";
        },
        toggleStopCounter: (state, action) => {
            const task = state.find((t) => t.id === action.payload )
            task.currentTimerStarting = "stop";
        },
        togglePriorityTask: (state, action) => {
            const task = state.find((t) => t.id === action.payload[0] )
            task.priority = action.payload[1];
        },
        toggleProgressTask: (state, action) => {
            const task = state.find((t) => t.id === action.payload[0] )
            task.progress = action.payload[1];
        },
        toggleTitleTask: (state, action) => {
            const task = state.find((t) => t.id === action.payload[0] )
            task.title = action.payload[1];        
        },
        toggleContentTask: (state, action) => {
            const task = state.find((t) => t.id === action.payload[0] )
            task.content = action.payload[1];
        },
        deleteTask: (state, action) => {
            const tasks = state.filter((t) => t.id !== action.payload )
            return tasks;
        },
        stateFilterAfaire: (state, action) => {
            const tasks = state.filter((t) => t.progress === "A faire" )
            const otherTasks = state.filter((t) => t.progress !== "A faire" )
            return [].concat(tasks, otherTasks);
        },
        stateFilterEncours: (state, action) => {
            const tasks = state.filter((t) => t.progress === "En cours" )
            const otherTasks = state.filter((t) => t.progress !== "En cours" )
            return [].concat(tasks, otherTasks);
        },
        stateFilterFait: (state, action) => {
            const tasks = state.filter((t) => t.progress === "Fait" )
            const otherTasks = state.filter((t) => t.progress !== "Fait" )
            return [].concat(tasks, otherTasks);
        },
        stateFilterAvalider: (state, action) => {
            const tasks = state.filter((t) => t.progress === "A valider" )
            const otherTasks = state.filter((t) => t.progress !== "A valider" )
            return [].concat(tasks, otherTasks);
        },
        stateFilterProgressAsc: (state, action) => {
            const tasks = state.sort(function compare(a, b) {
                                                    if (a.priority < b.priority)
                                                    return -1;
                                                    if (a.priority > b.priority )
                                                    return 1;
                                                    return 0;
                                                });
            return tasks;      
        },
        stateFilterProgressDesc: (state, action) => {
            const tasks = state.sort(function compare(a, b) {
                                                if (a.priority > b.priority)
                                                return -1;
                                                if (a.priority < b.priority )
                                                return 1;
                                                return 0;
                                            });
        return tasks;      
        },
        stateFilterTimeAsc: (state, action) => {
            const tasks = state.sort(function compare(a, b) {
                                                    if (a.timeCount < b.timeCount)
                                                    return -1;
                                                    if (a.timeCount > b.timeCount )
                                                    return 1;
                                                    return 0;
                                                });
            return tasks;      
        },
        stateFilterTimeDesc: (state, action) => {
            const tasks = state.sort(function compare(a, b) {
                                                if (a.timeCount > b.timeCount)
                                                return -1;
                                                if (a.timeCount < b.timeCount )
                                                return 1;
                                                return 0;
                                            });
        return tasks;      
        },
    }
})


export const {  addTask, 
                deleteAllTasks,
                getTasks,
                initTask,
                toggleTimeCountTask, 
                toggleStopCounter,
                togglePriorityTask, 
                toggleProgressTask, 
                toggleTitleTask, 
                toggleContentTask, 
                updateTask, 
                deleteTask,
                stateFilterAfaire,
                stateFilterEncours,
                stateFilterFait,
                stateFilterAvalider,
                stateFilterProgressAsc,
                stateFilterProgressDesc,
                stateFilterTimeAsc,
                stateFilterTimeDesc, } = todoSlice.actions;


export const store = configureStore({
    reducer: {
        todo: todoSlice.reducer,
    }
})
