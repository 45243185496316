import React, { useState } from "react";
import progressFilter from './stockFunctions/progressFilter';
import updateQuantity from './stockRequest/updateQuantity';
import addFilter from './stockFunctions/addFilter';
import changePage from './stockFunctions/changePage';
import editArticle from './stockFunctions/editArticle';
import changeQuantity from './stockFunctions/changeQuantity';
import dispResum from './stockFunctions/dispResum';
import editStockCommandForm from './stockFunctions/editStockCommandForm';
import managThisProvider from './stockFunctions/managThisProvider';
import addCommand from './stockFunctions/addCommand';

export default function PartTable({ manageRefreshing,
                                    stopRefreshing,
                                    currentUser, 
                                    stockState, 
                                    setStockState,
                                    userHoverTable,
                                    updateDate,
                                    setCurrentId,
                                    textFetch,
                                    setTextFetch,
                                    quantChange, 
                                    setQuantChange, 
                                    setUserHoverTable,
                                    buildCommandInfo, 
                                    setBuildCommandInfo,
                                    openCommandForm, 
                                    setOpenCommandForm, 
                                    setCurrentProvider,
                                    setManagProvider }) {
    
    const [quantAutoriz, setQuantAutoriz] = useState(true);

  return (

    <table className="stockTable noselect">
                            <thead style={{"paddingBottom": "13px"}}>
                                <tr>
                                    <th 
                                        className="countIndicator"
                                        colSpan="1">
                                        {stockState.totalParts + " références"}
                                    </th>
                                    <th 
                                        className="addArticle" 
                                        onClick={() => editArticle(null, setCurrentId, stockState, setStockState)}
                                        colSpan="2">
                                        Ajouter une référence
                                    </th>
                                    <th 
                                        className="paginatorStyle" 
                                        colSpan="3">
                                        <span 
                                            className="pageIndicatorLeft material-symbols-outlined"
                                            onClick={() => changePage(stockState, setStockState, "previous")}>
                                            arrow_back
                                        </span>
                                        <span className="pageNumber">Page { stockState.page }</span>
                                        <span 
                                            className="pageIndicatorRight material-symbols-outlined"
                                            onClick={() => changePage(stockState, setStockState, "next")}>
                                            arrow_forward
                                        </span>
                                    </th>
                                    <th className="perPageContainer" colSpan="3">
                                        <span>Afficher</span> 
                                        <button 
                                            id="perPageMin"
                                            onClick={() => setStockState({...stockState, perPage: stockState.perPage - 1})}> - </button>
                                        <input 
                                        type="number" 
                                        id="quantInput"
                                        value={stockState.perPage} 
                                        size="4"
                                        min="1" max="15" step="1" 
                                        onChange={(e) => setStockState({...stockState, perPage: e.target.value})}
                                        />
                                        <button 
                                            id="perPageAdd"
                                            onClick={() => setStockState({...stockState, perPage: stockState.perPage + 1})}> + </button>
                                        <span>par page</span>
                                    </th>
                                    <th className="refreshZone">
                                        <div className="refreshIndicator">
                                            {userHoverTable && (
                                                <>
                                                    <span className="rIon material-symbols-outlined">toggle_on</span>
                                                    <span className="rIon hourRef">{updateDate}</span>
                                                </>
                                            )}
                                            {!userHoverTable && (
                                                <>
                                                    <span className="rIoff material-symbols-outlined">toggle_off</span>
                                                    <span className="rIoff hourRef">{updateDate}</span>
                                                </>
                                            )}
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <thead><tr><td colSpan="10" style={{"background":"grey"}}></td></tr></thead>
                            <tbody>
                                <tr>
                                    <td className="filterStyle tdWidtText refFilterContainer">
                                        <div className="textFilterContainer">
                                            <span className="filterSymb material-symbols-outlined">filter_alt</span>
                                            <input type="text" 
                                                id="refTextFilter" className="refTextFilter" size="10"
                                                onChange={(e) => progressFilter (stockState, setStockState, textFetch, setTextFetch, "ref", e)} 
                                                value={textFetch.refTextFetch}/>
                                            <select type="select" id="refFilter" className="refFilter" 
                                                onChange={(e) => addFilter(stockState, setStockState, setTextFetch, quantChange, setQuantChange, setUserHoverTable, "ref", e)}>
                                                <option value="choix">Pas de choix</option>
                                                {(stockState.refTextValues.length === 0) && (stockState.refValues.map((r, index) => (
                                                    <option key={"ref"+index} value={stockState.refValues[index]}>{stockState.refValues[index]}</option>
                                                )))}
                                                {(stockState.refTextValues.length !== 0) && (stockState.refTextValues.map((r, index) => (
                                                    <option key={"ref"+index} value={stockState.refTextValues[index]}>{stockState.refTextValues[index]}</option>
                                                )))}
                                            </select>
                                        </div>
                                    </td>
                                    <td className="filterStyle tdWidtText desFilterContainer">
                                        <div className="textFilterContainer">
                                            <span className="filterSymb material-symbols-outlined">filter_alt</span>
                                            <input type="text" 
                                                id="desTextFilter" className="desTextFilter" size="10"
                                                onChange={(e) => progressFilter (stockState, setStockState, textFetch, setTextFetch, "des", e)} 
                                                value={textFetch.desTextFetch}/>
                                            <select type="select" id="desFilter" className="desFilter" 
                                                onChange={(e) => addFilter(stockState, setStockState, setTextFetch, quantChange, setQuantChange, setUserHoverTable, "designator", e)} >
                                                <option value="choix">Pas de choix</option>
                                                {(stockState.desTextValues.length === 0) && (stockState.desValues.map((r, index) => (
                                                    <option key={"des"+index} value={stockState.desValues[index]}>{stockState.desValues[index]}</option>
                                                )))}
                                                {(stockState.desTextValues.length !== 0) && (stockState.desTextValues.map((r, index) => (
                                                    <option key={"des"+index} value={stockState.desTextValues[index]}>{stockState.desTextValues[index]}</option>
                                                )))}
                                            </select>
                                        </div>
                                    </td>
                                    <td className="filterStyle tdWidtText catFilterContainer">
                                        <div className="textFilterContainer">
                                            <span className="filterSymb material-symbols-outlined">filter_alt</span>
                                            <input type="text" 
                                                id="catTextFilter" className="catTextFilter" size="10"
                                                onChange={(e) => progressFilter (stockState, setStockState, textFetch, setTextFetch, "cat", e)} 
                                                value={textFetch.catTextFetch}/>
                                            <select type="select" id="catFilter" className="catFilter" 
                                                onChange={(e) => addFilter(stockState, setStockState, setTextFetch, quantChange, setQuantChange, setUserHoverTable, "category", e)} >
                                                <option value="choix">Pas de choix</option>
                                                {(stockState.catTextValues.length === 0) && (stockState.catValues.map((r, index) => (
                                                    <option key={"cat"+index} value={stockState.catValues[index]}>{stockState.catValues[index]}</option>
                                                )))}
                                                {(stockState.catTextValues.length !== 0) && (stockState.catTextValues.map((r, index) => (
                                                    <option key={"cat"+index} value={stockState.catTextValues[index]}>{stockState.catTextValues[index]}</option>
                                                )))}
                                            </select>
                                        </div>
                                    </td>
                                    <td className="filterStyle quantWidtBut" colSpan="4">
                                        <button className="initBut" 
                                        onClick={() => addFilter(stockState, setStockState, setTextFetch, quantChange, setUserHoverTable, setQuantChange, "", null)}
                                        style={{
                                            "background": (stockState.filter.minQuant || stockState.filter.column !== "" || stockState.filter.value !== "") ? "lightgreen" : "orange",
                                            "transform": (stockState.filter.minQuant || stockState.filter.column !== "" || stockState.filter.value !== "") ? "scale(1)" : "scale(0.4)"
                                            }}>
                                            Initialisé les filtres
                                        </button>
                                        { stockState.quantAlert > 0 && (
                                            <p 
                                            onClick={() => addFilter(stockState, setStockState, setTextFetch, quantChange, setQuantChange, setUserHoverTable, "minQuant", null)}
                                            className="quantAlert">
                                                <span className="filterSymb material-symbols-outlined" style={{"color":"black"}}>filter_alt</span>
                                                Alerte quantité : {stockState.quantAlert} articles
                                            </p>
                                        )}
                                    </td>
                                    <td className="filterStyle tdWidtText proFilterContainer">
                                        <div className="textFilterContainer">
                                            <span className="filterSymb material-symbols-outlined">filter_alt</span>
                                            <input type="text" 
                                                id="proTextFilter" className="proTextFilter" size="10"
                                                onChange={(e) => progressFilter(stockState, setStockState, textFetch, setTextFetch, "prov", e)} 
                                                value={textFetch.proTextFetch}/>
                                            <select type="select" id="proFilter" className="proFilter" 
                                                onChange={(e) => addFilter(stockState, setStockState, setTextFetch, quantChange, setQuantChange, setUserHoverTable, "provider", e)}>
                                                <option value="choix">Pas de choix</option>
                                                {(stockState.proTextValues.length === 0) && (stockState.proValues.map((r, index) => (
                                                    <option key={"pro"+index} value={stockState.proValues[index]}>{stockState.proValues[index]}</option>
                                                )))}
                                                {(stockState.proTextValues.length !== 0) && (stockState.proTextValues.map((r, index) => (
                                                    <option key={"pro"+index} value={stockState.proTextValues[index]}>{stockState.proTextValues[index]}</option>
                                                )))}
                                            </select>
                                        </div>
                                    </td>
                                    <td className="filterStyle tdWidtText proFilterContainer"></td>
                                    <td className="filterStyle tdWidtText proFilterContainer"></td>
                                </tr>
                                <tr>
                                    <td className="topColumn tdWidtText">Référence</td>
                                    <td className="topColumn tdWidtText">Désignation</td>
                                    <td className="topColumn tdWidtText">Catégories</td>
                                    <td className="topColumn tdWidtBut">Quantité</td>
                                    <td className="topColumn tdWidtBut">Min</td>
                                    <td className="topColumn tdWidtBut">Délai</td>
                                    <td className="topColumn tdWidtBut">Action</td>
                                    <td className="topColumn tdWidtText">Fournisseur</td>
                                    <td className="topColumn tdWidtText">Ref. Fournisseur</td>
                                    <td className="topColumn tdWidtBut">Prix</td>
                                </tr>
                                { stockState.stock.map((t) => (
                                    <tr key={dispResum(t.id)}>
                                        <td  
                                            onMouseOver={manageRefreshing}
                                            onMouseOut={stopRefreshing}
                                            className="editMarker" 
                                            onClick={(e) => editArticle(t.id, setCurrentId, stockState, setStockState)}>
                                                {dispResum(t.ref)}
                                        </td>
                                        <td  
                                            onMouseOver={manageRefreshing}
                                            onMouseOut={stopRefreshing}
                                            className="editMarker" 
                                            onClick={(e) => editArticle(t.id, setCurrentId, stockState, setStockState)}>
                                                {dispResum(t.designator)}
                                        </td>
                                        <td  
                                            onMouseOver={manageRefreshing}
                                            onMouseOut={stopRefreshing}
                                            className="editMarker" 
                                            onClick={(e) => editArticle(t.id, setCurrentId, stockState, setStockState)}>
                                            <ul className="categoriesList">
                                                { t.categories && t.categories.map((caties, index) => 
                                                        <li key={"caties"+index}>{dispResum(caties)}</li>
                                                )}
                                            </ul>
                                        </td>
                                        <td>
                                            <div className="quantContainer">
                                                <button 
                                                    id="quantDesc"
                                                    onClick={() => updateQuantity (currentUser, stockState, setStockState, setQuantAutoriz, quantChange, setQuantChange, "sub", t.id)}> - </button>
                                                <input 
                                                type="number" 
                                                id="quantInput"
                                                style={{"background": t.quantity <= t.min_quantity && ("#E1755E")}}
                                                value={t.quantity} 
                                                size="4"
                                                min="0" max="9999" step="1" 
                                                onMouseLeave={(e) => updateQuantity (currentUser, stockState, setStockState, setQuantAutoriz, quantChange, setQuantChange, "change", t.id, e)}
                                                onChange={(e) => changeQuantity(t.id, e, stockState, setStockState, quantAutoriz)}
                                                />
                                                <button 
                                                    id="quantAsc"
                                                    onClick={() => updateQuantity (currentUser, stockState, setStockState, setQuantAutoriz, quantChange, setQuantChange, "add", t.id)}> + </button>
                                            </div>
                                            {(!t.command) && (
                                                <div className="commandAction" onClick={() => addCommand(buildCommandInfo, setBuildCommandInfo, t)}>
                                                    <span className="commandText" style={{"color": "red"}}>Pas de commande</span>
                                                </div>
                                            )}
                                            {(t.command) && (
                                                <div className="commandAnimation" onClick={() => addCommand(buildCommandInfo, setBuildCommandInfo, t)}>
                                                    <span className="orderIndication">
                                                    {t.command.get_date ? t.command.get_date+" " : "pas de date "}: 
                                                    {t.command.command_quantity ? t.command.command_quantity : " X"} pc </span>
                                                    <span className="commandAnimationIcon material-symbols-outlined noselect">airport_shuttle</span>
                                                </div>
                                            )}
                                        </td>
                                        <td  
                                            onMouseOver={manageRefreshing}
                                            onMouseOut={stopRefreshing}
                                            className="editMarker" 
                                            onClick={(e) => editArticle(t.id, setCurrentId, stockState, setStockState)}>
                                                {t.min_quantity}
                                        </td>
                                        <td  
                                            onMouseOver={manageRefreshing}
                                            onMouseOut={stopRefreshing}
                                            className="editMarker" 
                                            onClick={(e) => editArticle(t.id, setCurrentId, stockState, setStockState)}>
                                                {t.delay_appro}
                                        </td>
                                        <td>
                                            <span 
                                                className="stockButEditCommandForm material-symbols-outlined"
                                                onClick={() => editStockCommandForm(t.id, t.provider, setCurrentId, openCommandForm, setOpenCommandForm, setCurrentProvider)} >
                                                draft_orders
                                            </span>
                                        </td>
                                        <td 
                                        className="editProvider" 
                                        onClick={() => managThisProvider(t.provider, currentUser, stockState, setStockState, setManagProvider)}>
                                            {dispResum(t.provider)}
                                        </td>
                                        <td  
                                            onMouseOver={manageRefreshing}
                                            onMouseOut={stopRefreshing}
                                            className="editMarker" 
                                            onClick={() => editArticle(t.id, setCurrentId, stockState, setStockState)}>
                                                {dispResum(t.provider_ref)}
                                        </td>
                                        <td  
                                            onMouseOver={manageRefreshing}
                                            onMouseOut={stopRefreshing}
                                            className="editMarker" 
                                            onClick={() => editArticle(t.id, setCurrentId, stockState, setStockState)}>
                                                {dispResum(t.price)}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
  )
}