import React, { useContext, useRef, useState } from 'react'
import { UserContext } from '../context/userContext';
import axios from "axios";

function SignUpModal() {

    const { modalState, toggleModals } = useContext(UserContext);
    const [validation, setValidation] = useState("");
    const [infoAuth, setInfoAuth] = useState(false);
    const inputs = useRef([]);
    const addInputs = el => {
        if(el && !inputs.current.includes(el)) {
            inputs.current.push(el);
        }
    }
    const formRef = useRef();
    
    function handleForm (e) {
        e.preventDefault()
            if((inputs.current[1].value.length || inputs.current[2].value.length) < 6) {
                setValidation("6 caractéres minimum");
            }
            else if (inputs.current[1].value !== inputs.current[2].value) {
                setValidation("Erreur de saisie des mots de passe");
            } else {
                (async() => {     
                    const signUpRequest  = await axios({
                        method: 'post', 
                        url: process.env.REACT_APP_SERVER_NAME,
                        params: {ur: 'ac'},
                        data: { 
                            "user": {
                                "email": inputs.current[0].value,
                                "password": inputs.current[1].value
                            }
                        },
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + process.env.REACT_APP_ACCOUNT_CREATION
                        }
                    }).then((resp) => {
                        if (resp.status) {
                            if (resp.status === 200 && resp.data.message === "Pré-authentification réussie") {
                                setValidation("");
                                toggleModals("close");
                                setInfoAuth(true);
                            }
                            if (resp.status === 200 && resp.data.message !== "Pré-authentification réussie") {
                                inputs.current = [];
                                setValidation(resp.data.message);
                                setTimeout(() => toggleModals("close"), 1500);
                            }
                            if (resp.status !== 200) {
                                setValidation("Erreur du serveur, désolé");
                            }
                        }
                    })
                })()
            }
        
    }
    const closeModal = () => {
        setValidation("")
        toggleModals("close")
    }
    
    return (
        <>
            {infoAuth && (
                <div className="modalConfirmSignUp">
                    <button 
                        onClick={() => setInfoAuth(false)} 
                        className="closeButStyle">
                        <span className="material-symbols-outlined">cancel</span>
                    </button>
                    <div className="h2ConfirmSignUp">
                        <h2>Vous avez reçu un mail</h2>
                    </div>
                    <div className="p1ConfirmSignUp">
                        <p>Afin de confirmer votre identié, veuillez consultez votre boite mail et valider le lien "cliquez <u>ICI</u>".</p>
                    </div>
                    <div className="p2ConfirmSignUp">
                        <p>Vérifiez votre dossier spam, l'expéditeur est identifiable sous l'intitulé: </p>
                        <p><strong>Pascal C - manager-système,<br/>noreply@manager-système.fr</strong></p>
                    </div>

                </div>
            )}
            { !infoAuth && modalState.signUpModal && (
                <dialog className="diagSign" open>
                    <div className="">
                        <div className="">
                            <div className="">
                                <button 
                                onClick={() => closeModal()} 
                                className="closeButStyle">
                                <span className="material-symbols-outlined">cancel</span>
                                </button>
                                <h5 className="modalUpTitle">Inscrivez vous!</h5>
                            </div>
                            <div className="">
                                <form className="signinForm" ref={formRef} onSubmit={handleForm}>
                                    <div className="mb-3">
                                        <label htmlFor="signInEmail" className="">Adresse mail :</label>
                                        <input  type="email" 
                                        ref={addInputs} 
                                        name="email" 
                                        className=""
                                        id="signInEmail"
                                        autoFocus 
                                        required />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="signInPwd" className="">Mot de passe : <sub>(min 6)</sub></label>
                                        <input  
                                            key="pwd1"
                                            type="password" 
                                            ref={addInputs} 
                                            name="pwd" 
                                            className=""
                                            id="signIn1"
                                            required />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="signInPwd" className="">Confirmez :</label>
                                        <input  
                                            key="pwd2"
                                            type="password" 
                                            ref={addInputs} 
                                            name="pwd" 
                                            className=""
                                            id="signIn2"
                                            required />
                                    </div>
                                    <p className="validationMessage">{validation}</p>
                                    <button id="fakeBut">
                                        <span id="buttonDemo3" style={{"--clr": "lightgreen"}}>
                                            <span>Soumettre</span>
                                            <i></i>
                                        </span>
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </dialog>
            )}
        </>
    )
}
export default SignUpModal