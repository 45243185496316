import React, { useState, useEffect, useRef, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toggleTimeCountTask, toggleStopCounter } from "../redux/reduxStore";
import { UserContext } from '../context/userContext';
import Counter from "./TaskComponents/Counter";
import DeployButton from "./TaskComponents/DeployButton";
import EditCUDButtons from "./TaskComponents/EditCUDButtons";
import ModalDeletion from "./TaskComponents/ModalDeletion";
import SetProgression from "./TaskComponents/SetProgression";
import ModalLoopForm from "./TaskComponents/ModalLoopForm";
import FileManager from "./TaskComponents/FileManager";
import FileIndicator from "./TaskComponents/FileIndicator";
import TaskContent from "./TaskComponents/TaskContent";
import updateTask from "./TasksRequest/updateTask";

export default function Task({item, nbCounter, setNbCounter, taskInCounter}) {

    const task = useSelector((state) => state.todo.find((t) => t.id === item.id));
    const dispatch = useDispatch(); 
    const { currentUser } = useContext(UserContext);
    const interv = useRef(null);

    const [taskState, setTaskState] = useState({
        deploy: "90px",
        deployCounter: false,
        editMode: false,
        loopData: [1, 1],
        modalDeletion: false,
        modalLoopForm: false,
        nbFile: 0,
        opacityBut: "0",
        startCounter: item.currentTimerStarting,
        textLoopBut: "Boucler"
    });
    useEffect (() => {
        if (item.currentTimerStarting === "start" || taskState.startCounter === "start") {
            setTimer("startMemo");
        }
        if (item.loop && item.loop.length === 2) {
            setTaskState({  ...taskState, 
                            loopData: [item.loop[0] / 86400, item.loop[1]], 
                            textLoopBut: item.loop[0] / 86400 + " jours"});
        } else {
            setTaskState({...taskState, textLoopBut: "Boucler"});
        }

        return () => {
            if(interv.current) {
                clearInterval(interv.current);
            }
        }
    }, [])

    function expand() {
        if (taskState.deploy === "90px") {
            setTaskState({  ...taskState, 
                            deploy: "700px", 
                            opacityBut: "1", 
                            deployCounter: false});
            return ;
        }
        if (taskState.deploy === "700px") {
            updateTask(false, currentUser, task);
            setTaskState({  ...taskState, 
                            deploy: "90px", 
                            opacityBut: "0", 
                            editMode: false, 
                            modalDeletion: false, 
                            modalLoopForm: false});
            return ;
        }
    }
    
    function setTimer (starter) {
        if ((starter === "start" && !interv.current && nbCounter < 4 && !taskInCounter.includes(item.id)) ||
            (starter === "startMemo" && !interv.current && nbCounter <= 4)) {
            interv.current = setInterval(setClock, 1000);
            if (!taskInCounter.includes(item.id)) {taskInCounter.push(item.id);}
            setTaskState({...taskState, startCounter: true});
        } else {
            setTaskState({...taskState, deployCounter: false});
        }
        if (starter && nbCounter < 4) {
            updateTask("start", currentUser, task);
        }
        if (starter !== "start" && starter !== "startMemo") {
            clearInterval(interv.current);
            setTaskState({...taskState, startCounter: false});
            taskInCounter.splice(taskInCounter.indexOf(item.id), 1);
            updateTask("stop", currentUser, task);
            interv.current = null;
            dispatch(toggleStopCounter(task.id));
        }
        setNbCounter(taskInCounter.length);
    } 
    function setClock () {
        dispatch(toggleTimeCountTask(task.id));
    }  
        
    return (  
        <div className="taskStyle noselect" key={task.id} style={{"maxHeight": taskState.deploy}}>
            { taskState.modalDeletion && (
                <ModalDeletion 
                task={task} 
                taskState={taskState}
                setTaskState={setTaskState} /> 
            )}
            { !taskState.editMode &&(
                <Counter 
                    taskId={task.id}
                    setTimer={setTimer}
                    taskTimeCount={task.timeCount}
                    taskState={taskState}
                    setTaskState={setTaskState} />
            )}
            {(!taskState.editMode && taskState.deploy === "90px") && (
                <FileIndicator nbFile={taskState.nbFile} />  
            )}
            <DeployButton expand={expand} taskState={taskState} />
            <div className="taskButtons">
                <SetProgression 
                    taskId={task.id} 
                    taskState={taskState}
                    taskProgress={task.progress} />
                <EditCUDButtons 
                    task={task} 
                    expand={expand}
                    taskState={taskState}
                    setTaskState={setTaskState} />
            </div>
            { taskState.modalLoopForm && (
                <ModalLoopForm 
                    task={task}
                    taskState={taskState}
                    setTaskState={setTaskState} />
            )}
            <TaskContent editMode={taskState.editMode} task={task} />
            <FileManager
                currentUser={currentUser}
                task={task} 
                taskState={taskState}
                setTaskState={setTaskState} />
        </div>
    )
}
