import axios from "axios";

function deleteFile(filename, task, currentUser, docs, setDocs, setConfirmDelFile,  taskState, setTaskState ) {

    setConfirmDelFile(false);

    var newDocsTab = [];
    docs.map((doc, index) => {
        if (doc !== filename) {
            newDocsTab.push(doc);
        }
    })
    setDocs(newDocsTab);
    setTaskState({...taskState, nbFile: newDocsTab.length});

    (async() => {     
        const delFile  = await axios({
            method: 'post', 
            url: process.env.REACT_APP_SERVER_NAME,
            params: {de: 'fi'},
            data: {
                user_email: currentUser.email,
                user_teamUid: currentUser.teamUid,
                user_role: currentUser.role,
                file: filename
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + currentUser.token
            }
        })
    })()

    return ;
}

export default deleteFile;