import controlText from '../controlText';

function progressFilter (stockState, setStockState, textFetch, setTextFetch, name, e) {

    if (controlText(e.target.value).length > -1 && name === "ref") {
        setTextFetch({...textFetch, refTextFetch: e.target.value});
        setStockState({...stockState, refValues: []});
        var refText = [];
        stockState.refValues.map((r) => { 
            if (controlText(e.target.value).toLowerCase() === r.slice(0, controlText(e.target.value).length).toLowerCase()) {
                refText.unshift(r);
            } else {
                refText.push(r);
            }
        })
        setStockState({...stockState, 
                        refValues: refText, 
                        refTextValues: refText, 
                        desTextValues: [],
                        catTextValues: [],
                        proTextValues: [],
        })
    }
    if (controlText(e.target.value).length > -1 && name === "des") {
        setTextFetch({...textFetch, desTextFetch: e.target.value});
        setStockState({...stockState, desValues: []});
        var desText = [];
        stockState.desValues.map((r) => { 
            if (controlText(e.target.value).toLowerCase() === r.slice(0, controlText(e.target.value).length).toLowerCase()) {
                desText.unshift(r);
            } else {
                desText.push(r);
            }
        })
        setStockState({...stockState, 
                        desValues: desText, 
                        refTextValues: [], 
                        desTextValues: desText,
                        catTextValues: [],
                        proTextValues: [],
        })
    }
    if (controlText(e.target.value).length > -1 && name === "cat") {
        setTextFetch({...textFetch, catTextFetch: e.target.value});
        setStockState({...stockState, catValues: []});
        var catText = [];
        stockState.catValues.map((r) => { 
            if (controlText(e.target.value).toLowerCase() === r.slice(0, controlText(e.target.value).length).toLowerCase()) {
                catText.unshift(r);
            } else {
                catText.push(r);
            }
        })
        setStockState({...stockState, 
                        catValues: catText, 
                        refTextValues: [], 
                        desTextValues: [],
                        catTextValues: catText,
                        proTextValues: [],
        })
    }
    if (controlText(e.target.value).length > -1 && name === "prov") {
        setTextFetch({...textFetch, proTextFetch: e.target.value});
        setStockState({...stockState, proValues: []});
        var provText = [];
        stockState.proTextValues.map((r) => { 
            if (controlText(e.target.value).toLowerCase() === r.slice(0, controlText(e.target.value).length).toLowerCase()) {
                provText.unshift(r);
            } else {
                provText.push(r);
            }
        })
        setStockState({...stockState, 
                        proValues: provText, 
                        refTextValues: [], 
                        desTextValues: [],
                        catTextValues: [],
                        proTextValues: provText,
        })
    }
}

export default progressFilter;