import React from "react";

function CguLink({globalState, setGlobalState}) {
    return (
        <div className="cguContainer"  onClick={() => setGlobalState({...globalState, cgu: !globalState.cgu})}>
            <span className="material-symbols-outlined cguIcon" translate="no">receipt_long</span>
            <span className="cguText1" >Conditions Générales d'utilisation</span>
            <span className="cguText2" >CGU</span>
        </div>
    )
}
export default CguLink;