import axios from "axios";

function sendComment (currentUser, comment, headerState, setHeaderState) {

    (async() => { 
      const sendComment  = await axios({
        method: 'post', 
        url: process.env.REACT_APP_SERVER_NAME,
        params: {ur: 'cm'},
        data: {
            user_email: currentUser.email,
            user_teamUid: currentUser.teamUid,
            userComment: {
                typetool: "Todos",
                comment: comment
            }
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentUser.token
        }
    }).then((resp) => {
        if (resp.data.comment === "commentOk") {
            setHeaderState({...headerState, commentSent: true, commentWindow: false });
        }
        })
    })()
}
export default sendComment;