import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {    togglePriorityTask, 
            toggleTitleTask, 
            toggleContentTask } from "../../redux/reduxStore";
import controlText from "./controlText";

export default function TaskContent({editMode, task}) {

    const dispatch = useDispatch();
    const [titleLen, setTitleLen] = useState(0);
    const [contentLen, setContentLen] = useState(0);

    function changePriority (e) {
        dispatch(togglePriorityTask([e.target.id, e.target.value]));
    }
    function changeTitle (e) { 
        setTitleLen(controlText(e.target.value).length);
        if (titleLen < 26) {
            dispatch(toggleTitleTask([e.target.id, controlText(e.target.value)]));
            document.getElementsByClassName("taskSecurTitle").inerText = controlText(e.target.value);
        }
    }
    function changeContent (e) {
        setContentLen(controlText(e.target.value).length);
        if (contentLen < 1024) {
            dispatch(toggleContentTask([e.target.id, controlText(e.target.value)]));
            document.getElementsByClassName("taskSecurContent").inerText = controlText(e.target.value);
        }
    }

    return (
        <>
        { !editMode && (
                <>
                    <div className="taskTitle">
                        <h4 id="h4title">{task.title}</h4>
                    </div>
                    <div className={"taskPriority t" + task.priority}>
                        <p>
                            <span className="priorityLabel">Priorité: </span>
                            <strong>{task.priority}</strong>
                        </p>
                    </div>
                    <div className="taskText">
                        <p className="dispTextarea">{task.content}</p>
                    </div>
                </>
            )}       
            { editMode && (
                <>
                    <div className="h4titleDiv">
                        <label className="h4titleLab" htmlFor={task.id}>Titre 
                            <i id="titleCharMark">( {titleLen} / 25 )</i>: 
                        </label>
                        <input type="text" name={task.id} id={task.id} className="h4titleForm taskSecurTitle" maxLength="25" size="25" value={task.title} onChange={changeTitle} />
                    </div>
                    <div className="taskPriorityInput">
                        <label style={{"marginRight": "15px"}}>Priorité: <strong>{task.priority}</strong></label>
                        <input type="range" min="1" max="10" step="1" id={task.id} value={task.priority} onChange={changePriority} />
                    </div>
                    <div className="taskTextArea">
                        <i id="contentCharMark">( {contentLen} / 1024 )</i>
                        <br/>
                        <textarea rows="5" id={task.id} value={task.content} onChange={changeContent} className="taskSecurContent"></textarea>
                    </div>
                </>
            )}
        </>
    )
}
