import React, { useContext } from 'react'
import { UserContext } from '../../context/userContext';
import changeLoop from "../TasksRequest/changeLoop";

export default function ModalLoopForm({ task,
                                        taskState,
                                        setTaskState }) {

    const { currentUser } = useContext(UserContext);

    function handleDayNb(e) {
        setTaskState({...taskState, loopData: [e.target.value, taskState.loopData[1]]});
    }
    function handleOccNb(e) {
        setTaskState({...taskState, loopData: [taskState.loopData[0], e.target.value]});
    }
    function stopLoop() {
        changeLoop(false, currentUser, task);
        setTaskState({  ...taskState, 
                        textLoopBut: "Boucler", 
                        modalLoopForm: false});
    }
    function startLoop() {
        changeLoop(true, currentUser, task);
        setTaskState({  ...taskState, 
                        textLoopBut: document.getElementById("rytme").value + "jours", 
                        modalLoopForm: false});
    }

    return (
        <div className="modalLoopForm">
            <h3>Paramètrage de la boucle</h3>
            <p><i>
                Cette tâche sera répétée au délai que vous souhaitez.<br/>
                Les titres, textes et la priorité seront ceux de cet instant.<br/>
                L'état sera "A faire" et le chronomètre initialisé à 0.<br/>
                Les fichiers attachés ne seront pas répétés.
            </i></p>
            <br/>
            <p>
                Répétition tout les <input type="number" id="rytme" min="1"  max="400" step="1" size="4" value={taskState.loopData[0]} onChange={handleDayNb} /> jours
            </p>
            <p>
                Nombre de répétitions: <input type="number" id="occurNb" min="1"  max="400" step="1" size="4" value={taskState.loopData[1]} onChange={handleOccNb} /> fois
            </p>
            <div>
                { (taskState.textLoopBut !== "Boucler") && (
                    <button id="stopLoop" onClick={stopLoop}>Arreter la boucle</button>
                )}    
                { (taskState.textLoopBut === "Boucler") && (
                    <button id="validLoop" onClick={startLoop}>Valider la boucle</button>
                )}
                <button id="cancelLoop" onClick={() => setTaskState({...taskState, modalLoopForm: false})}><span className="material-symbols-outlined">cancel</span></button>
            </div>
        </div>
    )
}
