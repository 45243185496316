import axios from "axios";

async function editMountage(ref, currentUser, setAssemblyDetail) {
  
    await axios({
        method: 'post', 
        url: process.env.REACT_APP_SERVER_NAME,
        params: {ass: 'detail'},
        data: {
            user_email: currentUser.email,
            user_teamUid: currentUser.teamUid,
            user_role: currentUser.role,
            action: "readOneAssembly",
            ref: ref
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentUser.token
        }
    }).then((resp) => {
        if (resp.status === 200 && resp.data) {
            setAssemblyDetail(resp.data.assemblyDetail);
        }
    })
}

export default editMountage;