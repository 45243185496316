import React from "react";

export default function StateRadioButton({classes, taskId, value, changeProgress, taskProgress}) {
    
    return (
        <div>
            <span style={{"display": "flex", "width": "100px"}}>
                <input 
                    type="radio" 
                    className={classes}
                    id={taskId} name={taskId} value={value}
                    onChange={changeProgress}
                    checked={taskProgress === value}
                    />
                <label htmlFor={taskId}>{value}</label>
            </span>    
        </div>
    )
}
