import React, { useState, useEffect, useRef } from "react";
// import axios from "axios";
import controlText from './controlText';
import getProviderArticles from './stockRequest/getProviderArticles';
import getCommand from './stockRequest/getCommand';

export default function CommandForm({currentUser, currentProvider, openCommandForm, setOpenCommandForm, currentId, setCurrentId, stockState, setStockState}) {

    const [commandState, setCommandState] = useState({
        type: "command",
        customerName: "",
        customerAddressOne: "",
        customerAddressTwo: "",
        customerZipTown: "",
        customerSiret: "",
        customerTva: "",
        customerPhone: "",
        providerAddressOne: "",
        providerAddressTwo: "",
        providerZipTown: "",
        providerName: "",
        article: "",
        otherArticles: ""
    });

    useEffect (() => {
        if (!commandState.article) {
            if (commandState.type === "command") {
                document.getElementById("commandCheck").checked = true;
            } else {
                document.getElementById("commandCheck").checked = false;
            }
            getProviderArticles (   currentUser, 
                                    currentId, 
                                    currentProvider,
                                    commandState, 
                                    setCommandState, 
                                    stockState, 
                                    setStockState )
        }

        return ;
    }, [])

    function addArticle(e) {
        if (e.target.value !== "novalue") {
            var tab = commandState.article;
            tab.push(commandState.otherArticles.filter((a) => a.ref === e.target.value)[0]);
            setCommandState({
                ...commandState, 
                article: tab,
                otherArticles: commandState.otherArticles.filter((a) => a.ref !== e.target.value)
            });
        }
        document.getElementById("otherArts").selectedIndex = 0;
    }
    function delArt(ref) {
        var tabs = commandState.otherArticles;
        tabs.push(commandState.article.filter((a) => a.ref === ref)[0]);
        setCommandState({
            ...commandState, 
            article: commandState.article.filter((a) => a.ref !== ref),
            otherArticles: tabs
        });
    }
    function changeArtQuant(ref, newVal) {
        var articl = commandState.article;
        articl.map((a) => {
            if (a.ref === ref) {
                a.quant = newVal;
            }
        })
        setCommandState({...commandState, article: articl})
    }
    function sendAndDownloadCommand () {
        if (commandState.providerName && commandState.providerName.length > 1 &&
            commandState.customerName && commandState.customerName.length > 1) {
                getCommand ( currentUser, 
                    currentId, 
                    commandState, 
                    stockState, 
                    setStockState)
        } else {
            document.getElementById("customerName").style = "border-bottom: 2px solid red; background: #F19586;";
        }
    }
    function returnToPreparation () {
        document.getElementById("docxLink").innerHTML = "<span>Créer ce document</span>";
        document.getElementById("backToPreparation").innerText = "";
        document.getElementById("backToPreparation").style = "border: none";
    }

    return (
        <div className="commandFormContainer">
            <div className="commandFormHeader">
                <p 
                    className="actionGlobalBut closeCommandForm" 
                    onClick={() => setOpenCommandForm(!openCommandForm)}>
                    X
                </p>
                <p  id="docxLink"
                    className="actionGlobalBut validationCommandForm" 
                    onClick={sendAndDownloadCommand}>
                    Créer ce document
                </p>
                <p id="backToPreparation" className="actionGlobalBut backToPreparation" onClick={returnToPreparation}></p>
                <div className="commandFormTitle">
                    <div>
                        <h3>Commande / Devis</h3>
                        <h4>Fournisseur : <span>{commandState.providerName}</span></h4>
                    </div>
                    <div className="typeOfDoc">
                        <input type="radio" name="typeChoice" id="commandCheck"
                            onClick={() => setCommandState({...commandState, type: "command"})} />
                        <label>Commande</label>
                        <span style={{"marginLeft": "40px"}}></span>
                        <input type="radio" name="typeChoice"
                            onClick={() => setCommandState({...commandState, type: "estimate"})} />
                        <label>Devis</label>
                    </div>
                </div>
                <div className="commandForm">
                    <div className="commandFormCustomer">
                        <input 
                            type="text" 
                            id="customerName" 
                            value={commandState.customerName} 
                            onChange={(e) => setCommandState({...commandState, customerName: controlText(e.target.value)})}
                            placeholder="Entreprise Dupont" />
                        <input 
                            type="text" 
                            value={commandState.customerAddressOne} 
                            onChange={(e) => setCommandState({...commandState, customerAddressOne: controlText(e.target.value)})}
                            placeholder="Bat C résidence de l'avenir" />
                        <input 
                            type="text" 
                            value={commandState.customerAddressTwo} 
                            onChange={(e) => setCommandState({...commandState, customerAddressTwo: controlText(e.target.value)})}
                            placeholder="15 ZA des entreprises" />
                        <input 
                            type="text" 
                            value={commandState.customerZipTown} 
                            onChange={(e) => setCommandState({...commandState, customerZipTown: controlText(e.target.value)})}
                            placeholder="34000 Montpellier" />
                        <input 
                            type="text" 
                            value={commandState.customerSiret} 
                            onChange={(e) => setCommandState({...commandState, customerSiret: controlText(e.target.value)})}
                            placeholder="numèro de SIRET" />
                        <input 
                            type="text" 
                            value={commandState.customerTva} 
                            onChange={(e) => setCommandState({...commandState, customerTva: controlText(e.target.value)})}
                            placeholder="numéro de TVA" />
                        <input 
                            type="text" 
                            value={commandState.customerPhone} 
                            onChange={(e) => setCommandState({...commandState, customerPhone: controlText(e.target.value)})}
                            placeholder="numéro de téléphone" />
                        <p className="addressNotice">
                            Vos coordonées seront enregistrée et proposées sur chaque commandes
                        </p>
                    </div>
                    <div className="commandFormProvider">
                        <p className="dispProviderName">{commandState.providerName}</p> 
                        <input 
                            type="text" 
                            id="providerName" 
                            value={commandState.providerAddressOne} 
                            onChange={(e) => setCommandState({...commandState, providerAddressOne: controlText(e.target.value)})}
                            placeholder="lot 3 Bâtiment D" />
                        <input 
                            type="text" 
                            value={commandState.providerAddressTwo} 
                            onChange={(e) => setCommandState({...commandState, providerAddressTwo:controlText(e.target.value)})}
                            placeholder="1 rue du commerce" />
                        <input 
                            type="text" 
                            value={commandState.providerZipTown} 
                            onChange={(e) => setCommandState({...commandState, providerZipTown: controlText(e.target.value)})}
                            placeholder="34000 Montpellier" />
                        <p className="addressNotice">
                            Les coordonées de ce fournisseur seront enregistrées et proposées à chaque commande lui étant adréssée.
                        </p>
                    </div>
                    <div className="commandTextHeader">
                        <span style={{"lineHeight": "30px"}}>Bonjour,</span>
                        <br/>
                        <span style={{"marginRight": "80px"}}>
                        {(commandState.type === "command") && "Veuillez trouver ci-joint ma commande:"}
                        {(commandState.type === "estimate") && "Veuillez chiffrer les articles ci-dessous:"}
                        </span>
                        <br/>
                        <table className="commandTable">
                            <thead>
                                <tr className="commandTablHead">
                                    <th className="commandRef">Référence</th>
                                    <th className="commandDes">Désignation</th>
                                    <th className="commandQua">Quantité</th>
                                </tr>
                            </thead>
                            <tbody>
                                {commandState.article && (
                                    <>
                                        {commandState.article.map((art, index) => (
                                            <tr className="commandLine" key={index}>
                                                <td className="commandRef">
                                                    <span className="delArt" onClick={() => delArt(art.ref)}>X</span>
                                                    {art.ref}
                                                </td>
                                                <td className="commandDes">{art.designator}</td>
                                                <td className="commandQua">
                                                    <input 
                                                    type="number" 
                                                    min="1" max="999" step="1" 
                                                    value={art.quant} 
                                                    onChange={(e) => changeArtQuant(art.ref, e.target.value)} />
                                                </td>
                                            </tr>
                                        ))}
                                    </>
                                )}
                                { commandState.otherArticles && (
                                    <tr>
                                        <td colSpan="3" className="commandPartsNotice">
                                            <span style={{"marginLeft":"30px"}}>Ajouter d'autres pièces: </span>
                                            <select id="otherArts" onClick={(e) => addArticle(e)}>
                                                <option key="novalue" value="novalue">Votre choix</option>
                                                {commandState.otherArticles.map((art, index) => (
                                                    <option key={index} value={art.ref}>
                                                        {art.ref}
                                                    </option>
                                                ))}
                                            </select>
                                        </td> 
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
