import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { toggleProgressTask } from "../../redux/reduxStore";
import StateRadioButton from "../TaskComponents/StateRadioButton";

export default function SetProgression({taskId, taskState, taskProgress}) {

    const dispatch = useDispatch();
    const [colorProgress, setColorProgress] = useState("grey");

    useEffect (() => {    
        changeProgressColor(taskProgress);

    })

    function changeProgress (e) {
        dispatch(toggleProgressTask([e.target.id, e.target.value])); 
        changeProgressColor(e.target.value);
    }

    function changeProgressColor (state) {
        if (state === "A faire") { setColorProgress("red");}
        if (state === "En cours") { setColorProgress("rgb(233, 253, 56)"); }
        if (state === "Fait") { setColorProgress("#4CEFA6"); }
        if (state === "A valider") { setColorProgress("lightgreen"); }
    } 

    return (
        <>
            <div 
                id="stateDisplayed" 
                className="dispState"
                style={{"color": colorProgress}} >
                <span className="noeditState" >
                    <span className="noeditStateText">
                        {taskProgress}
                    </span>
                    <span 
                        className="material-symbols-outlined butIcon" 
                        style={{
                            "color": colorProgress, 
                            "borderBottom": "4px solid "+ colorProgress
                        }} 
                        translate="no" >
                            lightbulb
                    </span>
                </span>
                { taskState.editMode && (
                    <span className="stateInputZone" >
                        <StateRadioButton  
                                classes="stateInput prog0" 
                                taskId={taskId}
                                value="A faire"
                                changeProgress={changeProgress}
                                taskProgress={taskProgress} />
                        <StateRadioButton  
                                classes="stateInput prog1" 
                                taskId={taskId}
                                value="En cours"
                                changeProgress={changeProgress}
                                taskProgress={taskProgress} />
                        <StateRadioButton  
                                classes="stateInput prog2" 
                                taskId={taskId}
                                value="Fait"
                                changeProgress={changeProgress}
                                taskProgress={taskProgress} />
                        <StateRadioButton  
                                classes="stateInput prog3" 
                                taskId={taskId}
                                value="A valider"
                                changeProgress={changeProgress}
                                taskProgress={taskProgress} />
                    </span>
                ) }
            </div>
        </>
    )
}
