import React, { useState, useEffect } from "react";
import loadFile from "../TasksRequest/loadFile";
import deleteFile from "../TasksRequest/deleteFile";
import exportFile from "../TasksRequest/exportFile";

export default function FileManager({currentUser, task, taskState, setTaskState }) {

    const [docs, setDocs] = useState(task.docs);
    const [confirmDelFile, setConfirmDelFile] = useState(false);
    

    useEffect (() => {
        if (taskState.nbFile !== docs.length) {
            setTaskState({...taskState, nbFile: docs.length});
        }
    
    })

    return (
        <div 
            className="taskFileLoader"
            style={{"marginTop": taskState.editMode ? "50px" : "5px"}} >
            <span id="taskFileLegend">Fichiers joints</span>
            <div  className="fileShowZone">
                { docs.map((d, index) => 
                    <div key={index} className={"oneFileShow " + d.substr(-3)}>
                        { (taskState.editMode && confirmDelFile !== d) &&
                            <span 
                                className="deleteFileSymb material-symbols-outlined"
                                onClick={() => setConfirmDelFile(d)}>
                                    delete
                            </span>
                        }
                        { (taskState.editMode && confirmDelFile === d) &&
                            <>
                                <span 
                                    className="cancelDeleteFile"
                                    onClick={() => setConfirmDelFile(false)}>
                                        Annuler
                                </span>
                                <span 
                                    className="deleteFile"
                                    onClick={() => deleteFile(d, task, currentUser, docs, setDocs, setConfirmDelFile, taskState, setTaskState )}>
                                        Confirmez?
                                </span>
                            </>    
                        }
                        <span 
                            className="showFileName"
                            onClick={(() => exportFile(d, currentUser, task, index))}>
                            <span id={"showFileLink"+index+task.id}></span>
                                {d.substr(16)}
                        </span>
                    </div>
                )}
            </div>
            { taskState.editMode && (
            <div className="inputFileInput">
                <form id="fileForm" name="fileForm" encType="multipart/form-data">
                    <label htmlFor={task.id}
                        style={{"color": (taskState.nbFile < 5) ? "black" : "red"}} >
                        {taskState.nbFile < 5 && "Nouveau fichier?"}
                        {taskState.nbFile >= 5 && "5 fichiers maximum"}
                        <sub><i>png, jpg, pdf, docx, xlsx, pptx</i></sub>
                    </label>
                    <br/>
                    { (taskState.nbFile < 5) && (
                        <input 
                            type="file" 
                            id={task.id + "file"} 
                            name={task.id + "inpFile"} 
                            onChange={(e) => loadFile(e, currentUser, task, setDocs, taskState, setTaskState )}
                            />
                    )}
                </form>
            </div>
            )}
        </div>        
    )
}
