import React from "react";
import buildQuizState from "./articleFunctions/buildQuizState";

export default function RadioQuizForm({id, indicatorColor, content, quizContent, setQuizContent}) {
    return (
        <div className="oneCheckbox">
            <input type="radio" name="radio" />
            <span 
                className="lengthIndicator"
                style={{"color": indicatorColor}}>
                {(content) ? content.length : 0} / 30
            </span>
            <label htmlFor="radio1">
                <input type="text" id={id}
                    onChange={() => buildQuizState("radio", quizContent, setQuizContent)}
                    value={content ? content : "" }/>
            </label>
        </div>
    )
}
