import React, { useContext, useRef, useState, useEffect } from 'react'
import { UserContext } from '../context/userContext';
import axios from "axios";
import Cookies from 'universal-cookie';

function SignInModal({setApiIdentifier}) {

    const { modalState, toggleModals, setCurrentUser, currentUser } = useContext(UserContext);
    const cookies = new Cookies();
    const [validation, setValidation] = useState("")
    const [remember, setRemember] = useState(false)
    const inputs = useRef([])
    const addInputs = el => {
        if(el && !inputs.current.includes(el)) {
            inputs.current.push(el)
        }
    }
    const formRef = useRef()

    useEffect(() => {

        return (() => {  
        })
    })
    
    function handleForm (e) {
        e.preventDefault()
        if(inputs.current[0].value.length  < 6 || inputs.current[1].value.length < 6) {
            setValidation("6 caractéres minimum");
        } else  {
            ( async() => {     
                const identification  = await axios({
                    method: 'post', 
                    url: process.env.REACT_APP_SERVER_NAME,
                    params: {ur: 'cu'},
                    data: { 
                        "user": {
                            "email": inputs.current[0].value,
                            "password": inputs.current[1].value
                        }
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + process.env.REACT_APP_ACCOUNT_CREATION
                    }
                }).then((resp) => {
                    if (resp.status === 200 && resp.data.message === 'Connecté' && resp.data.token) {
                        if (remember) {
                            cookies.set('managersysteme_keepconnection', resp.data.apiIdentifier, Date.now() + 604800);
                        }
                        cookies.set('managersysteme_keepauthorization', resp.data.token, Date.now() + 604800);
                        setCurrentUser({...currentUser, token: resp.data.token, email: resp.data.email});
                        setApiIdentifier(false);
                        closeModal();
                    }
                    if (resp.status === 200  && resp.data.message !== 'Connecté' ) {
                        setValidation("Erreur d'identification");
                    }
                })
            })()
        }
    }
    const closeModal = () => {
        setValidation("")
        toggleModals("close")
    }
    
    return (
        <>
            {modalState.signInModal && (
                <dialog className="diagSign" open>
                    <div className="">
                        <div className="">
                            <div className="">
                                <button 
                                    onClick={() => closeModal()} 
                                    className="closeButStyle">
                                <span className="material-symbols-outlined">cancel</span>
                                </button>
                                <h5 className="modalTitle">Identifiez vous!</h5>
                            </div>
                            <div className="">
                                <form className="signinForm" ref={formRef} onSubmit={handleForm}>
                                    <div className="mb-3">
                                        <label htmlFor="signInEmail" className="">Adresse mail :</label>
                                        <input  
                                            type="email" 
                                            ref={addInputs} 
                                            name="email" 
                                            className=""
                                            id="signInEmail"
                                            autoFocus
                                            required />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="signInPwd" className="">Mot de passe :</label>
                                        <input  
                                            type="password" 
                                            ref={addInputs} 
                                            name="pwd" 
                                            className=""
                                            id="signInPwd"
                                            required />
                                    </div>
                                    <p className="validationMessage">{validation}</p>
                                    <div className="cguValidation">
                                        <label htmlFor="cguValidation" className="">J'ai lu les CGU</label>
                                        <input 
                                            type="checkbox"
                                            name="cguValidation" 
                                            className="additionalInput"
                                            required />
                                    </div>
                                    <div className="rememberCheck">
                                        <label htmlFor="rememberme" className="">Se souvenir de moi</label>
                                        <input 
                                            type="checkbox"
                                            name="rememberme" 
                                            onClick={() => setRemember(true)}
                                            className="additionalInput" />
                                    </div> 
                                    <button id="fakeBut">
                                        <span id="buttonDemo3" style={{"--clr": "lightgreen"}}>
                                            <span>Soumettre</span>
                                            <i></i>
                                        </span>
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </dialog>
            )}
        </>
    )
}
export default SignInModal

/*                                  

*/