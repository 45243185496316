import axios from "axios";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

function logOut ( email, token ) {
                        

    (async () => {
        cookies.remove('managersysteme_keepauthorization');
        cookies.remove('managersysteme_keepconnection');
        const disconnect = await axios({
            method: 'post', 
            url: process.env.REACT_APP_SERVER_NAME,
            params: {ur: 'dc'},
            data: {
                user_email: email
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        })
    })()
    window.location.reload();
}
export default logOut;