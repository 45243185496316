import axios from "axios";

function initPassword(headerState, setHeaderState) {
    if (headerState.initPwd) {
        (async () => {
            const initPwd = await axios({
                method: 'post',
                url: process.env.REACT_APP_SERVER_NAME,
                params: {pwd: 'init'},
                data: {userUpdate: {email: headerState.tpEmail}},
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + process.env.REACT_APP_SPECIAL
                },
            })
        })()
    }
    setHeaderState({...headerState, tpEmail: "", initPwd: !headerState.initPwd});
}
export default initPassword;