import React from "react";
import CheckQuizForm from "./CheckQuizForm";

export default function DispCheckForm({quizContent, setQuizContent, buildQuizState}) {
    return (
        <div className="sheetLine">
            <span 
                className="lengthIndicator"
                style={{"color": quizContent.quiz.quiz && quizContent.quiz.quiz.length <= 2 ? "red" : "green"}}>
                {quizContent.quiz.quiz.length} / 200
            </span>
            <textarea cols="50" rows="4" id="checkTypeTitle"
                placeholder="Saisissez ici votre question (200 caractères)"
                onChange={() => buildQuizState("checkbox", quizContent, setQuizContent)} 
                value={quizContent.quiz.quiz ? quizContent.quiz.quiz : "" }/>
            <br/>
            <div className="inputsList">
                <CheckQuizForm 
                    id="checkbox_1"
                    name="check1"
                    indicatorColor={(!quizContent.quiz.checkbox_1 || quizContent.quiz.checkbox_1 === "") ? "red" : "green"}
                    content={quizContent.quiz.checkbox_1}
                    quizContent={quizContent}
                    setQuizContent={setQuizContent} />
                <CheckQuizForm 
                    id="checkbox_2"
                    name="check2"
                    indicatorColor={(!quizContent.quiz.checkbox_2 || quizContent.quiz.checkbox_2 === "") ? "red" : "green"}
                    content={quizContent.quiz.checkbox_2}
                    quizContent={quizContent}
                    setQuizContent={setQuizContent} />
                <CheckQuizForm 
                    id="checkbox_3"
                    name="check3"
                    indicatorColor={(!quizContent.quiz.checkbox_3 || quizContent.quiz.checkbox_3 === "") ? "red" : "green"}
                    content={quizContent.quiz.checkbox_3}
                    quizContent={quizContent}
                    setQuizContent={setQuizContent} />
                <CheckQuizForm 
                    id="checkbox_4"
                    name="check4"
                    indicatorColor={(!quizContent.quiz.checkbox_4 || quizContent.quiz.checkbox_4 === "") ? "red" : "green"}
                    content={quizContent.quiz.checkbox_4}
                    quizContent={quizContent}
                    setQuizContent={setQuizContent} />
                <CheckQuizForm 
                    id="checkbox_5"
                    name="check5"
                    indicatorColor={(!quizContent.quiz.checkbox_5 || quizContent.quiz.checkbox_5 === "") ? "red" : "green"}
                    content={quizContent.quiz.checkbox_5}
                    quizContent={quizContent}
                    setQuizContent={setQuizContent} />
                <CheckQuizForm 
                    id="checkbox_6"
                    name="check6"
                    indicatorColor={(!quizContent.quiz.checkbox_6 || quizContent.quiz.checkbox_6 === "") ? "red" : "green"}
                    content={quizContent.quiz.checkbox_6}
                    quizContent={quizContent}
                    setQuizContent={setQuizContent} />
                <CheckQuizForm 
                    id="checkbox_7"
                    name="check7"
                    indicatorColor={(!quizContent.quiz.checkbox_7 || quizContent.quiz.checkbox_7 === "") ? "red" : "green"}
                    content={quizContent.quiz.checkbox_7}
                    quizContent={quizContent}
                    setQuizContent={setQuizContent} />
                <CheckQuizForm 
                    id="checkbox_8"
                    name="check8"
                    indicatorColor={(!quizContent.quiz.checkbox_8 || quizContent.quiz.checkbox_8 === "") ? "red" : "green"}
                    content={quizContent.quiz.checkbox_8}
                    quizContent={quizContent}
                    setQuizContent={setQuizContent} />
                <CheckQuizForm 
                    id="checkbox_9"
                    name="check9"
                    indicatorColor={(!quizContent.quiz.checkbox_9 || quizContent.quiz.checkbox_9 === "") ? "red" : "green"}
                    content={quizContent.quiz.checkbox_9}
                    quizContent={quizContent}
                    setQuizContent={setQuizContent} />
                <CheckQuizForm 
                    id="checkbox_10"
                    name="check10"
                    indicatorColor={(!quizContent.quiz.checkbox_10 || quizContent.quiz.checkbox_10 === "") ? "red" : "green"}
                    content={quizContent.quiz.checkbox_10}
                    quizContent={quizContent}
                    setQuizContent={setQuizContent} />
            </div>
        </div>
    )
}
