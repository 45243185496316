import React from "react";
import { useDispatch } from "react-redux";
import newTask from "./globalRequest/newTask";

export default function AddTaskButton({currentUser, globalState}) {
    
    const dispatch = useDispatch();

    return (
        <>
            { (globalState.maxTaskNb < 25) && (
                <div 
                    className="addButtonDiv" 
                    style={{"--headerBtColor": globalState.theme.themes.headerBtColor[globalState.themeNb]}} 
                    onClick={() => newTask(currentUser, dispatch)}>
                    <div>
                        <span id="taskIndice">{globalState.maxTasksNb}</span> 
                        <span className="material-symbols-outlined noselect addIcon" translate="no">library_add</span>
                    </div>
                    <button 
                        type="button"  
                        className="addButton" >
                        Ajouter une tâche
                    </button>
                </div>
            )}
            { (globalState.maxTaskNb > 24) && (
                <div 
                    className="addButtonDiv" 
                    id="errorMaxTask" 
                    style={{"--headerBtColor": globalState.theme.themes.headerBtColor[globalState.themeNb]}} >
                        25 max
                </div>
            )}
        </>
    )
}
