import React, { useRef, useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { stateFilterAfaire, 
        stateFilterEncours, 
        stateFilterFait, 
        stateFilterAvalider,
        stateFilterProgressAsc,
        stateFilterProgressDesc,
        stateFilterTimeAsc,
        stateFilterTimeDesc } from "../redux/reduxStore";
import { UserContext } from '../context/userContext';
import StateFilterButton from './FilterComponents/StateFilterButton';
import changeTheme from "../headerComponents/globalRequest/changeTheme";

export default function ToolBar({globalState, setGlobalState}) {

    const tasks = useSelector((state) => state.todo); 
    const dispatch = useDispatch();
    const { currentUser } = useContext(UserContext);
    const nbAfaire = useRef(0);
    const nbEncours = useRef(0);
    const nbFait = useRef(0);
    const nbAvalider = useRef(0);
    const [deployFilter, setDeployFilter] = useState("46px");

    useEffect(() => {
        indices();
    }, [tasks])

    function indices () {
        nbAfaire.current = nbEncours.current = nbFait.current = nbAvalider.current = 0;
        tasks.map((task) => {
            if (task.progress === "A faire") { nbAfaire.current += 1; }
            if (task.progress === "En cours") { nbEncours.current += 1; }
            if (task.progress === "Fait") { nbFait.current += 1; }
            if (task.progress === "A valider") { nbAvalider.current += 1; }
        })
    };
    

    function changeState (state) {
        document.getElementById("initFilter").classList = (state === "initFilter") ? "actionChoice active" : "actionChoice";
        document.getElementById("afaire").classList = (state === "afaire") ? "actionChoice active" : "actionChoice";
        document.getElementById("encours").classList = (state === "encours") ? "actionChoice active" : "actionChoice";
        document.getElementById("fait").classList = (state === "fait") ? "actionChoice active" : "actionChoice";
        document.getElementById("avalider").classList = (state === "avalider") ? "actionChoice active" : "actionChoice";
        if (state === "afaire") {
            dispatch(stateFilterAfaire());
        }
        if (state === "encours") {
            dispatch(stateFilterEncours());
        }
        if (state === "fait") {
            dispatch(stateFilterFait());
        }
        if (state === "avalider") {
            dispatch(stateFilterAvalider());
        }
        if (state === "initFilter") {
            document.getElementById("ascB").classList = "ascBut";
            document.getElementById("descB").classList = "descBut";
            document.getElementById("miniTimB").classList = "miniTimBut";
            document.getElementById("maxiTimB").classList = "maxiTimBut";
        }
    }
    function setProgressFilter(setting) {
        if (setting === "asc") {
            document.getElementById("ascB").classList = "ascBut active";
            document.getElementById("descB").classList = "descBut";
            dispatch(stateFilterProgressAsc())
        }
        if (setting === "desc") {
            document.getElementById("ascB").classList = "ascBut";
            document.getElementById("descB").classList = "descBut active";
            dispatch(stateFilterProgressDesc())
        }
        document.getElementById("miniTimB").classList = "miniTimBut";
        document.getElementById("maxiTimB").classList = "maxiTimBut";
    }
    function setTimeFilter(setting) {
        if (setting === "asc") {
            document.getElementById("miniTimB").classList = "miniTimBut active";
            document.getElementById("maxiTimB").classList = "maxiTimBut";
            dispatch(stateFilterTimeAsc())
        }
        if (setting === "desc") {
            document.getElementById("miniTimB").classList = "miniTimBut";
            document.getElementById("maxiTimB").classList = "maxiTimBut active";
            dispatch(stateFilterTimeDesc())
        }  
        document.getElementById("ascB").classList = "ascBut";
        document.getElementById("descB").classList = "descBut";
    }
    function changeThemeDb (e) {
        setGlobalState({...globalState, themeNb: (e.target.value)})
        changeTheme(e.target.value, currentUser);
    }

    return (
        <>
            { (deployFilter === "46px") && (
                <span 
                    className="material-symbols-outlined deployFilterBut noselect"
                    style={{"transform": "rotate(90deg)"}} 
                    onClick={() => setDeployFilter("120px")}>
                        wifi_protected_setup
                </span>
            )}
            { (deployFilter === "120px") && (
                <span 
                    className="material-symbols-outlined deployFilterBut noselect"
                    style={{"transform": "rotate(0)"}} 
                    onClick={() => setDeployFilter("46px")}>
                        wifi_protected_setup
                </span>
            )}
            <div 
                className="toolbarZone noselect"
                style={{ 
                        "--bgHeaderImage": globalState.theme.themes.bgHeaderImage[globalState.themeNb],
                        "--headerColor": globalState.theme.themes.headerColor[globalState.themeNb],
                        "--headerBorderColor": globalState.theme.themes.headerBorderColor[globalState.themeNb],
                        "height": deployFilter
                    }}>
                <div 
                    style={{
                            "--toolbChoiceColor": globalState.theme.themes.toolbChoiceColor[globalState.themeNb],
                            "--toolbChoiceColorH": globalState.theme.themes.toolbChoiceColorH[globalState.themeNb],
                        }}
                    className="stateBar" >
                    <div 
                        className="actionChoice active" 
                        id="initFilter" 
                        onClick={() => changeState("initFilter")}>
                            <span 
                                className="material-symbols-outlined iconFilter" 
                                style={{"paddingLeft": "20px", "fontSize": "26px"}}>
                                filter_alt_off
                            </span>
                    </div>
                    <StateFilterButton value="afaire" text="A faire" changeState={changeState} nbTask={nbAfaire.current}/>
                    <StateFilterButton value="encours" text="En cours" changeState={changeState} nbTask={nbEncours.current} />
                    <StateFilterButton value="fait" text="Fait" changeState={changeState} nbTask={nbFait.current} />
                    <StateFilterButton value="avalider" text="A Valider" changeState={changeState} nbTask={nbAvalider.current} />
                </div>
                <div className="taskStateBar">
                    <div className="unitFilterZone">
                        <select className="selectTheme" onChange={changeThemeDb}>
                            <option className="selectOption" value="2">Votre thème?</option>
                            <option className="selectOption" value="2">Thème clair</option>
                            <option className="selectOption" value="1">Thème gris</option>
                            <option className="selectOption" value="0">Thème sombre</option>
                        </select>
                    </div>
                    <div className="unitFilterZone">
                        <label htmlFor="timeFilter" style={{"color": globalState.theme.themes.toolbLabelColor[globalState.themeNb]}}>Filtre des temps</label>
                        <br />
                        <button 
                                id="miniTimB" 
                                name="timeFilter" 
                                className="miniTimBut"
                                style={{"--butColor": "#8E18C6"}} 
                                onClick={() => setTimeFilter("asc")}>
                            Croissant
                        </button>
                        <button 
                                id="maxiTimB" 
                                name="timeFilter" 
                                className="maxiTimBut"
                                style={{"--butColor": "#8E18C6"}}
                                onClick={() => setTimeFilter("desc")}>
                            Décroissant
                        </button>
                    </div>
                    <div className="unitFilterZone">
                        <label htmlFor="progressFilter" style={{"color": globalState.theme.themes.toolbLabelColor[globalState.themeNb]}}>Filtre des priorités</label>
                        <br />
                        <button 
                                id="ascB" 
                                name="progressFilter" 
                                className="ascBut" 
                                style={{"--butColor": "rgb(26, 149, 180)"}} 
                                onClick={() => setProgressFilter("asc")}>
                            Croissant
                        </button>
                        <button 
                                id="descB" 
                                name="progressFilter" 
                                className="descBut" 
                                style={{"--butColor": "rgb(26, 149, 180)"}} 
                                onClick={() => setProgressFilter("desc")}>
                            Décroissant
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
