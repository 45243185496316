function validTypes () { 
    
    return ['image/png', 
            'image/jpeg', 
            'application/pdf',
            'text/csv',  
            'text/plain',  
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation'];
}
export default validTypes;