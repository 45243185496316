
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateTask } from "../redux/reduxStore";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Task from "./Task";
import "./todosStyle/todosListStyle.css";
import "./todosStyle/taskStyle.css";


export default function TodosList({globalState, setGlobalState}) {

    const dispatch = useDispatch();
    const tasks = useSelector((state) => state.todo);
    const [nbCounter, setNbCounter] = useState(countStartingCounter());
    const taskInCounter = useRef([]);
    const getItemStyle = (isDragging, draggableStyle) => ({
        padding: 12,
        margin: `0 0 8px 0`,
        backgroundImage: globalState.theme.themes.taskTheme[globalState.themeNb],
        ...draggableStyle
    });
    const getListStyle = isDraggingOver => ({
        background: "transparent",
        maxWidth: 1000,
        padding: 8,
        margin: 25
    });

    useEffect(() => {
        
        return ;
    }, [])


    function countStartingCounter () {
        return (tasks.filter((t) => t.currentTimerStarting === "start")).length;
    }
    
    function reorder (list, startIndex, endIndex)  { 
        var result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    function onDragEnd(result) {   
        if (!result.destination) {

            return;
        }
        const items = reorder(
            tasks,
            result.source.index,
            result.destination.index
        );
        dispatch(updateTask(items));
        countStartingCounter();
    }

    return (   
      <DragDropContext onDragEnd={onDragEnd}>
            { (nbCounter >= 4) && (
                <span id="alertNbCounter">4 compteurs max!!!</span>
            )}
            <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <div
                        onMouseOver={() => setGlobalState({...globalState, maxTasksNb: (tasks.length)}) }
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)} >
                        {tasks.map((item, index) => (
                        // {uniqueKey(tasks).map((item, index) => (
                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                {(provided, snapshot) => (
                                    <div
                                        className="theTask"
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )} >
                                        <Task 
                                            key={index} 
                                            item={item} 
                                            nbCounter={nbCounter} 
                                            setNbCounter={setNbCounter}
                                            taskInCounter={taskInCounter.current} />
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}
