import React, {useContext, useState, useEffect, useRef} from "react";
import QuizTypeChoice from "./QuizTypeChoice";
import { UserContext } from "../context/userContext";
import SheetHelpIndicator from "./SheetHelpIndicator";
import DispTextForm from "./ArticleDependencies/DispTextForm";
import DispTextQuiz from "./ArticleDependencies/DispTextQuiz";
import DispTextareaForm from "./ArticleDependencies/DispTextareaForm";
import DispTextareaQuiz from "./ArticleDependencies/DispTextareaQuiz";
import DispCheckForm from "./ArticleDependencies/DispCheckForm";
import DispCheckQuiz from "./ArticleDependencies/DispCheckQuiz";
import DispRadioForm from "./ArticleDependencies/DispRadioForm";
import DispRadioQuiz from "./ArticleDependencies/DispRadioQuiz";
import sendQuizToApi from "./articleRequest/sendQuizToApi"
import readOneSheet from "./articleRequest/readOneSheet";
import modifyStateSheet from "./articleRequest/modifyStateSheet";
import readExistingSheets from "./articleRequest/readExistingSheets";
import sendRespToApi from "./articleRequest/sendRespToApi";
import controlText from "./articleFunctions/controlText";
import buildQuizState from "./articleFunctions/buildQuizState";
import "./articles.css";

function Article({globalState, setGlobalState}) {

    
    const initialQuizState = {
        title: "",
        date: 0,
        quiz: {
            type: "", quiz: "",
            checkbox_1: "", checkbox_2: "", checkbox_3: "", checkbox_4: "", checkbox_5: "",
            checkbox_6: "", checkbox_7: "", checkbox_8: "", checkbox_9: "", checkbox_10: "",
            radio_1: "", radio_2: "", radio_3: "", radio_4: "", radio_5: ""
        }
    }
    const { currentUser } = useContext(UserContext);
    const [errorMessage, setErrorMessage] = useState(null);
    const [deployBuildForm, setDeployBuildForm] = useState(false);
    const [articleManagMode, setArticleManagMode] = useState(false);
    const [quizMode, setQuizMode] = useState("read");
    const [sheetTypeList, setSheetTypeList] = useState([]);
    const sheetQuizList = useRef([]);
    const [sheetNameList, setSheetNameList] = useState(null);
    const [quizType, setQuizType] = useState(null);
    const [quizContent, setQuizContent] = useState(initialQuizState);
    const [respContent, setRespContent] = useState(null);
    const [quizGlobal, setQuizGlobal] = useState(null);
    const [respUid, setRespUid] = useState("noUid");
    const [sheetState, setSheetState] = useState(null);
    const [closeSheetConfirm, setCloseSheetConfirm] = useState(false);
    const [infoColor, setInfoColor] = useState(globalState.theme.themes.articleCl[globalState.themeNb]);
    
    useEffect (() => {
        
        sendQuizToApi ( currentUser, 
                        setErrorMessage, 
                        articleManagMode, 
                        quizType, 
                        quizContent, 
                        sheetTypeList, 
                        setSheetTypeList, 
                        "readSheets" );

        sheetTypeList.map((sheet) => {
            if (!sheetQuizList.current.includes(sheet.title)) {
                    sheetQuizList.current.push(sheet.title);
            }
        })

        return ;
    }, [quizGlobal, sheetNameList, respContent, sheetState, infoColor])

    
    
    function initQuizForm () {
        setQuizContent({
            ...quizContent,
            title: "", 
            quiz: { type: "", quiz: "" }
        })
        setQuizGlobal(null);
    }
    function saveQuiz() {
        buildQuizState(quizType, quizContent.quiz.type, setQuizContent);
        sendQuizToApi(  currentUser, 
                        setErrorMessage, 
                        articleManagMode, 
                        quizType, 
                        quizContent, 
                        sheetTypeList, 
                        setSheetTypeList, 
                        "createSheet" );
        setQuizType(null); 
        initQuizForm();
        setTimeout(() => readOneSheet(  currentUser, 
                                        quizContent.title, 
                                        quizGlobal, 
                                        setQuizGlobal, 
                                        setRespContent, 
                                        setSheetState, 
                                        quizContent.title ), 1000 );
        setQuizContent({ 
            ...quizContent, 
            title: document.getElementById("sheetSelection").value.substring(0, document.getElementById("sheetSelection").value.indexOf(",")) });
    }
    function dispDate(timestamp) {
        var monthTab = {0: "janvier", 1: "février", 2: "mars", 3:"avril", 4: "mai", 5: "juin", 6: "juillet", 7: "aout", 8: "septembre", 9: "octobre", 10: "novembre", 11: "decembre"};
        var date = new Date(parseInt(timestamp)*1000);
        return date.getDate() + " " + monthTab[parseInt(date.getMonth())] + " " + date.getFullYear();
    }
    
    return (
        <>
            <div 
                className="buildSheetContainer noselect"
                style={{ 
                    "height": (deployBuildForm || !articleManagMode || currentUser.role === "ROLE_COLLABO") ? "auto" : "15px",
                    "--articleBg": globalState.theme.themes.articleBg[globalState.themeNb],
                    "--articleCl": globalState.theme.themes.articleCl[globalState.themeNb]
                }}>
                { (articleManagMode && (currentUser.role === "ROLE_MANAGER" || currentUser.role === "ROLE_USER")) && (
                    <>
                        <div className="buildSheetTitle">
                            <span 
                                className="material-symbols-outlined deployBut"
                                onClick={() => {setDeployBuildForm(!deployBuildForm)}}
                                style={{"transform": deployBuildForm ? "translateY(5px) rotate(180deg)" : "translateY(5px)"}}>
                                shift
                            </span>
                            Construire / Modifier votre feuille de suivi.
                            { ((currentUser.role === "ROLE_MANAGER" || currentUser.role === "ROLE_USER") && articleManagMode) && (
                                <span 
                                    style={{"color": articleManagMode ? "#2C9117" : "red"}}
                                    className="modManagText">mode manager: 
                                        <span 
                                            className="material-symbols-outlined modeManagerSelector"
                                            style={{"color": "#2C9117"}}
                                            onClick={() => {
                                                setArticleManagMode(!articleManagMode);
                                                setQuizContent(initialQuizState);
                                                setQuizMode("read");
                                                setQuizType(null);
                                                setRespContent(null);
                                                setQuizGlobal(null);
                                                }}>
                                                toggle_on
                                        </span>
                                </span>
                            )}
                        </div>
                        {/* <SheetHelpIndicator /> */}
                        <div className="buildSheetMenu">
                            <div>
                                <select id="sheetSelection"
                                    onChange={(e) => {
                                        setQuizContent({
                                            ...quizContent, 
                                            title: e.target.value.substring(0, e.target.value.indexOf(",")), 
                                            sheetUid: e.target.value.substring(e.target.value.indexOf(",")+1)
                                        });
                                        readOneSheet(   currentUser, 
                                                        e.target.value.substring(0, e.target.value.indexOf(",")), 
                                                        quizGlobal, 
                                                        setQuizGlobal, 
                                                        setRespContent, 
                                                        setSheetState, 
                                                        null);
                                        }}>
                                    <option key="sheet1" value="Pas de fiche">Choisir une feuille</option>
                                    {sheetTypeList.map((sheet, index) => 
                                        <option key={"sheetName" + index} value={[sheet.title, sheet.uid]}>
                                            {sheet.title}
                                        </option>
                                    )}
                                </select>
                            </div>
                            <div> 
                                { (quizContent.title === "" || quizContent.title === "Pas de fiche") && (
                                    <button 
                                        className="validationButton"
                                        onClick={() => {setQuizMode("createSheet")}}>
                                            Nouvelle feuille
                                    </button>
                                )}
                                { (quizContent.title && quizContent.title !== "" && quizContent.title !== "Pas de fiche") && (
                                    <>
                                        <button 
                                            className="validationButton"
                                            onClick={() => setQuizMode("createQuiz")}>
                                                Modifier: {quizContent.title.slice(0, 10) + "..."}
                                        </button>
                                        <button 
                                            className="deletionButton"
                                            onClick={() => {sendQuizToApi(currentUser, 
                                                            setErrorMessage, 
                                                            articleManagMode, 
                                                            quizType, 
                                                            quizContent, 
                                                            sheetTypeList, 
                                                            setSheetTypeList, 
                                                            "deleteSheet", 
                                                            quizContent.title );
                                                setQuizContent(initialQuizState);
                                                setQuizType(null);
                                                setQuizMode("read");
                                                setRespContent(null);
                                                setQuizGlobal(null);
                                            }}>
                                                Supprimer cette fiche
                                        </button>
                                    </>
                                    
                                )}
                            </div>  
                        </div>
                        { (quizMode === "createSheet" && document.getElementById("sheetSelection").selectedIndex === 0) && (
                                    <div>
                                        <label forhtml="sheetTitle" style={{"fontWeight": "500", "marginRight": "15px"}}>
                                            Titre de votre fiche: 
                                        </label>
                                        <span 
                                            className="lengthIndicator"
                                            style={{"color": quizContent.title && quizContent.title.length <= 2 ? "red" : "green"}}>
                                            {quizContent.title.length} / 60
                                        </span>
                                        <input type="text" name="sheetTitle" 
                                            style={{"background": quizContent.title && quizContent.title.length <= 2 ? "red" : "lightgreen"}}
                                            onChange={(e) => setQuizContent({...quizContent, title: controlText(e.target.value, 60)})} 
                                            value={quizContent.title}/>
                                        { !sheetTypeList.includes(quizContent.title) && (
                                            <button 
                                                className="validationButton"
                                                onClick={() => sendQuizToApi(currentUser, setErrorMessage, articleManagMode, quizType, quizContent, sheetTypeList, setSheetTypeList, "createSheet")}>
                                                    Enregistrer
                                            </button>
                                        )}
                                        { errorMessage && (
                                            <p style={{"color": "red"}}>{errorMessage}</p>
                                        )}
                                    </div>
                        )}
                        { (quizMode === "createQuiz" && sheetQuizList.current.includes(quizContent.title)) && (
                                    <QuizTypeChoice 
                                        quizType={quizType}
                                        setQuizType={setQuizType}
                                        setQuizMode={setQuizMode}
                                        initQuizForm={initQuizForm} />
                        )}
                        { (document.getElementById("sheetSelection") && 
                            document.getElementById("sheetSelection").selectedIndex !== 0 && 
                            quizType === "text") && (
                                <DispTextForm
                                    quizContent={quizContent}
                                    setQuizContent={setQuizContent}
                                    buildQuizState={buildQuizState}/>
                        )}
                        { (document.getElementById("sheetSelection") && 
                            document.getElementById("sheetSelection").selectedIndex !== 0 && 
                            quizType === "textarea") && (
                                <DispTextareaForm
                                    quizContent={quizContent}
                                    setQuizContent={setQuizContent}
                                    buildQuizState={buildQuizState}/>
                        )}
                        { (document.getElementById("sheetSelection") && 
                            document.getElementById("sheetSelection").selectedIndex !== 0 && 
                            quizType === "checkbox") && (
                                <DispCheckForm
                                    quizContent={quizContent}
                                    setQuizContent={setQuizContent}
                                    buildQuizState={buildQuizState} />
                        )}
                        { (document.getElementById("sheetSelection") && 
                            document.getElementById("sheetSelection").selectedIndex !== 0 && 
                            quizType === "radio") && (
                                <DispRadioForm
                                    quizContent={quizContent}
                                    setQuizContent={setQuizContent}
                                    buildQuizState={buildQuizState} />
                        )}
                        { quizType !== null && (
                            <div className="">
                                <button 
                                    className="deletionButton"
                                    onClick={() => initQuizForm()}>
                                        Supprimer
                                </button>
                                <button 
                                    className="validationButton"
                                    onClick={() => saveQuiz()}>
                                        Valider
                                </button>
                            </div>
                        )}
                    </>
                )}
                { (!articleManagMode || currentUser.role === "ROLE_COLLABO") && (
                    <>
                        <div className="typeSheetSelection">
                            <span>Choisissez un type de fiche: </span>
                            <select id="sheetSelection"
                                onChange={(e) => {
                                    setRespContent(null);
                                    setQuizGlobal(null);
                                    if (document.getElementById("sheetContentSelection")) {
                                        document.getElementById("sheetContentSelection").selectedIndex = 0;
                                    }
                                    setQuizContent({
                                        ...quizContent, 
                                        title: e.target.value.substring(0, e.target.value.indexOf(",")), 
                                        sheetUid: e.target.value.substring(e.target.value.indexOf(",")+1)
                                    });
                                    readExistingSheets(
                                        currentUser, 
                                        {title: e.target.value.substring(0, e.target.value.indexOf(",")), 
                                        sheetUid: e.target.value.substring(e.target.value.indexOf(",")+1)},
                                        setSheetNameList, 
                                        "readExistingSheets");
                                    }}>
                                <option key="sheet999" value="Pas de type">Choisir un type de feuille</option>
                                {sheetTypeList.map((sheet, index) => 
                                    <option key={"sheetName" + index} value={[sheet.title, sheet.uid]}>
                                        {sheet.title}
                                    </option> 
                                )}
                            </select>
                            { ((currentUser.role === "ROLE_MANAGER" || currentUser.role === "ROLE_USER") && !articleManagMode) && (
                                <span 
                                    style={{"color": articleManagMode ? "#2C9117" : "red"}}
                                    className="modManagText">mode manager: 
                                        <span 
                                            className="material-symbols-outlined modeManagerSelector"
                                            style={{"color": "red"}}
                                            onClick={() => {
                                                setArticleManagMode(!articleManagMode);
                                                setQuizContent(initialQuizState);
                                                setQuizMode("read");
                                                setQuizType(null);
                                                setRespContent(null);
                                                setQuizGlobal(null);
                                                setSheetNameList(null)
                                                }}>
                                                toggle_off
                                        </span>
                                </span>
                            )}
                        </div>
                        { sheetNameList && (
                            <>
                                <div className="typeSheetSelection">
                                    <span style={{"margin": "4px 0"}}>Editez une fiche: </span>
                                    <select id="sheetContentSelection"
                                        onChange={(e) => {
                                            setRespUid(e.target.value.substring(e.target.value.indexOf("$")+1));
                                            readOneSheet(   currentUser, 
                                                            quizContent.title, 
                                                            quizGlobal, 
                                                            setQuizGlobal,
                                                            setRespContent, 
                                                            setSheetState, 
                                                            e.target.value.substring(0, e.target.value.indexOf("$")) );
                                            }}>
                                        <option key="sheet999" value="Pas de fiche">Choisir une feuille</option>
                                        {sheetNameList.map((sheet, index) => 
                                            <option key={"sheetName" + index} value={sheet} >
                                                {
                                                sheet.substring(0, sheet.indexOf("%")) 
                                                + " - " 
                                                + dispDate(sheet.substring(sheet.indexOf("%")+1, sheet.indexOf("$")))
                                                }
                                            </option> 
                                        )}
                                    </select>
                                    <span style={{"margin": "6px 0"}}> ou <span 
                                                className="validationButton" 
                                                style={{"fontSize": "var(--fontSizeText)"}}
                                                onClick={
                                                    () => {readOneSheet(currentUser, 
                                                                        quizContent.title, 
                                                                        quizGlobal, 
                                                                        setQuizGlobal, 
                                                                        setRespContent, 
                                                                        setSheetState, 
                                                                        null);
                                                        if (document.getElementById("typeSheetSelection")) {
                                                            document.getElementById("typeSheetSelection").selectedIndex = 0;
                                                        }
                                                        document.getElementById("sheetContentSelection").selectedIndex = 0;
                                                        setSheetState({resp_state: "en cours"});
                                                        setRespUid("noUid");
                                                    }
                                                }>
                                                    Créer une fiche
                                            </span>
                                    </span>
                                </div>
                                <div className="sheetClosingActionContainer">
                                    { ((quizGlobal !== null && !articleManagMode) && 
                                        sheetState.resp_state === "en cours" && 
                                        document.getElementById("sheetContentSelection") &&
                                        document.getElementById("sheetContentSelection").selectedIndex !== 0) && (
                                        <button 
                                            className="sheetClosingAction"
                                            onClick={() => {
                                                if (closeSheetConfirm) {
                                                    setCloseSheetConfirm(false);
                                                }
                                                !closeSheetConfirm ? 
                                                setCloseSheetConfirm(true) :
                                                modifyStateSheet(   currentUser, 
                                                                    sheetState.resp_uid,  
                                                                    setSheetState,
                                                                    "closeSheet" );
                                                if (closeSheetConfirm) {
                                                    setSheetState({...sheetState, resp_state: "cloturé"})
                                                }
                                                    } } >
                                                { !closeSheetConfirm ? "Cloturer cette fiche" : "Confirmez" }
                                        </button>
                                    )}
                                    { ((quizGlobal !== null && !articleManagMode) && 
                                        sheetState.resp_state === "cloturé" && 
                                        (currentUser.role === "ROLE_MANAGER" || currentUser.role === "ROLE_USER") && 
                                        document.getElementById("sheetContentSelection") && 
                                        document.getElementById("sheetContentSelection").selectedIndex !== 0) && (
                                        <button 
                                            className="sheetClosingAction"
                                            onClick={() => {
                                                            modifyStateSheet(currentUser, 
                                                                            sheetState.resp_uid, 
                                                                            setSheetState,
                                                                            "refreshSheet" );
                                                            setCloseSheetConfirm(false);
                                                            if (!closeSheetConfirm) {
                                                                setSheetState({...sheetState, resp_state: "en cours"})
                                                            }
                                                            } } >
                                                Restaurer cette fiche
                                        </button>
                                    )}
                                </div>
                            </>
                        )}
                    </>
                )}
                { (!articleManagMode && sheetState && sheetState.updated_user && quizGlobal !== null && respContent && sheetState && respUid !== "noUid") && (
                    <span className="dispSheetState">
                        {sheetState.updated_user && sheetState.updated_user.slice(0, sheetState.updated_user.indexOf("@")) + " "} 
                        a <span className="dispSheetState" style={{
                                "color": sheetState.resp_state === "en cours" ? "#2C9117" : "orange", 
                                "fontWeight": "600"
                                }}>
                            {sheetState.resp_state === "en cours" ? "mis à jour" : "cloturé"} {" "}
                        </span> 
                        cette fiche le {dispDate(sheetState.updated_at)}. <br/> 
                        Statut actuel: 
                        <span style={{
                                "color": sheetState.resp_state === "en cours" ? "#2C9117" : "orange", 
                                "fontWeight": "600"
                                }}>
                            {" " + sheetState.resp_state}
                        </span>
                    </span>
                )}
            </div>
            <div 
                className="quizDispContainer"
                style={{ 
                    //"height": deployBuildForm ? "auto" : "5px",
                    "--articleBg": globalState.theme.themes.articleBg[globalState.themeNb],
                    "--articleCl": globalState.theme.themes.articleCl[globalState.themeNb] }}>
                { (quizGlobal !== null && respContent) && 
                    quizGlobal.map((quiz, index) => (
                        <div key={index + "iteration"}>
                            { (quiz[0].quiz_type === "text") && (
                                <DispTextQuiz
                                    currentUser={currentUser} 
                                    setErrorMessage={setErrorMessage}
                                    quiz={quiz} 
                                    index={index}
                                    sendQuizToApi={sendQuizToApi} 
                                    deployBuildForm={deployBuildForm}
                                    articleManagMode={articleManagMode}
                                    quizType={quizType}
                                    quizContent={quizContent} 
                                    respContent={respContent}
                                    setRespContent={setRespContent}
                                    sheetTypeList={sheetTypeList}
                                    setSheetTypeList={setSheetTypeList}
                                    infoColor={infoColor}
                                    setInfoColor={setInfoColor}
                                    sheetState={sheetState.resp_state} />
                            )}
                            { (quiz[0].quiz_type === "textarea") && (
                                <DispTextareaQuiz
                                    currentUser={currentUser} 
                                    setErrorMessage={setErrorMessage}
                                    quiz={quiz} 
                                    index={index}
                                    sendQuizToApi={sendQuizToApi} 
                                    deployBuildForm={deployBuildForm}
                                    articleManagMode={articleManagMode}
                                    quizType={quizType}
                                    quizContent={quizContent} 
                                    respContent={respContent}
                                    setRespContent={setRespContent}
                                    sheetTypeList={sheetTypeList}
                                    setSheetTypeList={setSheetTypeList}
                                    infoColor={infoColor}
                                    setInfoColor={setInfoColor}
                                    sheetState={sheetState.resp_state} />
                            )}
                            { (quiz[0].quiz_type === "checkbox") && (
                                <DispCheckQuiz
                                    currentUser={currentUser} 
                                    setErrorMessage={setErrorMessage}
                                    quiz={quiz} 
                                    index={index}
                                    sendQuizToApi={sendQuizToApi} 
                                    deployBuildForm={deployBuildForm}
                                    articleManagMode={articleManagMode}
                                    quizType={quizType}
                                    quizContent={quizContent} 
                                    respContent={respContent}
                                    setRespContent={setRespContent}
                                    sheetTypeList={sheetTypeList}
                                    setSheetTypeList={setSheetTypeList}
                                    infoColor={infoColor}
                                    setInfoColor={setInfoColor}
                                    sheetState={sheetState.resp_state} />
                            )}
                            { (quiz[0].quiz_type === "radio") && (
                                <DispRadioQuiz
                                    currentUser={currentUser} 
                                    setErrorMessage={setErrorMessage}
                                    quiz={quiz} 
                                    index={index}
                                    sendQuizToApi={sendQuizToApi} 
                                    deployBuildForm={deployBuildForm}
                                    articleManagMode={articleManagMode}
                                    quizType={quizType}
                                    quizContent={quizContent} 
                                    respContent={respContent}
                                    setRespContent={setRespContent}
                                    sheetTypeList={sheetTypeList}
                                    setSheetTypeList={setSheetTypeList}
                                    infoColor={infoColor}
                                    setInfoColor={setInfoColor}
                                    sheetState={sheetState.resp_state} />
                            )}
                        </div>
                    ))
                }
                { !articleManagMode && infoColor === "red" && (
                    <button 
                        className="responseValidation" 
                        onClick={() => {
                            sendRespToApi(currentUser, quizContent, respContent, respUid, quizContent.sheetUid, setSheetNameList, "saveResp");
                            setInfoColor(globalState.theme.themes.articleCl[globalState.themeNb]);
                            document.getElementById("sheetContentSelection").selectedIndex = 0;
                            setRespContent(null);
                            setQuizGlobal(null);
                        }}>
                        <span 
                            className="material-symbols-outlined"
                            style={{"color": infoColor}}>
                                save
                        </span>
                    </button>
                )}
            </div>
        </>
    )
}

export default Article;