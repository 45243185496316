import React from "react";

export default function MsTool({globalState, content, toolChosen, setToolChosen, changeTool, rubriq}) {
  return (
    <div 
        className="msToolcontainer" 
        style={{"--msToolBg1": globalState.theme.themes.msToolBg1[globalState.themeNb],
                "--msToolBg2": globalState.theme.themes.msToolBg2[globalState.themeNb]
                }}
        onClick={() => changeTool(rubriq)}>
      <h1 className="msToolTitle">{content.title}</h1>
      <p className="msToolText">{content.def}</p>
      <img className="msToolImg" src={content.image} alt="tool visual" />
    </div>
  )
}
