import axios from "axios";

async function getArticles (setPaginatorAutoriz, 
                            currentUser, 
                            stockState, 
                            setStockState, 
                            setUpdateDate,
                            quantFilter = null) {
    setStockState({
        ...stockState,
        message: "Loading ..."
    });
    setPaginatorAutoriz(false);
    var pagination = {page: stockState.page, perPage: stockState.perPage};
    await axios({
        method: 'post', 
        url: process.env.REACT_APP_SERVER_NAME,
        params: {st: 'read'},
        data: {
            user_email: currentUser.email,
            user_teamUid: currentUser.teamUid,
            user_role: currentUser.role,
            action: "readArticles",
            paginator: {
                page: stockState.page,
                perPage: stockState.perPage
            },
            filter: {
                column: stockState.filter.column,
                value: stockState.filter.value,
                minQuant: stockState.filter.minQuant
            }
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentUser.token
        }
    }).then((resp) => {
        if (resp.status === 200) { 
            setPaginatorAutoriz(true);
            setStockState({
                ...stockState,
                filter: stockState.filter,
                totalParts: resp.data.totalParts,
                stock: resp.data.stockAsked,
                quantAlert: resp.data.quantAlert,
                refValues: resp.data.refValues,
                desValues: resp.data.desValues,
                catValues: resp.data.catValues,
                proValues: resp.data.proValues,
                paginator: pagination,
                old_message: stockState.message,
                message: resp.data.message
            });
            var d = new Date();
            var hour = (parseInt(d.getHours()) > 9) ? d.getHours() : "0" + d.getHours()
            var minu = (parseInt(d.getMinutes()) > 9) ? d.getMinutes() : "0" + d.getMinutes()
            var seco = (parseInt(d.getSeconds()) > 9) ? d.getSeconds() : "0" + d.getSeconds()
            setUpdateDate(hour + ' : ' + minu + ' : ' + seco);
        } 
        if (resp.status !== 200) {
            setStockState({
                ...stockState,
                message: "Consultation impossible pour l'instant."
            });
        }
    })
}

export default getArticles;