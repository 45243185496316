import axios from "axios";

async function changeAssemblyImage({
    currentUser,
    file,
    assemblyDetail,
    setAssemblyDetail
}) {
    await axios({
            method: 'post', 
            url: process.env.REACT_APP_SERVER_NAME,
            params: {ass: 'img'},
            data: {
                user_email: currentUser.email,
                team_uid: currentUser.teamUid,
                assemblyId: assemblyDetail.id,
                fileUpload: file[0]
            },
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'multipart/form-data',
                'Authorization': 'Bearer ' + currentUser.token
            }
        }).then((resp) => {
            if (resp.status === 200) {
                setAssemblyDetail({
                    ...assemblyDetail, 
                    assembly_image: resp.data.res.filename,
                    assImgLoading: resp.data.res.message
                })
            }
        })
}

export default changeAssemblyImage;