import axios from "axios";
import validTypes from "./validTypes";

function loadFile(e, currentUser, task, setDocs, taskState, setTaskState ) {

    const validMimeTypes = validTypes();

    e.preventDefault();
    if (validMimeTypes.includes(e.target.files[0].type) && e.target.files[0].size < 10000000) {
        (async() => {     
            const sendFile  = await axios({
                method: 'post', 
                url: process.env.REACT_APP_SERVER_NAME,
                params: {fu: 'ad'},
                data: {
                    user_email: currentUser.email,
                    user_teamUid: currentUser.teamUid,
                    user_role: currentUser.role,
                    user_task: task.id,
                    fileUpload: e.target.files[0]
                },
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Accept': 'multipart/form-data',
                    'Authorization': 'Bearer ' + currentUser.token
                }
            }).then((resp) => {
                if (resp.status === 200) {
                    setDocs(resp.data.docsList);
                    setTaskState({...taskState, nbFile: resp.data.docsList.length});
                }
            })
        })()
    }
}
export default loadFile;