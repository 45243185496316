import axios from "axios";

function changeLoop(state, currentUser, task) {

    if (state === true) {
        (async() => {     
            const sendTaskLoop  = await axios({
                method: 'post', 
                url: process.env.REACT_APP_SERVER_NAME,
                params: {re: 'ad'},
                data: {
                    user_email: currentUser.email,
                    user_teamUid: currentUser.teamUid,
                    user_role: currentUser.role,
                    task: {
                        taskId: task.id,
                        stateCounter: false,
                        taskTitle: task.title,
                        taskPriority: task.priority,
                        taskContent: task.content,
                        taskProgress: task.progress,
                        rytme: document.getElementById("rytme").value,
                        occurences: document.getElementById("occurNb").value
                    }
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + currentUser.token
                }
            })
        })()
    }
    if (state === false) {
        (async() => {     
            const delTaskLoop  = await axios({
                method: 'post', 
                url: process.env.REACT_APP_SERVER_NAME,
                params: {re: 'su'},
                data: {
                    user_email: currentUser.email,
                    user_teamUid: currentUser.teamUid,
                    user_role: currentUser.role,
                    task: {
                        taskId: task.id,
                    }
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + currentUser.token
                }
            })
        })()
    }
    
  return ;
}
export default changeLoop;