import axios from "axios";

async function noveltyValidation (currentUser) {

    const validNovelty = await axios({
        method: 'post', 
        url: process.env.REACT_APP_SERVER_NAME,
        params: {nv: 'nvvl'},
        data: {
            user_email: currentUser.email,
            user_teamUid: currentUser.teamUid,
            user_role: currentUser.role,
            action: "validNovelty",
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentUser.token
        }
    })
}

export default noveltyValidation;