import React, {useContext, useRef, useState, useEffect} from "react";
import { UserContext } from "../context/userContext";
import controlText from './controlText';
import addArticle from "./stockRequest/addArticle";
import subArticle from "./stockRequest/subArticle";
// import updateCategories from "./stockRequest/updateCategories";

export default function AddStockFunction({stockState, setStockState, id = null, stopRefreshing}) {

    const { currentUser } = useContext(UserContext);
    const titre = useRef("Ajouter un article!");
    const [partValue, setPartValue] = useState({
        titre: "",
        id: null,
        ref: "",
        des: "",
        cat: "",
        cats: [],
        quant: 0,
        quantMini: 0,
        delay: 0,
        prov: "",
        provRef: "",
        price: ""
    });
    const [confirmSubArticle, setConfirmSubArticle] = useState(false);

    useEffect (() => {
        if (id !== null && partValue.ref === "") {
            titre.current = "Modifier cet article!"
            stockState.stock.map((t) => {
                if (t.id === id) {
                    setPartValue({
                        ...partValue, 
                        id: t.id,
                        ref: t.ref, 
                        des: t.designator, 
                        cat: t.category,
                        cats: t.categories,
                        quant: t.quantity,
                        quantMini: t.min_quantity,
                        delay: t.delay_appro,
                        prov: t.provider,
                        provRef: t.provider_ref,
                        price: t.price
                    });
                }
            })
        }
    })

    function changeArticleValue (e, name) {
        if (name === "ref") {
            setPartValue({...partValue, ref: controlText(e.target.value)});
            document.getElementById("refLen").innerText = (e.target.value.length).toString() + " / 12";
        }
        if (name === "des") {
            setPartValue({...partValue, des: controlText(e.target.value)});
            document.getElementById("desLen").innerText = (e.target.value.length).toString() + " / 40";
        }
        if (name === "cat") {
            setPartValue({...partValue, cat: controlText(e.target.value)});
            document.getElementById("catLen").innerText = (e.target.value.length).toString() + " / 40";
        }
        if (name === "quant") {
            if (isNaN(e.target.value) || e.target.value < 0 || e.target.value === "") {
                e.target.value = 0;
            }
            setPartValue({...partValue, quant: controlText(e.target.value)});
            document.getElementById("quantLen").innerText = (e.target.value.length).toString() + " / 5";
        }
        if (name === "quantMini") {
            if (isNaN(e.target.value) || e.target.value < 0 || e.target.value === "") {
                e.target.value = 0;
            }
            setPartValue({...partValue, quantMini: controlText(e.target.value)});
            document.getElementById("quantMiniLen").innerText = (e.target.value.length).toString() + " / 5";
        }
        if (name === "delay") {
            if (isNaN(e.target.value) || e.target.value < 0 || e.target.value === "") {
                e.target.value = 0;
            }
            setPartValue({...partValue, delay: controlText(e.target.value)});
            document.getElementById("delayLen").innerText = (e.target.value.length).toString() + " / 5";
        }
        if (name === "provider") {
            setPartValue({...partValue, prov: controlText(e.target.value)});
            document.getElementById("provLen").innerText = (e.target.value.length).toString() + " / 60";
        }
        if (name === "providerRef") {
            setPartValue({...partValue, provRef: controlText(e.target.value)});
            document.getElementById("provRefLen").innerText = (e.target.value.length).toString() + " / 40";
        }
        if (name === "price") {
            var floatVal = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'];
            if (e.target.value !== null && floatVal.includes(e.target.value.slice(-1))) {
                setPartValue({...partValue, price: controlText(e.target.value)});
                document.getElementById("priceLen").innerText = (e.target.value.length).toString() + " / 10";
            }
            
        }
    }
    function closeAddStockWindow () {
        setStockState({...stockState, addMode: false});
    }
    function validArticleForm () {
        addArticle (currentUser, id, partValue, stockState, setStockState)
    }
    function deleteOneCategoy (index) {
        var part = partValue.cats.filter((n) => { return n !== partValue.cats[index] });
        setPartValue({...partValue, cats: part});
        part = null;
    }
    function changeArticleCategories(e) {
        var part = partValue.cats
        if (!part.includes(e) && e.length > 0) {
            part.unshift(e);
            setPartValue({...partValue, cats: part});
        }
        part = null;
    }

    return (
        <div className="addModaleWindowContainer" onLoad={stopRefreshing}>
            <div className="addModaleWindow">
                <span 
                    className="material-symbols-outlined closeButton"
                    onClick={() => {
                        setConfirmSubArticle(false)
                        closeAddStockWindow();
                        }}>
                        cancel
                </span>
                <h3 className="addModaleStockH3">{titre.current}</h3>
                <div className="addModaleinput" key="ref">
                    <label htmlFor="refStock">Référence</label>
                    <span className="lenIndicator" id="refLen"></span>
                    { (id !== null) && (
                        <p style={{"marginRight": "20px"}}>{partValue.ref}</p>
                    )}
                    { (id === null) &&(
                    <input type="text" name="refStock"
                        maxLength="12"
                        onChange={(e) => changeArticleValue(e, "ref")} 
                        value={partValue.ref}/>
                    )}
                </div>
                <div className="addModaleinput" key="des">
                    <label htmlFor="desStock">Désignation</label>
                    <span className="lenIndicator" id="desLen"></span>
                    <input type="text" name="desStock"
                        maxLength="40"
                        onChange={(e) => changeArticleValue(e, "des")} 
                        value={partValue.des}/>
                </div>
                <div className="addModaleinput categoriesSelectZone noselect" key="cats">
                    <label htmlFor="catStock">Catégories</label>
                    <span className="lenIndicator" id="catLen"></span>
                    <span className="categoriesEdition">
                        <ul className="categoriesShow">
                            {partValue.cats && (
                                partValue.cats.map((oneCat, index) => (
                                    <li key={"cats" + index}>
                                        <span className="delCatBut" onClick={() => deleteOneCategoy(index)}>X</span>
                                        {oneCat}
                                    </li>
                                ))
                            )}
                        </ul>
                        <span className="catiesInputs">
                            <select type="select" id="catsFilter" 
                                className="selectExistingCategories" 
                                onChange={(e) => changeArticleCategories(e.target.value, "cats")}>
                                <option value="choix">Pas de choix</option>
                                {stockState.catValues && (stockState.catValues.map((r, index) => (
                                    <option key={"cat" + index} value={stockState.catValues[index]}>{stockState.catValues[index]}</option>
                                )))}
                            </select>
                            <span className="catiesCreation">
                                <label style={{"fontSize": "10px", "marginRight": "60px"}}>Nouvelle catégorie</label>
                                <span style={{"marginRight": "40px"}}>
                                    <input type="text" name="catStock" id="newCaties"
                                        maxLength="40" size="16"
                                        onChange={(e) => changeArticleValue(e, "cat")} />
                                    <button 
                                        className="addCatiesBut" 
                                        onClick={(e) => changeArticleCategories(document.getElementById("newCaties").value, "cats")}>
                                        +
                                    </button>
                                </span>
                            </span>
                        </span>
                    </span>
                </div>
                <div className="addModaleinput" key="quant">
                    <label htmlFor="quantStock">Quantité</label>
                    <span className="lenIndicator" id="quantLen"></span>
                    <input type="text" name="quantStock"
                        maxLength="5" size="5"
                        onChange={(e) => changeArticleValue(e, "quant")} 
                        value={partValue.quant}/>
                </div>
                <div className="addModaleinput" key="quantMini">
                    <label htmlFor="quantMiniStock">Quantité Minimum</label>
                    <span className="lenIndicator" id="quantMiniLen"></span>
                    <input type="text" name="quantMiniStock"
                        maxLength="3" size="3"
                        onChange={(e) => changeArticleValue(e, "quantMini")} 
                        value={partValue.quantMini}/>
                </div>
                <div className="addModaleinput" key="delay">
                    <label htmlFor="delayStock">Délai appro en jours</label>
                    <span className="lenIndicator" id="delayLen"></span>
                    <input type="text" name="delayStock"
                        maxLength="3" size="3"
                        onChange={(e) => changeArticleValue(e, "delay")} 
                        value={partValue.delay}/>
                </div>
                <div className="addModaleinput providerSelectZone" key="prov">
                    <label htmlFor="provStock">Fournisseur</label>
                    <span className="lenIndicator" id="provLen"></span>
                    <span className="categoriesEdition">
                        <input type="text" name="provStock" style={{"margin": "8px 0"}}
                            maxLength="60"
                            onChange={(e) => changeArticleValue(e, "provider")} 
                            value={partValue.prov}/>
                        <select type="select" id="catFilter" 
                            className="selectExistingProvider" 
                            onChange={(e) => changeArticleValue(e, "provider")}>
                            <option value="choix">Pas de choix</option>
                            {stockState.catValues && (stockState.proValues.map((r, index) => (
                                <option key={"cat"+index} value={stockState.proValues[index]}>{stockState.proValues[index]}</option>
                            )))}
                        </select>
                    </span>
                </div>
                <div className="addModaleinput" key="provRef">
                    <label htmlFor="provRefStock">Ref fournisseur</label>
                    <span className="lenIndicator" id="provRefLen"></span>
                    <input type="text" name="provRefStock"
                        maxLength="60"
                        onChange={(e) => changeArticleValue(e, "providerRef")} 
                        value={partValue.provRef}/>
                </div>
                <div className="addModaleinput" key="price">
                    <label htmlFor="priceStock">Prix unitaire</label>
                    <span className="lenIndicator" id="priceLen"></span>
                    <input type="text" name="priceStock"
                        maxLength="60"
                        onChange={(e) => changeArticleValue(e, "price")} 
                        value={partValue.price}/>
                </div>
                <div  className="addModaleinput" style={{"textAlign": "center"}} key="articleVal">
                    { (id !== null && !confirmSubArticle ) &&(
                        <button 
                            className="subArticleValidationBut" 
                            onClick={() => setConfirmSubArticle(true)}>
                            Supprimer l'article
                        </button>
                    )}
                    { (id !== null && confirmSubArticle ) &&(
                        <button 
                            className="subArticleValidationBut" 
                            onClick={() => subArticle (currentUser, id, stockState, setStockState)}
                            style={{"transform": "scale(1.5)", "background": "red"}}>
                            CONFIRMEZ
                        </button>
                    )}
                    <button 
                        className="addArticleValidationBut" 
                        onClick={() => validArticleForm()}>
                        Valider
                    </button>
                </div>
            </div>
        </div>
    )
}
