import React, { useContext } from "react";
import CUDButton from "../TaskComponents/CUDButton";
import { useDispatch } from "react-redux";
import { UserContext } from '../../context/userContext';
import updateTask from "../TasksRequest/updateTask";
import removeTask from "../TasksRequest/removeTask";

export default function EditCUDButtons({task, 
                                        expand, 
                                        taskState, 
                                        setTaskState }) {

    const { currentUser } = useContext(UserContext);
    const dispatch = useDispatch(); 

    function editTask () {
        if (!taskState.editMode && taskState.deploy === "90px") {
            expand();
        }
        if (taskState.editMode && taskState.deploy === "700px") {
            setTaskState({  ...taskState, 
                            deploy: "90px", 
                            opacityBut: "none", 
                            modalLoopForm: false});
        }
        if (taskState.editMode) {
            updateTask(false, currentUser, task);
        }
        setTaskState({...taskState, editMode: !taskState.editMode});
    }

    return (
        <>
            <CUDButton 
                    editMode={!taskState.editMode}
                    taskId={task.id}
                    classes="actionBut editBut"
                    editTask={editTask}
                    removeTask={() => removeTask("no", currentUser, task, dispatch)}
                    opacityBut={taskState.opacityBut}
                    icon="edit_square"
                    text="Editer" />
            <CUDButton 
                    editMode={taskState.editMode}
                    taskId={task.id}
                    classes="actionBut validBut"
                    editTask={null}
                    removeTask={() => removeTask("no", currentUser, task, dispatch)}
                    opacityBut={taskState.opacityBut}
                    icon="close"
                    text="" />
            <CUDButton 
                    editMode={taskState.editMode}
                    taskId={task.id}
                    classes="actionBut deleteBut"
                    editTask={editTask}
                    removeTask={() => setTaskState({...taskState, modalDeletion: true})}
                    opacityBut={taskState.opacityBut}
                    icon="delete"
                    text="Effacer" />
            <br/>
            <CUDButton
                    editMode={taskState.editMode}
                    taskId={task.id}
                    classes="actionBut loopBut"
                    editTask={() => setTaskState({...taskState, modalLoopForm: !taskState.modalLoopForm})}
                    opacityBut={taskState.opacityBut}
                    icon="all_inclusive"
                    text={taskState.textLoopBut} />
        </>
    )
}
