import React from "react";

export default function LogoAndTitleDisplaying({globalState, toolChosen, setToolChosen}) {
    return (
        <div className="headerLeftMedia">
            <div>
                <img 
                    src="./images/managersysteme.png" 
                    onClick={() => setToolChosen("accueil")}
                    className="headerLogo" 
                    alt="logo" 
                    style={{"--headerBtColor": globalState.theme.themes.headerBtColor[globalState.themeNb]}}/>
            </div>
            <div className="h1HeaderStyle">
            { (toolChosen === "accueil") && (
                <h1>Manager système</h1>
            )}
            { (toolChosen === "account") && (
                <h1>Votre compte</h1>
            )}
            { (toolChosen === "task") && (
                <h1>Gestion des tâches</h1>
            )}
            { (toolChosen === "stock") && (
                <h1>Gestion du stock</h1>
            )}
            { (toolChosen === "article") && (
                <h1>Suivi des produits</h1>
            )}
            </div>
        </div>
    )
}
