import axios from "axios";

async function getCommand ( currentUser, 
                            currentId, 
                            commandState, 
                            stockState, 
                            setStockState) {
    await axios({
        method: 'post', 
        url: process.env.REACT_APP_SERVER_NAME,
        params: {cd: 'createCd'},
        data: {
            user_email: currentUser.email,
            user_teamUid: currentUser.teamUid,
            id: currentId,
            paginator: {
                page: stockState.page,
                perPage: stockState.perPage
            },
            type: commandState.type,
            customer: {
                customerName: commandState.customerName,
                customerAddressOne: commandState.customerAddressOne,
                customerAddressTwo: commandState.customerAddressTwo,
                customerZipTown: commandState.customerZipTown,
                customerSiret: commandState.customerSiret,
                customerTva: commandState.customerTva,
                customerPhone: commandState.customerPhone
                
            },
            provider: {
                providerName: commandState.providerName,
                providerAddressOne: commandState.providerAddressOne,
                providerAddressTwo: commandState.providerAddressTwo,
                providerZipTown: commandState.providerZipTown,
                providerSiret: commandState.providerSiret,
                providerTva: commandState.providerTva,
                providerPhone: commandState.providerPhone
            },
            articles: commandState.article,
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentUser.token
        }
    }).then ((resp) => {
        if (resp.status === 200) {
                // setStockState({
                //     ...stockState,
                //     old_message: stockState.message,
                //     message: resp.data.message
                // });
                document.getElementById("docxLink").innerHTML = resp.data;
                document.getElementById("backToPreparation").innerText = "Retour";
                document.getElementById("backToPreparation").style = "border: 1px solid black";
            }
    })
}

export default getCommand;