import axios from "axios";

async function readExistingSheets(currentUser, selectedType, setSheetNameList, name) {

    await axios({
        method: 'post', 
        url: process.env.REACT_APP_SERVER_NAME,
        params: {qz: 'crt'},
        data: {
            user_email: currentUser.email,
            user_teamUid: currentUser.teamUid,
            user_role: currentUser.role,
            typeSheetName: selectedType.title,
            typeSheetUid: selectedType.sheetUid,
            action: name,
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentUser.token
        }
    }).then((resp) => {
        if (resp.status === 200) {
            setSheetNameList(resp.data.sheetNameList)
        }
    })
}

export default readExistingSheets;