import React, { useState, useEffect, useContext } from "react";
import { UserContext } from './context/userContext'
import Comment from "./headerComponents/Comment";
import CguLink from "./headerComponents/CguLink";
import LogoAndTitleDisplaying from "./headerComponents/LogoAndTitleDisplaying";
import IdentityFunction from "./headerComponents/IdentityFunction";
import GetIdentity from "./headerComponents/GetIdentity";
import InitPassword from "./headerComponents/InitPassword";
import CommentButton from "./headerComponents/CommentButton";
import AddTaskButton from "./headerComponents/AddTaskButton";


export default function Header({toolChosen, setToolChosen, globalState, setGlobalState}) {

    const { toggleModals, currentUser} = useContext(UserContext)
    const [headerState, setHeaderState] = useState({
        commentWindow: false,
        commentSent: false,
        initPwd: false,
        tpEmail: ""
    });

    useEffect (() => {
        if(currentUser) {
            setGlobalState({
                ...globalState, 
                dispName: (currentUser.email.length > 14 ? currentUser.email.slice(0, 14) + "..." : currentUser.email)
            });
        }
    }, [])

    return (
        <div 
            className="headerGlobalStyle dim centr noselect" 
            style={{ 
                "--bgHeaderImage": globalState.theme.themes.bgHeaderImage[globalState.themeNb],
                "--headerColor": globalState.theme.themes.headerColor[globalState.themeNb]
            }}>
            <CguLink globalState={globalState} setGlobalState={setGlobalState}/>
                
            <LogoAndTitleDisplaying 
                globalState={globalState}
                toolChosen={toolChosen}
                setToolChosen={setToolChosen} />
            {currentUser && (
                <IdentityFunction 
                    globalState={globalState} />
            )}
            {!currentUser && (
                <GetIdentity 
                    globalState={globalState}
                    toggleModals={toggleModals}
                    headerState={headerState}
                    setHeaderState={setHeaderState} />
            )}
            { headerState.initPwd && (
                <InitPassword 
                    headerState={headerState}
                    setHeaderState={setHeaderState} />
            )}
            { headerState.commentWindow && (
                <Comment 
                    headerState={headerState}
                    setHeaderState={setHeaderState}
                    currentUser={currentUser} />
            )}
            { headerState.commentSent && (
                <span className="commentSent">✔</span>
            )}
            { currentUser && (
                <div className="actionHeaderZone">
                    <CommentButton 
                        globalState={globalState}
                        headerState={headerState}
                        setHeaderState={setHeaderState} />
                    { toolChosen === "task" && (
                        <AddTaskButton 
                            currentUser={currentUser}
                            globalState={globalState} />
                    )}
                </div>  
            )}
            { !currentUser && (
            <div className="actionHeaderZone"></div>
            )}
        </div>
    )
}
