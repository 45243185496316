import React from 'react'

export default function CommentButton({globalState, headerState, setHeaderState}) {
    return (
        <div className="settingButtonDiv" style={{"--headerBtColor": globalState.theme.themes.headerBtColor[globalState.themeNb]}}>
            <div onClick={() => setHeaderState({...headerState, commentWindow: !headerState.commentWindow})}>
                <span className="material-symbols-outlined noselect commentIcon" translate="no">chat</span>
            </div>
        </div>
    )
}
