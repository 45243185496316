import { createContext, useState, useEffect } from 'react';

export const UserContext = createContext();

export function UserContextProvider (props) {

    const [currentUser, setCurrentUser] = useState(false);
    const [apiIdentifier, setApiIdentifier] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const [token, setToken] = useState("");

    useEffect (() => {

        return ;
    }, [])
    const [modalState, setModalState] = useState({
        signUpModal: false,
        signInModal: false
    })
    const toggleModals = modal => {
        if (modal === "signIn") {
            setModalState({
                signUpModal: false,
                signInModal: true
            })
        }
        if (modal === "signUp") {
            setModalState({
                signUpModal: true,
                signInModal: false
            })
        }
        if (modal === "close") {
            setModalState({
                signUpModal: false,
                signInModal: false,
            })
        }
    }

    return( 
        <UserContext.Provider value={{  modalState, 
                                        toggleModals, 
                                        currentUser, 
                                        setCurrentUser, 
                                        apiIdentifier, 
                                        setApiIdentifier, 
                                        token, 
                                        setToken
                                        }}>
            { !loadingData && props.children }
        </UserContext.Provider>
    )
}