import React, { useState, useEffect } from "react";
import editProvider from './stockRequest/editProvider';

export default function ManagProvider({ newData, 
                                        setNewData, 
                                        currentUser, 
                                        stockState, 
                                        setStockState,
                                        controlText, 
                                        managProvider, 
                                        setManagProvider}) {

    const [saveProviderData, setSaveProviderData] = useState(false);

    useEffect (() => {
        if (managProvider.providerSelected.trade_name !== newData.tradeName) {
            setNewData({tradeName: managProvider.providerSelected.trade_name});
        }

        return () => {};
    })

    function changeProviderValue (name, e) { 
        if (name === "webLink") {
            setManagProvider({...managProvider, providerSelected: {...managProvider.providerSelected, web_link: controlText(e.target.value)}});
            setNewData({...newData, webLink: controlText(e.target.value)});
        }
        if (name === "contactName") {
            setManagProvider({...managProvider, providerSelected: {...managProvider.providerSelected, contact_name: controlText(e.target.value)}});
            setNewData({...newData, contactName: controlText(e.target.value)});
        }
        if (name === "phone") {
            setManagProvider({...managProvider,providerSelected: {...managProvider.providerSelected, phone: controlText(e.target.value)}});
            setNewData({...newData, phone: controlText(e.target.value)});
        }
        if (name === "addressOne") {
            setManagProvider({...managProvider,providerSelected: {...managProvider.providerSelected, address_one: controlText(e.target.value)}});
            setNewData({...newData, addressOne: controlText(e.target.value)});
        }
        if (name === "addressTwo") {
            setManagProvider({...managProvider,providerSelected: {...managProvider.providerSelected, address_two: controlText(e.target.value)}});
            setNewData({...newData, addressTwo: controlText(e.target.value)});
        }
        if (name === "zipTown") {
            setManagProvider({...managProvider,providerSelected: {...managProvider.providerSelected, zip_town: controlText(e.target.value)}});
            setNewData({...newData, zipTown: controlText(e.target.value)});
        }
        if (name === "siret") {
            setManagProvider({...managProvider,providerSelected: {...managProvider.providerSelected, siret: controlText(e.target.value)}});
            setNewData({...newData, siret: controlText(e.target.value)});
        }
        if (name === "tva") {
            setManagProvider({...managProvider,providerSelected: {...managProvider.providerSelected, tva: controlText(e.target.value)}});
            setNewData({...newData, tva: controlText(e.target.value)});
        }
        setSaveProviderData(true);
    }

    function saveOrClose () {
        setManagProvider({...managProvider, displayProvider: false});
        if (saveProviderData === true) {
            editProvider (currentUser, stockState, setStockState, setManagProvider, newData, "update");
        }
        setSaveProviderData(false);
    }

    return (
        <div className="provManagWindowContainer">
            <div className="provManagWindow">
                { !saveProviderData && (
                    <span 
                        className="material-symbols-outlined closeButton"
                        onClick={saveOrClose}>
                        cancel
                    </span>
                )}
                { saveProviderData && (
                    <span 
                        className="material-symbols-outlined closeButton"
                        onClick={saveOrClose}
                        style={{"color": "orange"}}>
                        save
                    </span>
                )}
                <div className="provManagTitle centr">
                    <h4>Carnet des fournisseurs</h4>
                </div>
                <div className="displayProviderSelection" style={{"textAlign": "center"}}>
                    <select onChange={(e) => {
                        setNewData({tradeName: e.target.value});
                        editProvider(currentUser, stockState, setStockState, setManagProvider, {tradeName: e.target.value}, "read");
                        }}>
                        <option key="selectedProv" value="choix">Choix ?</option>
                        { managProvider.providerList.map((prov, index) => (
                            <option key={index} value={prov.trade_name}>{prov.trade_name}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <div className="displayProviderData">
                        <span className="providerDataName">Date de création: </span>
                        <input type="text" className="providerData" 
                            value={managProvider.providerSelected.created_at} disabled />
                    </div>
                    <div className="displayProviderData">
                        <span className="providerDataName">Entreprise: </span>
                        <input className="providerData" id="providerTradeName" 
                            value={managProvider.providerSelected.trade_name} disabled /> 
                    </div>
                    <div className="displayProviderData">
                        <span className="providerDataName">Site web: </span>
                        <input type="text" 
                            className="providerData" 
                            onChange={(e) => changeProviderValue("webLink", e)} 
                            value={managProvider.providerSelected.web_link} />
                    </div>
                    <div className="displayProviderData">
                        <span className="providerDataName">Contact: </span>
                        <input type="text" 
                            className="providerData" 
                            onChange={(e) => changeProviderValue("contactName", e)} 
                            value={managProvider.providerSelected.contact_name} />
                    </div>
                    <div className="displayProviderData">
                        <span className="providerDataName">Téléphone: </span>
                        <input type="text" 
                            className="providerData" 
                            onChange={(e) => changeProviderValue("phone", e)} 
                            value={managProvider.providerSelected.phone} />
                    </div>
                    <div className="displayProviderData">
                        <span className="providerDataName">Addresse: </span>
                        <input type="text" 
                            className="providerData" 
                            onChange={(e) => changeProviderValue("addressOne", e)} 
                            value={managProvider.providerSelected.address_one} />
                    </div>
                    <div className="displayProviderData">
                        <span className="providerDataName">Complément: </span>
                        <input type="text" 
                            className="providerData" 
                            onChange={(e) => changeProviderValue("addressTwo", e)} 
                            value={managProvider.providerSelected.address_two} />
                    </div>
                    <div className="displayProviderData">
                        <span className="providerDataName">Code Postal / ville: </span>
                        <input type="text" 
                            className="providerData" 
                            onChange={(e) => changeProviderValue("zipTown", e)} 
                            value={managProvider.providerSelected.zip_town} />
                    </div>
                    <div className="displayProviderData">
                        <span className="providerDataName">SIRET: </span>
                        <input type="text" 
                            className="providerData" 
                            onChange={(e) => changeProviderValue("siret", e)} 
                            value={managProvider.providerSelected.siret} />
                    </div>
                    <div className="displayProviderData">
                        <span className="providerDataName">TVA: </span>
                        <input type="text" 
                            className="providerData" 
                            onChange={(e) => changeProviderValue("tva", e)} 
                            value={managProvider.providerSelected.tva} />
                    </div>
                </div>
            </div>
        </div>
    )
}
