import React, {useState} from "react";

export default function DispRadioQuiz({ currentUser, 
                                        setErrorMessage,
                                        quiz,
                                        index, 
                                        sendQuizToApi,
                                        deployBuildForm, 
                                        articleManagMode,
                                        quizType, 
                                        quizContent, 
                                        respContent,
                                        setRespContent, 
                                        sheetList, 
                                        setSheetList,
                                        infoColor,
                                        setInfoColor,
                                        sheetState }) {

    const [reloadSheet, setReloadSheet] = useState(false);
                                        
    function presaveRadioData(tamponResp, quizId) {
        setInfoColor("red");
        tamponResp.map((r, index) => {
            if (r[0].id === quiz[0].id && r[0].id === quizId) {

                tamponResp[index][0].radio1 = 
                    (quiz[0].radio_answer_1 !== "" && document.getElementById(quiz[0].id + "radio1").checked) ? "1" : "0";
                tamponResp[index][0].radio2 = 
                    (quiz[0].radio_answer_2 !== "" && document.getElementById(quiz[0].id + "radio2").checked) ? "1" : "0";
                tamponResp[index][0].radio3 = 
                    (quiz[0].radio_answer_3 !== "" && document.getElementById(quiz[0].id + "radio3").checked) ? "1" : "0";
                tamponResp[index][0].radio4 = 
                    (quiz[0].radio_answer_4 !== "" && document.getElementById(quiz[0].id + "radio4").checked) ? "1" : "0";
                tamponResp[index][0].radio5 = 
                    (quiz[0].radio_answer_5 !== "" && document.getElementById(quiz[0].id + "radio5").checked) ? "1" : "0";
                }
        });
        setRespContent(tamponResp);
        setReloadSheet(!reloadSheet);
    }

    return (
        <div className="sheetLine">
            <p style={{"padding": "0 15px", "color": infoColor}}>
                <span>{index + 1} - </span>
                {quiz[0].quiz_title}
                <span 
                    className="lengthIndicator"
                    style={{"color": quizContent.title && quizContent.title.length <= 2 ? "red" : "green"}}>
                    Un seul choix possible
                </span>
            </p>
            <div className="checkUnitContainer">
                { (sheetState === "en cours" && quiz[0].radio_answer_1) && (
                    <div className="checkUnit">
                         { respContent[index][0].radio1 === "1" &&  (
                            <input type="radio" name={quiz[0].id + "radio"} id={quiz[0].id + "radio1"}
                                onChange={(e) => presaveRadioData(respContent, parseInt(e.target.id))} checked />
                        )}
                        { respContent[index][0].radio1 !== "1" && (
                            <input type="radio" name={quiz[0].id + "radio"} id={quiz[0].id + "radio1"}
                                onChange={(e) => presaveRadioData(respContent, parseInt(e.target.id))} />
                        )}
                        <label>{quiz[0].radio_answer_1}</label>
                    </div>
                )}
                { (sheetState !== "en cours" && quiz[0].radio_answer_1 && respContent[index][0].radio1 === "1") && (
                    <p style={{"padding": "0 15px"}}>Réponse: {quiz[0].radio_answer_1}</p>
                )}
                { (sheetState === "en cours" && quiz[0].radio_answer_2) && (
                    <div className="checkUnit">
                        { respContent[index][0].radio2 === "1" && (
                            <input type="radio" name={quiz[0].id + "radio"} id={quiz[0].id + "radio2"}
                                onChange={(e) => presaveRadioData(respContent, parseInt(e.target.id))} checked />
                        )}
                        { respContent[index][0].radio2 !== "1" && (
                            <input type="radio" name={quiz[0].id + "radio"} id={quiz[0].id + "radio2"}
                                onChange={(e) => presaveRadioData(respContent, parseInt(e.target.id))} />
                        )}
                        <label>{quiz[0].radio_answer_2}</label>
                    </div>
                )}
                { (sheetState !== "en cours" && quiz[0].radio_answer_2 && respContent[index][0].radio2 === "1") && (
                    <p style={{"padding": "0 15px"}}>{quiz[0].radio_answer_2}</p>
                )}
                { (sheetState === "en cours" && quiz[0].radio_answer_3) && (
                    <div className="checkUnit">
                        { respContent[index][0].radio3 === "1" && (
                            <input type="radio" name={quiz[0].id + "radio"} id={quiz[0].id + "radio3"}
                                onChange={(e) => presaveRadioData(respContent, parseInt(e.target.id))} checked />
                        )}
                        { respContent[index][0].radio3 !== "1" && (
                                <input type="radio" name={quiz[0].id + "radio"} id={quiz[0].id + "radio3"}
                                    onChange={(e) => presaveRadioData(respContent, parseInt(e.target.id))} />
                        )}
                        <label>{quiz[0].radio_answer_3}</label>
                    </div>
                )}
                { (sheetState !== "en cours" && quiz[0].radio_answer_3 && respContent[index][0].radio3 === "1") && (
                    <p style={{"padding": "0 15px"}}>{quiz[0].radio_answer_3}</p>
                )}
                { (sheetState === "en cours" && quiz[0].radio_answer_4) && (
                    <div className="checkUnit">
                        { respContent[index][0].radio4 === "1" && (
                            <input type="radio" name={quiz[0].id + "radio"} id={quiz[0].id + "radio4"}
                                onChange={(e) => presaveRadioData(respContent, parseInt(e.target.id))} checked />
                        )}
                        { respContent[index][0].radio4 !== "1" && (
                                <input type="radio" name={quiz[0].id + "radio"} id={quiz[0].id + "radio4"}
                                    onChange={(e) => presaveRadioData(respContent, parseInt(e.target.id))} />
                        )}
                        <label>{quiz[0].radio_answer_4}</label>
                    </div>
                )}
                { (sheetState !== "en cours" && quiz[0].radio_answer_4 && respContent[index][0].radio4 === "1") && (
                    <p style={{"padding": "0 15px"}}>{quiz[0].radio_answer_4}</p>
                )}
                { (sheetState === "en cours" && quiz[0].radio_answer_5) && (
                    <div className="checkUnit">
                        { respContent[index][0].radio5 === "1" && (
                            <input type="radio" name={quiz[0].id + "radio"} id={quiz[0].id + "radio5"}
                                onChange={(e) => presaveRadioData(respContent, parseInt(e.target.id))} checked />
                        )}
                        { respContent[index][0].radio5 !== "1" && (
                            <input type="radio" name={quiz[0].id + "radio"} id={quiz[0].id + "radio5"}
                                onChange={(e) => presaveRadioData(respContent, parseInt(e.target.id))} />
                        )}
                        <label>{quiz[0].radio_answer_5}</label>
                    </div>
                )}
                { (sheetState !== "en cours" && quiz[0].radio_answer_5 && respContent[index][0].radio5 === "1") && (
                    <p style={{"padding": "0 15px"}}>{quiz[0].radio_answer_5}</p>
                )}
            </div>
            {((currentUser.role === "ROLE_USER" || currentUser.role === "ROLE_MANAGER") && articleManagMode && deployBuildForm) && (
                <div style={{"transform": "scale(0.7)"}}>
                    <button 
                        className="deletionButton"
                        onClick={() => sendQuizToApi(   currentUser, 
                                                        setErrorMessage, 
                                                        articleManagMode,
                                                        quizType, 
                                                        quizContent, 
                                                        sheetList, 
                                                        setSheetList, 
                                                        "deleteQuiz", 
                                                        quiz[0].id ) }>
                            Supprimer cette question
                    </button>
                </div>
            )}
        </div>
    )
}
