import axios from "axios";

async function sendRespToApi (currentUser, selectedType,  respContent, respUid, sheetFormUid, setSheetNameList, action) {

    await axios({
        method: 'post', 
        url: process.env.REACT_APP_SERVER_NAME,
        params: {qz: 'crt'},
        data: {
            user_email: currentUser.email,
            user_teamUid: currentUser.teamUid,
            user_role: currentUser.role,
            action: action,
            typeSheetName: selectedType.title,
            typeSheetUid: selectedType.sheetUid,
            respContent: respContent,
            respUid: respUid,
            sheetFormUid: sheetFormUid
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentUser.token
        }
    }).then((resp) => {
        if (resp.status === 200) {
            if (resp.data.sheetNameList) {
                setSheetNameList(resp.data.sheetNameList);
            }
        }
    })
}

export default sendRespToApi;