function addCommand (buildCommandInfo, setBuildCommandInfo, article) {
    setBuildCommandInfo({
        ...buildCommandInfo, 
        commandInfoId: article.id,
        commandInfoAuthorAvt: "personLg.png",
        commandInfoForm: true,
        commandInfoRef: article.ref,
        commandInfoDes: article.designator,
        commandInfoCat: article.category
    })
    if (article.command) {
        setBuildCommandInfo({
            ...buildCommandInfo, 
            commandInfoId: article.id,
            commandInfoAuthorAvt: article.command.avatar,
            commandInfoForm: true,
            commandInfoRef: article.ref,
            commandInfoDes: article.designator,
            commandInfoCat: article.category,
            commandInfoOrderRef: article.command.order_ref ? article.command.order_ref : "",
            commandInfoOrderDate: article.command.order_date ? article.command.order_date : "",
            commandInfoShipDate: article.command.ship_date ? article.command.ship_date : "",
            commandInfoGetDate: article.command.get_date ? article.command.get_date : "",
            commandInfoQuantity: article.command.command_quantity ? article.command.command_quantity : "0"
        });
    } else {
        setBuildCommandInfo({
            ...buildCommandInfo, 
            commandInfoForm: true,
            commandInfoId: article.id,
            commandInfoAuthorAvt: "personLg.png",
            commandInfoRef: article.ref,
            commandInfoDes: article.designator,
            commandInfoCat: article.category,
            commandInfoOrderRef: "",
            commandInfoOrderDate: "",
            commandInfoShipDate: "",
            commandInfoGetDate: "",
            commandInfoQuantity: ""
        });
    }
}

export default addCommand;