import controlText from './controlText';
import addCommandInfo from './stockRequest/addCommandInfo';

export default function CommandInfo({currentUser, stockState, setStockState, buildCommandInfo, setBuildCommandInfo}) {
  return (
    <div className="commandInfoFormContainer">
                        <div className="commandInfoForm">
                        <span 
                            className="closeButton" 
                            // className="addStockClose" 
                            onClick={() => setBuildCommandInfo({...buildCommandInfo, commandInfoForm: false})}>
                                <span className="material-symbols-outlined">close</span>
                        </span>
                            <div>
                                <h3>Renseigner la commande</h3>
                                <ul>
                                    <li><span className="partCommandDetail">Référence: </span>{buildCommandInfo.commandInfoRef}</li>
                                    <li><span className="partCommandDetail">Désignation: </span>{buildCommandInfo.commandInfoDes}</li>
                                    {/* <li><span className="partCommandDetail">Catégorie: </span>{buildCommandInfo.commandInfoCat}</li> */}
                                </ul>
                            </div>
                            <hr style={{"color": "white", width: "250px"}} />
                            <span>
                            <img 
                                className="commandAuthorImg" 
                                src={process.env.REACT_APP_SERVER_NAME.slice(0,-14) + "zavatars/" + buildCommandInfo.commandInfoAuthorAvt} 
                                alt="Author avatar" />
                            </span>
                            <div className="commandFormStyle">
                                <div className="buildCommandInput">
                                    <label forhtml="commandNbParts">Nombre de pièces en commande: </label>
                                    <input type="number" name="commandNbParts" min="1" max="9999" step="1" 
                                        onChange={(e) => setBuildCommandInfo({...buildCommandInfo, commandInfoQuantity: controlText(e.target.value)})}
                                        value={buildCommandInfo.commandInfoQuantity}
                                    />
                                </div>
                                <div className="buildCommandInput">
                                    <label forhtml="commandNumber">Référence de la commande: </label>
                                    <input type="text" name="commandNumber" size="10" 
                                        onChange={(e) => setBuildCommandInfo({...buildCommandInfo, commandInfoOrderRef: controlText(e.target.value)})}
                                        value={buildCommandInfo.commandInfoOrderRef}
                                    />
                                </div>
                                <div className="buildCommandInput">
                                    <label forhtml="commandDateShip" 
                                    style={{"color": buildCommandInfo.commandInfoOrderDate === "" ? "red" : "lightgreen"}}>
                                        Date de la commande: </label>
                                    <input type="date" name="commandDateShip" min="1" max="9999" step="1" 
                                        onChange={(e) => setBuildCommandInfo({...buildCommandInfo, commandInfoOrderDate: controlText(e.target.value)})}
                                        value={buildCommandInfo.commandInfoOrderDate}
                                    />
                                </div>
                                <div className="buildCommandInput">
                                    <label forhtml="commandShip">Date d'expédition: </label>
                                    <input type="date" name="commandShip" min="1" max="9999" step="1" 
                                        onChange={(e) => setBuildCommandInfo({...buildCommandInfo, commandInfoShipDate: controlText(e.target.value)})}
                                        value={buildCommandInfo.commandInfoShipDate}
                                    />
                                </div>
                                <div className="buildCommandInput">
                                    <label forhtml="commandreception">Livraison estimée le: </label>
                                    <input type="date" name="commandreception" min="1" max="9999" step="1" 
                                        onChange={(e) => setBuildCommandInfo({...buildCommandInfo, commandInfoGetDate: controlText(e.target.value)})}
                                        value={buildCommandInfo.commandInfoGetDate}
                                    />
                                </div>
                            </div>
                            <div className="buildCommandInput">
                                <button onClick={() => addCommandInfo(  currentUser,
                                                                        buildCommandInfo,
                                                                        setBuildCommandInfo,
                                                                        stockState, 
                                                                        setStockState, 
                                                                        "delete")}
                                        className="deletionButton" >Supprimer</button>
                                <button onClick={() => addCommandInfo(  currentUser,
                                                                        buildCommandInfo,
                                                                        setBuildCommandInfo,
                                                                        stockState, 
                                                                        setStockState, 
                                                                        "save")}
                                        className="validationButton" >Enregistrer</button>
                            </div>
                            <div className="buildCommandInputInsert">
                                <button 
                                        onClick={() => addCommandInfo(  currentUser,
                                                                        buildCommandInfo,
                                                                        setBuildCommandInfo,
                                                                        stockState, 
                                                                        setStockState, 
                                                                        "insertInStock")}
                                        style={{"background": "orange"}}
                                        className="deletionButton" >Valider la quantité dans le stock et supprimer la commande</button>
                            </div>
                        </div>
                    </div>
  )
}
