import React from "react";
import initPassword from "./globalRequest/initPassword";

export default function GetIdentity({globalState, toggleModals, headerState, setHeaderState}) {
    return (
        <div className="loginButonZone">
            <div className="unidentity material-symbols-outlined" translate="no">person</div>
                <button 
                    className="signupBut"
                    onClick={() => toggleModals("signUp")}>
                        Inscripion
                </button>
                <button 
                    className="signinBut"
                    onClick={() => toggleModals("signIn")}>
                        Connexion
                </button>
                <button 
                    className="resetPass"
                    onClick={() => initPassword(headerState, setHeaderState)}>
                        Reset mot de passe
                </button>
        </div>
    )
}
