import React from "react";

function AssMenu({titleMenu, setTitleMenu}) {
    
    return (
        <div className="titleMenu noselect">
            <div 
                className="mountMenuChoice" 
                style={{"background":( titleMenu === "Assemblages") ? "#AEE3A5" : "#B0B6A0"}}
                onClick={() => setTitleMenu("Assemblages")}>
                <span>Assemblages</span>
            </div>
            <div 
                className="mountMenuChoice" 
                style={{"background": (titleMenu === "Detail") ? "#AEE3A5" : "#B0B6A0"}}
                onClick={() => setTitleMenu("Detail")}>
                <span>Détail</span>
            </div>
            <div 
                className="mountMenuChoice" 
                style={{"background": (titleMenu === "Eclate") ? "#AEE3A5" : "#B0B6A0"}}
                onClick={() => setTitleMenu("Eclate")}>
                <span>Eclaté</span>
            </div>
            <div 
                className="mountMenuChoice" 
                style={{"background": (titleMenu === "3D") ? "#AEE3A5" : "#B0B6A0"}}
                onClick={() => setTitleMenu("Fichiers")}>
                <span>Fichiers</span>
            </div>
        </div>
    )
}

export default AssMenu;