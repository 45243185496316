function addFilter( stockState, 
                    setStockState, 
                    setTextFetch, 
                    quantChange, 
                    setQuantChange, 
                    setUserHoverTable, 
                    column, 
                    e ) {
        setUserHoverTable(false);
    if (column !== "" && column !== "minQuant" && e !== null) {
        setStockState({
            ...stockState,
            filter: {
                column: column, 
                value: e.target.value ? e.target.value : "",
                minQuant: stockState.filter.minQuant
            }
        })
        if (column !== "ref") {document.getElementById("refFilter").selectedIndex = 0;}
        if (column !== "designator") {document.getElementById("desFilter").selectedIndex = 0;}
        if (column !== "cat") {document.getElementById("catFilter").selectedIndex = 0;}
        if (column !== "provider") {document.getElementById("proFilter").selectedIndex = 0;}
    } else if (column === "minQuant" && e === null) {
        setStockState({
            ...stockState, 
            filter: {
                ...stockState.filter, 
                minQuant: true
            }
        });
    } else {
        document.getElementById("refFilter").selectedIndex = 0;
        document.getElementById("desFilter").selectedIndex = 0;
        document.getElementById("catFilter").selectedIndex = 0;
        document.getElementById("proFilter").selectedIndex = 0;
        setStockState({
            ...stockState,
            refTextValues: [],
            desTextValues: [],
            catTextValues: [],
            proTextValues: [],
            filter: {
                column: "",
                value: "",
                minQuant: false
            }
        })
       setTextFetch({
            refTextFetch: "",
            desTextFetch: "",
            catTextFetch: "",
            proTextFetch: "",
        });
    }

    setQuantChange(quantChange + 1);
}

export default addFilter;