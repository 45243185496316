import axios from "axios";

function editProvider (currentUser, stockState, setStockState, setManagProvider, newData, action) {  
    (async() => {
        const getProvider  = await axios({
            method: 'post', 
            url: process.env.REACT_APP_SERVER_NAME,
            params: {mg: 'prov'},
            data: {
                user_email: currentUser.email,
                user_teamUid: currentUser.teamUid,
                providerData: newData,
                action: action,
                paginator: {
                    page: stockState.page,
                    perPage: stockState.perPage
                }
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + currentUser.token
            }
        }).then ((resp) => {
            if (resp.status === 200) {
                setStockState({
                    ...stockState,
                    
                    old_message: stockState.message,
                    message: resp.data.message
                });
                setManagProvider({
                    displayProvider: true,
                    providerList: resp.data.providerList,
                    providerSelected: {
                        id: (resp.data.providerSelected.id !== null) ? resp.data.providerSelected.id : 0 ,
                        trade_name: (resp.data.providerSelected.trade_name !== null) ? resp.data.providerSelected.trade_name : "" ,
                        created_at: (resp.data.providerSelected.created_at !== null) ? resp.data.providerSelected.created_at : "" ,
                        contact_name: (resp.data.providerSelected.contact_name !== null) ? resp.data.providerSelected.contact_name : "" ,
                        address_one: (resp.data.providerSelected.address_one !== null) ? resp.data.providerSelected.address_one : "" ,
                        address_two: (resp.data.providerSelected.address_two !== null) ? resp.data.providerSelected.address_two : "" ,
                        zip_town: (resp.data.providerSelected.zip_town !== null) ? resp.data.providerSelected.zip_town : "" ,
                        phone: (resp.data.providerSelected.phone !== null) ? resp.data.providerSelected.phone : "" ,
                        web_link: (resp.data.providerSelected.web_link !== null) ? resp.data.providerSelected.web_link : "" ,
                        siret: (resp.data.providerSelected.siret !== null) ? resp.data.providerSelected.siret : "" ,
                        tva: (resp.data.providerSelected.tva !== null) ? resp.data.providerSelected.tva : "" ,
                    }
                });
            } else {
                setManagProvider({
                    displayProvider: false,
                    providerSelected: {},
                    providerList: []
                });
            }
            
        })
    })()
}

export default editProvider;