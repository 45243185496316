import axios from "axios";

async function readOneSheet(currentUser, 
                            name, 
                            quizGlobal, 
                            setQuizGlobal, 
                            setRespContent, 
                            setSheetState,
                            sheetName ) {

                setRespContent(null);
                setQuizGlobal(null);

    await axios({
        method: 'post', 
        url: process.env.REACT_APP_SERVER_NAME,
        params: {qz: 'crt'},
        data: {
            user_email: currentUser.email,
            user_teamUid: currentUser.teamUid,
            user_role: currentUser.role,
            action: "readOneSheet",
            name: name,
            sheetName: sheetName
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentUser.token
        }
    }).then((resp) => {
            if (resp.status === 200) {
                var quizArray = Object.keys(resp.data.quizContent).map(function(cle) {
                    return [resp.data.quizContent[cle]];
                });
                var respArray = Object.keys(resp.data.respContent).map(function(cle) {
                    return [resp.data.respContent[cle]];
                });
                setSheetState(resp.data.respState ? resp.data.respState : {resp_state: "en cours"})
                setRespContent(respArray);
                setQuizGlobal(quizArray);
            }
        
    })
}

export default readOneSheet;