import React from "react";

export default function AssemblageTable({assemblyList, editOneMountage}) {
    return (
        <div className="dispAssemblyParts">   
            <table className="noselect">
                <tbody>
                    <tr key="headerTable" className="topMountageTable">
                        <td key="assChoice">Editer</td>
                        <td key="assRef">Référence</td>
                        <td key="assInd">Ind.</td>
                        <td key="assDes">Désignation</td>
                        <td key="assQuant">Quant.</td>
                        <td key="assMin">Quant. min</td>
                        <td key="assDelay">delai</td>
                    </tr>
                    { (assemblyList.length > 0) &&  assemblyList.map((ass, index) => 
                        <tr key={"data" + index}>
                            <td 
                            key={"edition"} 
                            className="editMountage"
                            onClick={() => editOneMountage(ass.ref)}><span className="material-symbols-outlined">edit_square</span></td>
                            <td key={"ref" + index}>{ass.ref}</td>
                            <td key={"hint" + index}>{ass.hint}</td>
                            <td key={"des" + index}>{ass.designator}</td>
                            <td key={"quant" + index}>{ass.quantity}</td>
                            <td key={"minQuant" + index}>{ass.min_quantity}</td>
                            <td key={"delay" + index}>{ass.delay_appro}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
  )
}
