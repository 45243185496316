import React from 'react'

export default function StateFilterButton({value, text, changeState, nbTask}) {

    return (
        <div 
            className="actionChoice" 
            id={value} 
            onClick={() => {changeState(value)}}>{text}
            <span id="indiceNbTask">{nbTask}</span>
        </div>
    )
}
