import React, { useState, useEffect, useRef } from "react";
import AssMenu from "./mountageComponents/AssMenu";
import AssemblageTable from "./mountageComponents/AssemblageTable";
import editMountage from "./mountageRequest/editMountage";
import changeAssemblyImage from "./mountageRequest/changeAssemblyImage";
import addOnePartFile from "./mountageRequest/addOnePartFile";
import getPartFiles from "./mountageRequest/getPartFiles";
import deletePartFile from "./mountageRequest/deletePartFile";
import AssemblageCreation from "./AssemblageCreation";
import getAssembly from './stockRequest/getAssembly';

export default function Assemblages({currentUser}) {

    const [assemblyList, setAssemblyList] = useState({});
    const [assemblyDetail, setAssemblyDetail] = useState({});
    const [canvasMode, setCanvasMode] = useState({
        editMode: false,
        colorMode: false,
        colorChoice: "red",
        createMode: false,
        deleteMode: false
    });
    const actualHightlightZone = useRef([]);
    const [assemblCreateForm, setAssemblCreateForm] = useState(false);
    const [currentRef, setCurrentRef] = useState(null);
    const [titleMenu, setTitleMenu] = useState("Assemblages");
    const [dispPartFilesForm, setDispPartFileForm] = useState(false);
    const [partFilesDetail, setPartFilesDetail] = useState(null);
    const [exportation, setExportation] = useState(false);

    useEffect (() => {
        getAssembly(currentUser, setAssemblyList);
        
        return ;
    }, [currentUser, assemblyDetail, titleMenu, partFilesDetail])

    function getPoint (e) { 
        if (e !== null && canvasMode.createMode) {
            actualHightlightZone.current.push([e.clientX-100,  e.clientY-220]);
        }
    }
    function editOneMountage (ref) {
        editMountage(ref, currentUser, setAssemblyDetail);
        setTitleMenu("Detail");
    }
    function handleChangeAssemblyImage(file) {
        changeAssemblyImage({currentUser, file, assemblyDetail, setAssemblyDetail});
    }
    function displayFileForm (ref) {
        setDispPartFileForm(true);
        getPartFiles({ref, currentUser, partFilesDetail, setPartFilesDetail});
    }
    function addPartFile(file) {
        addOnePartFile({currentUser, file, partFilesDetail, setPartFilesDetail});
    }
    function viewFile (file) {
        // console.log(file)
    }
    function deleteFile (file) {
        deletePartFile({currentUser, file, partFilesDetail, setPartFilesDetail});
    }

    return (
        <div className="noselect">
            { true && assemblCreateForm && (
                <AssemblageCreation
                    setAssemblyList={setAssemblyList}
                    currentUser={currentUser}
                    setAssemblCreateForm={setAssemblCreateForm} />
            )}
            { dispPartFilesForm && (
                <div className="addModaleWindowContainer">
                    <div className="addModaleWindow">
                        <span 
                            className="material-symbols-outlined closeButton"
                            onClick={() => setDispPartFileForm(false)}>
                                cancel
                        </span>
                        { partFilesDetail && (
                            <>
                                <div className="inputPartData">
                                    <p>{assemblyDetail.designator} : <b>{partFilesDetail.ref}</b> </p>
                                    <p>{partFilesDetail.message}</p>
                                </div>
                                    <div>
                                        <ul>
                                            { partFilesDetail.existsPartsFiles.map((file, index) => {
                                                return (
                                                    <li key={"file" + index}>
                                                        Fichier {index + 1}: <b>
                                                        { !exportation && (
                                                            <>
                                                                {file.file_name.slice(30)}
                                                            </>
                                                        )}
                                                        { exportation && (
                                                            <a 
                                                            href={process.env.REACT_APP_SERVER_NAME.slice(0, -14) + file.file_name.slice(2)} 
                                                            onClick={() => setExportation(false)}
                                                            download>
                                                                {file.file_name.slice(30)}
                                                            </a>
                                                        )}
                                                        </b>
                                                        <span 
                                                            className="material-symbols-outlined fileOption1"
                                                            onClick={() => viewFile(file.file_name)}>
                                                            visibility
                                                        </span>
                                                        <span 
                                                            className="material-symbols-outlined fileOption2"
                                                            onClick={() => setExportation(true)}>
                                                            download
                                                        </span>
                                                        <span 
                                                            className="material-symbols-outlined fileOption3"
                                                            onClick={() => deleteFile(file.file_name)}>
                                                            delete
                                                        </span>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                <div className="inputPartFile">
                                    <div style={{"textAlign": "center"}}>
                                        <span className="material-symbols-outlined">attach_file_add</span>
                                        <span className="addFileText">Ajouter un fichier</span>
                                    </div>
                                    <input 
                                        onChange={(e) => addPartFile(e.target.files)}
                                        type="file"
                                        name="partFileUpload"
                                        className="addFileBut validationButton" />
                                </div>
                            </>
                        )}
                        
                    </div>
                </div>
            )}
            <div className="dispExplodedParts">
                { true === false && (
                    <div className="showExplodedModel">
                        <canvas
                            width="500"
                            height="500"
                            id="canvas"
                            onClick={(e) => getPoint(e)} >
                            <img 
                                id="explodedImg"
                                // onLoad={() => draw()}
                                src={assemblyDetail.assembly_image ? 
                                process.env.REACT_APP_SERVER_NAME.slice(0,-14) + assemblyDetail.assembly_image : ""} 
                                alt="eclate"
                            />
                        </canvas>  
                    </div>
                )}
                <AssMenu
                    titleMenu={titleMenu}
                    setTitleMenu={setTitleMenu} />
                <div className="showReferences">
                    { titleMenu === "Assemblages" && (
                        <AssemblageTable 
                            assemblyList={assemblyList} 
                            editOneMountage={editOneMountage} />
                    )}  
                    { titleMenu === "Detail" && (
                        <div>
                            <div className="detailTopPage">
                                <ul className="assemblyDispDetail">
                                    <li key={"refDetail"}>Référence: <strong>{assemblyDetail.ref}</strong> ind: <strong>{assemblyDetail.hint}</strong></li>
                                    <li key={"desDetail"}>Désignation: <strong>{assemblyDetail.designator}</strong></li>
                                    <li key={"quantDetail"}>Quantity: <strong>{assemblyDetail.quantity}</strong></li>
                                    <li key={"minQuantDetail"}>Quant minimum: <strong>{assemblyDetail.min_quantity}</strong></li>
                                    <li key={"delayDetail"}>Delai: <strong>{assemblyDetail.delay_appro}</strong></li>
                                </ul>
                                <div className="managMountageParts">
                                    <p>Catégorie: <strong>{assemblyDetail.ref}</strong></p>
                                </div>
                            </div>
                            { currentUser && currentUser.role === "ROLE_MANAGER" && (
                                <>
                                    <div className="changeAssemblyImage">
                                        <label>Charger votre l'image de votre assemblage (png)</label>
                                        <input 
                                            type="file" 
                                            name="assemblyImg"
                                            onChange={(e) => handleChangeAssemblyImage(e.target.files)} />
                                    </div>
                                    { assemblyDetail.assImgLoading && (
                                        <div className="dispAssImgLoadMessage">
                                            <p style={{"color": (assemblyDetail.assImgLoading === "Votre image est enregistrée.") ? "blue" : "red"}}>
                                                {assemblyDetail.assImgLoading}
                                            </p>
                                        </div>
                                    )}
                                </>
                            )}
                            <div className="detailParts">
                                <table className="noselect">
                                    <thead>
                                        <tr>
                                            <td className="mountTh" key="partNb"><strong>{assemblyDetail.ref}</strong></td>
                                            <td className="mountTh" key="partRef">Ref.</td>
                                            <td className="mountTh" key="partDes">Designation</td>
                                        </tr>
                                    </thead>
                                    <tbody className="assemblyDispDetail">
                                        { assemblyDetail.parts && assemblyDetail.parts.map((assDet, index) => ( 
                                            <tr 
                                                key={assDet.part_ref} 
                                                style={{"color": (currentRef === assDet.part_ref) ? "green" : "black"}}
                                                // onMouseEnter={() => drawZone(assDet.part_ref, assDet.part_zone, "show")} 
                                                >
                                                <td>Piéce{index + 1}</td>
                                                <td>{assDet.part_ref}</td>
                                                <td>{assDet.part_des}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}  
                    { titleMenu === "Eclate" && (
                        <div>
                            <div className="dispExplodedTitle">
                                <p>Catégorie: <strong>{assemblyDetail.ref}</strong></p>
                            </div>
                            { assemblyDetail.assembly_image && assemblyDetail.assembly_image !== "" && (
                                <div className="dispExplodedAssImg">
                                    <img 
                                    
                                        src={process.env.REACT_APP_SERVER_NAME.slice(0, -14) + assemblyDetail.assembly_image} 
                                        alt="eclaté"/>
                                </div>
                            )}
                            { !assemblyDetail.assembly_image && (
                                <div className="dispExplodedAssImg">
                                    <p style={{"background":"lightgrey", "padding":"8px"}}>Pas d'image disponible</p>
                                </div>
                            )}
                        </div>
                    )}   
                    { titleMenu === "Fichiers" && (
                        <div>
                            <table>
                                <tbody>
                                { assemblyDetail.parts && assemblyDetail.parts.map((assDet, index) => ( 
                                    <tr
                                        key={assDet.part_ref} 
                                        style={{"color": (currentRef === assDet.part_ref) ? "green" : "black"}}
                                        >
                                        <td className="dispPartsFiles">{assDet.part_ref}</td>
                                        <td className="dispPartsFiles">{assDet.part_des}</td>
                                        <td onClick={() => displayFileForm(assDet.part_ref)}>
                                            <span className="material-symbols-outlined">attach_file_add</span>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    )}   
                </div>
            </div>
        </div>
    )
}
