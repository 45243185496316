function controlText(inputText) { 
    var surText = inputText
            .replace(/&/g, "")
            .replace(/\$/g, "")
            .replace(/\|/g, "")
            .replace(/</g, "")
            .replace(/>/g, "")
            .replace(/;/g, "")
            .replace(/"/g, "")
            .replace(/\*/g, "")
            .replace(/{/g, "")
            .replace(/}/g, "")
            .replace(/\[/g, "")
            .replace(/\]/g, "")
    return surText;
}

export default controlText;