import React from "react";

export default function DispTextForm({quizContent, setQuizContent, buildQuizState}) {
    return (
        <div className="sheetLine">
            <span 
                className="lengthIndicator"
                style={{"color": quizContent.quiz.quiz && quizContent.quiz.quiz.length <= 2 ? "red" : "green"}}>
                {quizContent.quiz.quiz.length} / 200
            </span>
            <textarea cols="50" rows="4" id="textTypeTitle"
                placeholder="Saisissez ici votre question (200 caractères)"
                onChange={() => buildQuizState("text", quizContent, setQuizContent)} 
                value={quizContent.quiz.quiz ? quizContent.quiz.quiz : "" }/>
            <br/>
            <input type="text" 
                placeholder="Espace réponse (60 caractères)"
                className="artInput" 
                maxLength="60" size="32"
                style={{"marginRight": "20px"}}/>
        </div>
    )
}
