import controlText from "./controlText";

export default function buildQuizState (type, quizContent, setQuizContent) {
    if (type === "text" || type === "textarea") {
        setQuizContent({
            ...quizContent, 
            quiz: {
                type: type, 
                quiz: document.getElementById("textTypeTitle").value ? controlText(document.getElementById("textTypeTitle").value, 200) : "",
            } 
        });
    }
    if (type === "checkbox") {
        setQuizContent({
            ...quizContent, 
            quiz: {
                type: type, 
                quiz: document.getElementById("checkTypeTitle").value ? controlText(document.getElementById("checkTypeTitle").value, 200) : "",
                checkbox_1: document.getElementById("checkbox_1").value ? controlText(document.getElementById("checkbox_1").value, 30) : "",
                checkbox_2: document.getElementById("checkbox_2").value ? controlText(document.getElementById("checkbox_2").value, 30) : "",
                checkbox_3: document.getElementById("checkbox_3").value ? controlText(document.getElementById("checkbox_3").value, 30) : "",
                checkbox_4: document.getElementById("checkbox_4").value ? controlText(document.getElementById("checkbox_4").value, 30) : "",
                checkbox_5: document.getElementById("checkbox_5").value ? controlText(document.getElementById("checkbox_5").value, 30) : "",
                checkbox_6: document.getElementById("checkbox_6").value ? controlText(document.getElementById("checkbox_6").value, 30) : "",
                checkbox_7: document.getElementById("checkbox_7").value ? controlText(document.getElementById("checkbox_7").value, 30) : "",
                checkbox_8: document.getElementById("checkbox_8").value ? controlText(document.getElementById("checkbox_8").value, 30) : "",
                checkbox_9: document.getElementById("checkbox_9").value ? controlText(document.getElementById("checkbox_9").value, 30) : "",
                checkbox_10: document.getElementById("checkbox_10").value ? controlText(document.getElementById("checkbox_10").value, 30) : "",
            } 
        });
    }
    if (type === "radio") {
        setQuizContent({
            ...quizContent, 
            quiz: {
                type: type, 
                quiz: document.getElementById("radioTypeTitle").value ? controlText(document.getElementById("radioTypeTitle").value, 200) : "",
                radio_1: document.getElementById("radio_1").value ? controlText(document.getElementById("radio_1").value, 30) : "",
                radio_2: document.getElementById("radio_2").value ? controlText(document.getElementById("radio_2").value, 30) : "",
                radio_3: document.getElementById("radio_3").value ? controlText(document.getElementById("radio_3").value, 30) : "",
                radio_4: document.getElementById("radio_4").value ? controlText(document.getElementById("radio_4").value, 30) : "",
                radio_5: document.getElementById("radio_5").value ? controlText(document.getElementById("radio_5").value, 30) : ""
            } 
        });
    }
}
