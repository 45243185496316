import React from "react";

export default function QuizTypeChoice({quizType, setQuizType, setQuizMode, initQuizForm}) {
    return (
        <div>
            <p>Choisissez un type de question</p>
            <div className="quizTypeList">
                <span 
                    className="quizTypeChoice"
                    style={{"border": quizType === "text" ? "1px solid blue" : "none"}}
                    onClick={() => {
                        setQuizType("text");
                    }}>
                        Réponse à texte court (60 caractères)
                </span>
                <span 
                    className="quizTypeChoice"
                    style={{"border": quizType === "textarea" ? "1px solid blue" : "none"}}
                    onClick={() => {
                        setQuizType("textarea");
                    }}>
                        Réponse à texte long (500 caractères)
                </span>
                <span 
                    className="quizTypeChoice"
                    style={{"border": quizType === "checkbox" ? "1px solid blue" : "none"}}
                    onClick={() => {
                        setQuizType("checkbox");
                    }}>
                        Réponse à choix multiple (cases à cocher)
                </span>
                <span 
                    className="quizTypeChoice"
                    style={{"border": quizType === "radio" ? "1px solid blue" : "none"}}
                    onClick={() => {setQuizType("radio");
                    }}>
                        Réponse à choix unique (cases à cocher)
                </span>
                <span 
                    className="quizTypeChoice"
                    style={{"color": "red"}}
                    onClick={() => {
                        setQuizType(null);
                        setQuizMode("read");
                        initQuizForm();
                    
                    }}>
                        Initialiser l'outil de construction
                </span>
            </div>
        </div>
    )
}
