import { useContext, useEffect, useRef } from 'react';
import { UserContext } from './context/userContext';
import { initTask } from "./redux/reduxStore";
import { useDispatch } from "react-redux";
import axios from "axios";
import Cookies from 'universal-cookie';

export default function ApiConnexion({setGlobalState, globalState}) {

    const {currentUser, setCurrentUser, setApiIdentifier} = useContext(UserContext);
    const cookies = new Cookies();
    const sendData = useRef(null);
    const dispatch = useDispatch();
    const task = useRef(null);

    useEffect(() => {    
        if (currentUser.email && currentUser.token) {
            var avt = "personLg.png";
            (async () => {
                sendData.current = await axios({
                    method: 'post',
                    url: process.env.REACT_APP_SERVER_NAME,
                    params: {ur: 'ct'},
                    data: {
                        user: {
                            email: currentUser.email
                        }
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + currentUser.token
                    }
                }).then((resp) => {
                    if (resp.status === 200) {
                        if (resp.data.avatar && resp.data.avatar !== null) {
                            avt = resp.data.avatar;
                        }
                        if (currentUser.email === resp.data.email) {
                            if (resp.data.items) {
                                resp.data.items.map((item) => {
                                    task.current = {
                                        id: item.task_id,
                                        createdAt: item.created_at,
                                        docs: item.docs,
                                        loop: item.loop,
                                        currentTimerStarting: item.currentTimerStarting,
                                        timeCount: item.time_count,
                                        title: item.title,
                                        priority: item.priority,
                                        content: item.content,
                                        progress: item.progress
                                    }
                                    dispatch(initTask(task.current));
                                })
                            }
                            setCurrentUser({...currentUser, 
                                firstname: resp.data.firstname ? resp.data.firstname : "",
                                lastname: resp.data.lastname ? resp.data.lastname : "",
                                company: resp.data.company ? resp.data.company : "",
                                metier: resp.data.metier ? resp.data.metier : "",
                                phone: resp.data.phone ? resp.data.phone : "",
                                borndate: resp.data.borndate ? resp.data.borndate : "",
                                town: resp.data.town ? resp.data.town : "",
                                avatar: avt,
                                role: resp.data.role,
                                teamUid: resp.data.teamUid,
                                noveltyValidation: resp.data.noveltyValidation,
                                novelty: resp.data.novelty
                            })
                            setApiIdentifier(true);
                            setGlobalState({
                                ...globalState, 
                                dispName:(
                                    (resp.data.firstname && resp.data.firstname.length > 1 && 
                                     resp.data.lastname && resp.data.lastname.length > 1) ? 
                                    (resp.data.firstname + ' ' + resp.data.lastname).slice(0, 14) + "..." : 
                                    currentUser.email.slice(0, 14) + "..."),
                                themeNb: (parseInt(resp.data.theme)),
                                avatar: process.env.REACT_APP_SERVER_NAME.slice(0,-14) + "zavatars/" + avt
                            });
                        }
                    } else {
                        setApiIdentifier(false);
                    }
                })
            })()
        }   
        if (!currentUser &&
            cookies.get('managersysteme_keepconnection') && cookies.get('managersysteme_keepauthorization')) {
            (async () => {
                sendData.current = await axios({
                    method: 'post',
                    url: process.env.REACT_APP_SERVER_NAME,
                    params: {ur: 'ct'},
                    data: {
                        user: {
                            keepConnection: cookies.get('managersysteme_keepconnection'),
                            email: "authorization",
                        }
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + cookies.get('managersysteme_keepauthorization')
                    }
                
                }).then((resp) => {
                    if (resp.status === 200) {
                        if (resp.data.avatar && resp.data.avatar !== null) {
                            avt = resp.data.avatar;
                        }
                        setCurrentUser({
                                            ...currentUser, 
                                            email: resp.data.email, 
                                            firstname: resp.data.firstname ? resp.data.firstname : "",
                                            lastname: resp.data.lastname ? resp.data.lastname : "",
                                            company: resp.data.company ? resp.data.company : "",
                                            metier: resp.data.metier ? resp.data.metier : "",
                                            phone: resp.data.phone ? resp.data.phone : "",
                                            borndate: resp.data.borndate ? resp.data.borndate : "",
                                            town: resp.data.town ? resp.data.town : "",
                                            avatar: avt,
                                            role: resp.data.role,
                                            teamUid: resp.data.teamUid,
                                            token: resp.data.newToken,
                                            noveltyValidation: resp.data.noveltyValidation,
                                            novelty: resp.data.novelty
                                        });
                        if (resp.data.items) {
                            resp.data.items.map((item) => {
                                task.current = {
                                    id: item.task_id,
                                    createdAt: item.created_at,
                                    docs: item.docs,
                                    loop: item.loop,
                                    currentTimerStarting: item.currentTimerStarting,
                                    timeCount: item.time_count,
                                    title: item.title,
                                    priority: item.priority,
                                    content: item.content,
                                    progress: item.progress
                                }
                                cookies.set('managersysteme_keepauthorization', resp.data.newToken, Date.now() + 604800);
                                dispatch(initTask(task.current));
                            })
                        }
                        setGlobalState({
                            ...globalState, 
                            dispName:(
                                (resp.data.firstname && resp.data.firstname.length > 1 && 
                                    resp.data.lastname && resp.data.lastname.length > 1) ? 
                                (resp.data.firstname + ' ' + resp.data.lastname).slice(0, 14) + "..." : 
                                resp.data.email.slice(0, 14) + "..."),
                            themeNb: (parseInt(resp.data.theme)),
                            avatar: process.env.REACT_APP_SERVER_NAME.slice(0,-14) + "zavatars/" + avt
                        });
                        setApiIdentifier(true);
                    } else {
                        setApiIdentifier(false);
                    }
                })
            })()
        }
            
        return () => {
            sendData.current = null;
            task.current = null;
        }
    }, [currentUser])

  return ;
}
