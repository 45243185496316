import React, { useContext } from "react";
import { UserContext } from '../context/userContext';
import {useDispatch } from "react-redux";
import { deleteAllTasks } from "../redux/reduxStore";
import logOut from "./globalRequest/logOut";

export default function IdentityFunction({globalState}) {
    
    const {currentUser, setCurrentUser, setApiIdentifier} = useContext(UserContext);
    const dispatch = useDispatch();

    function getLogOut () {
        logOut(currentUser.email, currentUser.token);
        setApiIdentifier(false); 
        setCurrentUser(false);
        dispatch(deleteAllTasks()); 
    }

    return (
        <div className="identityDiv noselect" style={{"--personColor": globalState.theme.themes.identifiedColor[globalState.themeNb]}}>
            <div className="identity">
                <img src={globalState.avatar} alt="avatar" />
            </div>
            <div 
                className="displayName"
                style={{"--noticeTextColor": globalState.theme.themes.noticeTextColor[ globalState.themeNb],
                        "--noticeBgColor": globalState.theme.themes.noticeBgColor[ globalState.themeNb]
                        }}>
                {globalState.dispName}
            </div>
            <button 
                className="logOutBut"
                onClick={getLogOut}>
                    Déconnexion
            </button>
        </div>
    )
}
