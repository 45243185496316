import React from "react";
import initPassword from "./globalRequest/initPassword";
import controlText from "../todosComponents/TaskComponents/controlText";

export default function InitPassword({headerState, setHeaderState}) {

    function updateEmail (e) {
        setHeaderState({...headerState, tpEmail: (controlText(e.target.value))});
    }

  return (
    <div className="initP">
                    <p className="initTitle">Indiquez votre adresse mail:</p>
                    <input 
                        className="initInput" 
                        type="email" 
                        id="emailInitilization" 
                        name="email" 
                        size="25"
                        maxLength="100"
                        value={headerState.tpEmail}
                        onChange={updateEmail}
                    />
                    <p className="initText">
                        <span style={{"marginLeft": "20px"}}>
                            Après validation, vous recevrez un lien par mail qui vous permettra de valider un nouveau mot de passe.
                        </span>
                    </p>
                    <button 
                        onClick={() => initPassword(headerState, setHeaderState)} 
                        className="initBut" >
                        Valider
                    </button>
                </div>
  )
}
