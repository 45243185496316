import React, { useState } from "react";
import sendComment from "./globalRequest/sendComment";
import controlText from "../todosComponents/TaskComponents/controlText";

export default function Comment({currentUser, headerState, setHeaderState }) {

    const [comment, setComment] = useState("");

    function commentUpdate(e) {
        if(controlText(e.target.value).length < 400) {
          document.getElementById("commentText").value = controlText(e.target.value);
          setComment(controlText(e.target.value));
        } else {
          document.getElementById("commentText").value = controlText(e.target.value).slice(0, 400);
        }
      }

    return (
        <div className="commentContainer">
            <div className="commentZone">
                <span 
                    className="material-symbols-outlined closeComment" 
                    onClick={() => setHeaderState({...headerState, commentWindow: false})}
                    translate="no">
                        cancel
                </span>
                <h3>Commentaires</h3>
                <p>Nous sommes attentifs à votre expérience, exprimez vous.</p>
                <textarea 
                    rows="11" cols="36" 
                    className="commentText" 
                    onChange={commentUpdate} 
                    id="commentText" 
                    scrollable="no"></textarea>
                <button onClick={() => sendComment(currentUser, comment, headerState, setHeaderState)} className="commentValidate">Envoyer</button>
            </div>
            
        </div>
    )
}
