import axios from "axios";

async function addCommandInfo (currentUser, buildCommandInfo, setBuildCommandInfo, stockState, setStockState, action) {
    await axios({
        method: 'post', 
        url: process.env.REACT_APP_SERVER_NAME,
        params: {ci: 'bld'},
        data: {
            user_email: currentUser.email,
            user_teamUid: currentUser.teamUid,
            user_role: currentUser.role,
            action: action,
            commandInfoId: buildCommandInfo.commandInfoId,
            commandInfoQuantity: buildCommandInfo.commandInfoQuantity,
            commandInfoOrderRef: buildCommandInfo.commandInfoOrderRef,
            commandInfoOrderDate: buildCommandInfo.commandInfoOrderDate,
            commandInfoShipDate: buildCommandInfo.commandInfoShipDate,
            commandInfoGetDate: buildCommandInfo.commandInfoGetDate,
            paginator: {
                page: stockState.page,
                perPage: stockState.perPage
            },
            filter: {
                column: stockState.filter.column,
                value: stockState.filter.value,
                minQuant: stockState.filter.minQuant
            }
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentUser.token
        }
    }).then((resp) => {
        if (resp.status === 200) {
            setStockState({
                ...stockState,
                totalParts: resp.data.totalParts,
                stock: resp.data.stockAsked,
                refValues: resp.data.refValues,
                desValues: resp.data.desValues,
                catValues: resp.data.catValues,
                proValues: resp.data.proValues,
                old_message: stockState.message,
                message: resp.data.message
            });
            setBuildCommandInfo({
                ...buildCommandInfo, 
                commandInfoForm: false,
                commandInfoId: 0,
                commandInfoRef: "",
                commandInfoDes: "",
                commandInfoCat: "",
                commandInfoOrderRef: "",
                commandInfoOrderDate: "",
                commandInfoShipDate: "",
                commandInfoGetDate: "",
                commandInfoQuantity: ""
        });
        }
    })
}

export default addCommandInfo;