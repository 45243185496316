import axios from "axios";

async function subArticle (currentUser, id, stockState, setStockState) {
    await axios({
        method: 'post', 
        url: process.env.REACT_APP_SERVER_NAME,
        params: {st: 'delete'},
        data: {
            user_email: currentUser.email,
            user_teamUid: currentUser.teamUid,
            user_role: currentUser.role,
            action: "deleteValue",
            article: {
                id: id
            },
            paginator: {
                page: stockState.page,
                perPage: stockState.perPage
            },
            filter: {
                column: stockState.filter.column,
                value: stockState.filter.value,
                minQuant: stockState.filter.minQuant
            }
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentUser.token
        }
    }).then((resp) => {
        if (resp.status === 200) {
            setStockState({
                ...stockState,
                addMode: false,
                totalParts: resp.data.totalParts,
                stock: resp.data.stockAsked,
                old_message: stockState.message,
                message: resp.data.message
            });
        }
    })
}

export default subArticle;