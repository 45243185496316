import axios from "axios";

async function getAssembly (currentUser, setAssemblyList) {

    await axios({
        method: 'post', 
        url: process.env.REACT_APP_SERVER_NAME,
        params: {ass: 'read'},
        data: {
            user_email: currentUser.email,
            user_teamUid: currentUser.teamUid,
            user_role: currentUser.role,
            action: "readAssembly"
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentUser.token
        }
    }).then((resp) => {
        if (resp.status === 200 && resp.data.assemblyList) {
            setAssemblyList(resp.data.assemblyList);
        }
    })
}

export default getAssembly;