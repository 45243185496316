import axios from "axios";

async function deletePartFile({
        currentUser, 
        file,
        partFilesDetail, 
        setPartFilesDetail
    }) {
    await axios({
        method: 'post', 
        url: process.env.REACT_APP_SERVER_NAME,
        params: {ass: 'dpf'},
        data: {
            user_email: currentUser.email,
            user_teamUid: currentUser.teamUid,
            user_role: currentUser.role,
            action: "deletePartFile",
            file_name: file
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentUser.token
        }
    }).then((resp) => {
        if (resp.status === 200 && resp.data) {
            setPartFilesDetail({
                ...partFilesDetail, 
                message: resp.data.partFiles.message,
                existsPartsFiles: resp.data.partFiles.existsPartsFiles
            });
        }
    })
}

export default deletePartFile;