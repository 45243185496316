import axios from "axios";
import { addTask } from "../../redux/reduxStore";

function newTask (currentUser, dispatch) {

    var newId = Date.now().toString().slice(0,11);
    dispatch(addTask(newId));

    (async() => {     
      const createTask  = await axios({
            method: 'post', 
            url: process.env.REACT_APP_SERVER_NAME,
            params: {tk: 'update'},
            data: {
                user: {
                    email: currentUser.email,
                    fbToken: currentUser.accessToken
                },
                task: {
                    taskId: newId,
                    stateCounter: 0,
                    taskTitle: "Nouvelle tâche",
                    taskPriority: 10,
                    taskContent: "",
                    taskProgress: "A faire"
                }
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + currentUser.jwtToken
            }
        })
    })()

    return;
}
export default newTask;
