import React, { useEffect, useState } from "react";
import DispPrettyTime from "./DispPrettyTime";

export default function Counter({   taskId, 
                                    setTimer, 
                                    taskTimeCount, 
                                    taskState, 
                                    setTaskState }) {

    const [startStyle, setStartStyle] = useState('scale(1)');
    const [stopStyle, setStopStyle] = useState('scale(1)');
                                        
    useEffect (() => {
        if (taskState.startCounter) {
            setStartStyle('scale(0)');
            setStopStyle('scale(1)');
        } else {
            setStartStyle('scale(1)');
            setStopStyle('scale(0)');
        }
        
        return ;
    })
    return (
        <div className="counterZone">
            { (taskState.startCounter && !taskState.editMode) && (
                <span 
                    id={taskId} 
                    className="material-symbols-outlined timeBut turnBut" 
                    translate="no" 
                    onClick={() => setTaskState({...taskState, deployCounter: !taskState.deployCounter})}>
                        timer
                </span>
            )}   
            { !taskState.startCounter && (
                <span 
                    id={taskId} 
                    className="material-symbols-outlined timeBut"
                    translate="no"  
                    onClick={() => setTaskState({...taskState, deployCounter: !taskState.deployCounter})}>
                        timer
                </span>  
            )}
            { !taskState.deployCounter && (
                <span id="dispTp" >
                    <DispPrettyTime time={taskTimeCount} />
                </span>
            )}        
            { taskState.deployCounter && ( 
                <>
                    <div className="counterDeployed">
                        <div className="dispTime">
                            <DispPrettyTime time={taskTimeCount} />
                        </div>
                        <div className="dispTimeBut">
                            <button 
                                className="stopTimeBut" 
                                id={taskId} 
                                style={{"transform": stopStyle}} 
                                onClick={() => setTimer(false)} >
                                Stop
                            </button>
                            <button 
                                className="startTimeBut" 
                                id={taskId} 
                                style={{"transform": startStyle}} 
                                onClick={() => setTimer("start")}>
                                Start
                            </button>
                        </div> 
                    </div>
                </>
            )}
        </div>
    )
}
