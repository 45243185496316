import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { UserContext } from '../../context/userContext';
import removeTask from "../TasksRequest/removeTask";

export default function ModalDeletion({task, taskState, setTaskState}) {

    const { currentUser } = useContext(UserContext);
    const dispatch = useDispatch(); 

    return (
        <div className="modalDeletionStyle">
            <div style={{"textAlign": "center"}}>
                <h3 className="headModal">Vous allez supprimer la tâche :</h3>
                <p className="headModal">{task.title}</p>
            </div>
            <div className="modalCentr">
                <button onClick={() => removeTask("yes", currentUser, task, dispatch)} id="csvDelete" >
                    Fournir un .csv
                </button>
            </div>
            <div className="modalCentr">
                <button onClick={() => setTaskState({...taskState, modalDeletion: false})} id="cancelDelete">Annuler</button>
                <button onClick={() => removeTask("no", currentUser, task, dispatch)} id="confirmDelete">Confirmer</button>
            </div>
        </div>
    )
}
