import axios from "axios";

async function changeTheme (themeChoosen, currentUser) {

    await axios({
        method: 'post', 
        url: process.env.REACT_APP_SERVER_NAME,
        params: {th: 'change'},
        data: {
            theme: themeChoosen,
            user_email: currentUser.email
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentUser.token
        }
    })
}
export default changeTheme;