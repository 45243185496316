import React, { useRef, useState, useEffect, useContext } from "react";
import { UserContext } from '../context/userContext';
import PasswordReplace from './PasswordReplace';
import changeTheme from "../headerComponents/globalRequest/changeTheme";
import controlText from './controlText';
import controlAccountFormValidity from './controlAccountFormValidity';
import axios from "axios";

export default function AccountManagement({globalState, setGlobalState}) {

    const { currentUser, setCurrentUser} = useContext(UserContext);
    const [expandWindow, setExpandWindow] = useState({
        myAccount: false,
        managTeam: false,
        managData: false,
        other: false
    })
    const [pwdChanging, setPwdChanging] = useState({
        openWindow: false,
        actualPwd: "",
        newPwd1: "",
        newPwd2: ""
    });
    const [team, setTeam] = useState([]);
    const [loadDb, setLoadDb] = useState(true);
    const [loading, setLoading] = useState(false)
    const [waitFile, setWaitFile] = useState(false)
    const [downloadFile, setDownloadFile] = useState("")
    const newMember = useRef({});

    useEffect(() => {
        if (currentUser.role === "ROLE_MANAGER") {
            (async() => {     
                const getMembers  = await axios({
                    method: 'post', 
                    url: process.env.REACT_APP_SERVER_NAME,
                    params: {gt: 'mb'},
                    data: {
                        user_email: currentUser.email,
                        teamUid: currentUser.teamUid
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + currentUser.token
                    }
                }).then((resp) => {
                    if (resp.status === 200) {
                        setTeam(resp.data[0]);
                    }
                })
            })()
        }
      return ;
    }, [globalState.avatar, currentUser, newMember.current])

    function changeThemeDb (e) {
        setGlobalState({...globalState, themeNb: (e.target.value)})
        changeTheme(e.target.value, currentUser);
    }
    function changeAvatar (e) {
        if (["image/png", "image/jpeg"].includes(e.target.files[0].type) && e.target.files[0].size < 3000000) {
            (async() => {     
                const sendFile  = await axios({
                    method: 'post', 
                    url: process.env.REACT_APP_SERVER_NAME,
                    params: {av: 'av'},
                    data: {
                        user_email: currentUser.email,
                        fileUpload: e.target.files[0]
                    },
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Accept': 'multipart/form-data',
                        'Authorization': 'Bearer ' + currentUser.token
                    }
                }).then((resp) => {
                    if (resp.status === 200) {
                        setGlobalState({
                        ...globalState, 
                        avatar: process.env.REACT_APP_SERVER_NAME.slice(0,-14) + "zavatars/" + resp.data.avatar
                        });
                    }
                })
            })()
        }
    }
    function saveUserData () {
        if (controlAccountFormValidity(currentUser)) {
            if (!currentUser.borndate) {
                currentUser.borndate = "00/00/00";
            }
            (async() => {     
                const sendUserData  = await axios({
                    method: 'post', 
                    url: process.env.REACT_APP_SERVER_NAME,
                    params: {ac: 'acData'},
                    data: {
                        user_email: currentUser.email ? currentUser.email: "",
                        firstname: currentUser.firstname ? currentUser.firstname: "",
                        lastname: currentUser.lastname ? currentUser.lastname: "",
                        town: currentUser.town ? currentUser.town: "",
                        company: currentUser.company ? currentUser.company: "",
                        metier: currentUser.metier ? currentUser.metier: "",
                        phone: currentUser.phone ? currentUser.phone: "",
                        borndate: currentUser.borndate ? currentUser.borndate: "00/00/00",
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + currentUser.token
                    }
                }).then((resp) => {
                    if (resp.status === 200) {
                        if (resp.data.teamUid.length > 1) {
                            setCurrentUser({...currentUser, teamUid: resp.data.teamUid});
                        }
                    }
                })
            })()
        }
    }
    function initAvatar () {
        (async() => {     
            const sendFile  = await axios({
                method: 'post', 
                url: process.env.REACT_APP_SERVER_NAME,
                params: {av: 'init'},
                data: {
                    user_email: currentUser.email,
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + currentUser.token
                }
            }).then ((resp) => {
                if (resp.status === 200) {
                    setGlobalState({...globalState, avatar: process.env.REACT_APP_SERVER_NAME.slice(0,-14) + "zavatars/" + "personLg.png"});
                }
            })
        })()
    }
    function expandAccountManagement () {
        setExpandWindow({...expandWindow, managTeam: !expandWindow.managTeam})
        if (!expandWindow.managTeam && window.innerWidth > 785 ) {
            document.getElementById("accountManagement").style.height = "410px"
        } else if (!expandWindow.managTeam && window.innerWidth < 785 ) {
            document.getElementById("accountManagement").style.height = "610px"
        } else {
            document.getElementById("accountManagement").style.height = "30px"
        }
    }
    function expandTeamManagement () {
        setExpandWindow({...expandWindow, managTeam: !expandWindow.managTeam})
        if (!expandWindow.managTeam) {
            document.getElementById("teamManagement").style.height = "330px"
        } else {
            document.getElementById("teamManagement").style.height = "30px"
        }
    }
    function expandDataManagement () {
        setExpandWindow({...expandWindow, managData: !expandWindow.managData})
        if (!expandWindow.managData) {
            document.getElementById("dataManagement").style.height = "220px"
        } else {
            document.getElementById("dataManagement").style.height = "30px"
        }
    }
    function expandOtherManagement () {
        setExpandWindow({...expandWindow, other: !expandWindow.other})
        if (!expandWindow.other) {
            document.getElementById("otherManagement").style.height = "130px"
        } else {
            document.getElementById("otherManagement").style.height = "30px"
        }
    }
    function teamCreation () {
        (async() => {     
            const teamCreate  = await axios({
                method: 'post', 
                url: process.env.REACT_APP_SERVER_NAME,
                params: {te: 'ce'},
                data: {
                    user_email: currentUser.email,
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + currentUser.token
                }
            }).then ((resp) => {
                if (resp.status === 200) {
                    
                }
            })
        })()
    }
    function addMember () {
         if (document.getElementById("newMemberEmail").value.length > 1 &&
            document.getElementById("newMemberFirstname").value.length > 1 &&
            document.getElementById("newMemberLastname").value.length > 1) {
                newMember.current = {
                    email: document.getElementById("newMemberEmail").value,
                    firstname: document.getElementById("newMemberFirstname").value,
                    lastname: document.getElementById("newMemberLastname").value,
                };
        } else {
            if (document.getElementById("newMemberEmail").value.length <= 1) {
                document.getElementById("newMemberEmail").style.borderBottom = "2px solid red";
            } else {
                document.getElementById("newMemberEmail").style.borderBottom = "2px solid lightgreen";
            }
            if (document.getElementById("newMemberFirstname").value.length <= 1) {
                document.getElementById("newMemberFirstname").style.borderBottom = "2px solid red";
            } else {
                document.getElementById("newMemberFirstname").style.borderBottom = "2px solid lightgreen";
            }
            if (document.getElementById("newMemberLastname").value.length <= 1) {
                document.getElementById("newMemberLastname").style.borderBottom = "2px solid red";
            } else {
                document.getElementById("newMemberLastname").style.borderBottom = "2px solid lightgreen";
            }

            return ;
        }
        
        (async() => {     
            const teamAddMb  = await axios({
                method: 'post', 
                url: process.env.REACT_APP_SERVER_NAME,
                params: {te: 'admb'},
                data: {
                    user_email: currentUser.email,
                    teamUid: currentUser.teamUid,
                    newMember: newMember.current
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + currentUser.token
                }
            }).then ((resp) => {
                if (resp.status === 200) {
                    setTeam(resp.data[0]);
                    document.getElementById("newMemberEmail").value = "";
                    document.getElementById("newMemberFirstname").value = "";
                    document.getElementById("newMemberLastname").value ="";
                    document.getElementById("newMemberEmail").style.borderBottom = "2px solid lightgrey";
                    document.getElementById("newMemberFirstname").style.borderBottom = "2px solid lightgrey";
                    document.getElementById("newMemberLastname").style.borderBottom = "2px solid lightgrey";
                }
            })
        })()
    }
    function callMember(email) {
        (async() => {     
            const teamAddMb  = await axios({
                method: 'post', 
                url: process.env.REACT_APP_SERVER_NAME,
                params: {te: 'camb'},
                data: {
                    user_email: currentUser.email,
                    teamUid: currentUser.teamUid,
                    newMember: email
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + currentUser.token
                }
            }).then ((resp) => {
                if (resp.status === 200) {
                    
                }
            })
        })()
    }
    function delMember(email) {
        (async() => {     
            const teamDelMb  = await axios({
                method: 'post', 
                url: process.env.REACT_APP_SERVER_NAME,
                params: {te: 'demb'},
                data: {
                    user_email: currentUser.email,
                    teamUid: currentUser.teamUid,
                    delEmail: email
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + currentUser.token
                }
            }).then ((resp) => {
                if (resp.status === 200) {
                    setTeam(resp.data[0]);
                }
            })
        })()
    }
    function sendExcelDb (e) {
        if (e.target.files[0].type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ) {
            setLoading(true);
            (async() => {     
                const sendDb  = await axios({
                    method: 'post', 
                    url: process.env.REACT_APP_SERVER_NAME,
                    params: {db: 'load'},
                    data: {
                        user_email: currentUser.email,
                        user_teamUid: currentUser.teamUid,
                        fileUpload: e.target.files[0]
                    },
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Accept': 'multipart/form-data',
                        'Authorization': 'Bearer ' + currentUser.token
                    }
                }).then((resp) => {
                    if(resp.status === 200) {
                        setLoadDb(false);
                        setLoading(false);
                    }
                    if(resp.status !== 200) {
                        setLoadDb(true);
                        setLoading(false);
                    }
                })
            })()
        }
    }
    function downloadDbInXlsx () {
        (async() => {     
            setWaitFile(true);
            const downloadXlsxDb  = await axios({
                method: 'post', 
                url: process.env.REACT_APP_SERVER_NAME,
                params: {db: 'dl'},
                data: {
                    user_email: currentUser.email,
                    user_teamUid: currentUser.teamUid,
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + currentUser.token
                }
            }).then ((resp) => {
                if (resp.status === 200) {
                    setWaitFile(false);
                    setDownloadFile(resp.data.filename)
                }
            })
        })()
    }

    return (
        <>
            { pwdChanging.openWindow && (
                <PasswordReplace 
                currentUser={currentUser}
                    pwdChanging={pwdChanging} 
                    setPwdChanging={setPwdChanging} />
            )}
            <div 
                className="accountH3Title"
                style={{"--noticeTextColor": globalState.theme.themes.noticeTextColor[ globalState.themeNb]}} >
                <h3>Ci dessous vos données</h3>
            </div>
            <div className="accountHeader">
                <div className="themeZone unitFilterZone">
                    <select className="selectTheme" onChange={changeThemeDb}>
                        <option className="selectOption" value="2">Votre thème?</option>
                        <option className="selectOption" value="2">Thème clair</option>
                        <option className="selectOption" value="1">Thème gris</option>
                        <option className="selectOption" value="0">Thème sombre</option>
                    </select>
                </div>
            </div>
            <br/>


            <div 
                className="accountFormLine noselect"
                id="accountManagement"
                style={{
                        "--accountFormBgColor": globalState.theme.themes.accountFormBgColor[ globalState.themeNb]
                        }}>
                <div 
                    className="managerCreationTitle">
                    <span 
                        className="accountExpand material-symbols-outlined"
                        onClick={expandAccountManagement}>
                            expand
                    </span>
                    <h5>Gérer votre compte</h5>
                    <div className="accountHelp">
                        <span className="material-symbols-outlined">contact_support</span>
                        <p>
                            <span style={{"marginLeft": "30px"}}></span>Un compte utilisateur fonctionne avec une adresse mail valide, vous pouvez choisir de compléter votre profil ou pas.<br/>
                            Pour créer une équipe, vous avez besoin d'un compte manager. Les données supplémentaires des champs marqués ( <strong style={{"color": "red"}}>*</strong> ) sont nécessaires.
                        </p>
                    </div>
                </div>
                <div className="accountManagementContent">
                    <div className="accountFormLeft">
                        <div className="accountFormUnity">
                            <span className="dispAccountEmail">{currentUser.email}</span>
                        </div>
                        <div className="accountFormUnity">
                            <strong style={{"color": "red"}}>* </strong>
                            <label htmlFor="accountLastname" id="accountLastnameLb" >Nom: </label>
                            <input 
                                type="text" 
                                id="accountLastnameInp"
                                name="accountLastname" 
                                onChange={(e) => setCurrentUser({...currentUser, lastname: controlText(e.target.value)})}
                                value={currentUser.lastname} />
                        </div>
                        <div className="accountFormUnity">
                            <strong style={{"color": "red"}}>* </strong>
                            <label htmlFor="accountFirstname" id="accountFirstnameLb">Prénom: </label>
                            <input 
                                type="text" 
                                id="accountFirstnameInp"
                                name="accountFirstname" 
                                onChange={(e) => setCurrentUser({...currentUser, firstname: controlText(e.target.value)})}
                                value={currentUser.firstname} />
                        </div>
                        <div className="accountFormUnity">
                            <strong style={{"color": "red"}}>* </strong>
                            <label htmlFor="accountTown" id="accountTownLb">Ville: </label>
                            <input 
                                type="text" 
                                id="accountTownInp"
                                name="accountTown" 
                                onChange={(e) => setCurrentUser({...currentUser, town: controlText(e.target.value)})}
                                value={currentUser.town} />
                        </div>
                        <div className="accountFormUnity">
                            <strong style={{"color": "red"}}>* </strong>
                            <label htmlFor="accountCompany" id="accountCompanyLb">Entreprise: </label>
                            <input 
                                type="text" 
                                id="accountCompanyInp"
                                name="accountCompany" 
                                onChange={(e) => setCurrentUser({...currentUser, company: controlText(e.target.value)})}
                                value={currentUser.company} />
                        </div>
                        <div className="accountFormUnity">
                            <strong style={{"color": "red"}}>* </strong>
                            <label htmlFor="accountMetier" id="accountMetierLb">Métier: </label>
                            <input 
                                type="text" 
                                id="accountMetierInp"
                                name="accountMetier" 
                                onChange={(e) => setCurrentUser({...currentUser, metier: controlText(e.target.value)})}
                                value={currentUser.metier} />
                        </div>
                        <div className="accountFormUnity">
                            <strong style={{"color": "red"}}>* </strong>
                            <label htmlFor="accountPhone" id="accountPhoneLb">Téléphone: </label>
                            <input 
                                type="text" 
                                id="accountPhoneInp"
                                name="accountPhone" 
                                onChange={(e) => setCurrentUser({...currentUser, phone: controlText(e.target.value)})}
                                value={currentUser.phone} />
                        </div>
                        <div className="accountFormUnity">
                            <label htmlFor="accountBorndate">Date de Naissance: </label>
                            <input 
                                type="date" 
                                name="accountBorndate" 
                                onChange={(e) => setCurrentUser({...currentUser, borndate: controlText(e.target.value)})}
                                value={currentUser.borndate} />
                        </div>
                        <button 
                            className="modifyPwd" 
                            onClick={() => setPwdChanging({...pwdChanging, openWindow: !pwdChanging.openWindow})}>
                            Modifier votre mot de passe
                        </button>
                        <button className="accountSaving" onClick={saveUserData}>
                            <span className="accountSavingSymb material-symbols-outlined">save</span>
                            <span className="accountSavingText">Enregistrer</span>
                        </button>
                    </div>
                    <div className="accountFormRight">
                        <div className="accountFormUnity">
                            <label htmlFor="avatar">Votre avatar: <strong>( <i>.png</i> , <i>.jpg</i> , <i>3Mo max</i> )</strong></label>
                            <br/><br/>
                            <input type="file" name="avatar" onChange={(e) => changeAvatar(e)} />
                        </div>
                        <div className="accountFormUnity dispAvatar">
                            <img src={globalState.avatar}  alt="avatar"/>
                        </div>
                        <div className="avatarDeletion">
                            <button onClick={initAvatar}>Supprimer mon avatar</button>
                        </div>
                    </div>
                </div>
            </div>
            { (currentUser.teamUid && currentUser.teamUid !== "" && currentUser.teamUid !== "none" && currentUser.role !== "ROLE_COLLABO") && (
                <div 
                    className="managerCreation noselect"
                    id="teamManagement"
                    style={{
                            "--accountFormBgColor": globalState.theme.themes.accountFormBgColor[ globalState.themeNb]
                            }}>
                    <div 
                        className="managerCreationTitle">
                        <span 
                            className="accountExpand material-symbols-outlined"
                            onClick={expandTeamManagement}>
                                expand
                        </span>
                        <h5>Gérer votre équipe</h5>
                        <div className="accountHelp">
                            <span className="material-symbols-outlined">contact_support</span>
                            <p>
                                <span style={{"marginLeft": "30px"}}></span>Vous avez créé votre équipe et vous en êtes le manager. Vous pouvez ajouter des membres en saisissant leur emails, prénoms et noms. Lorsque vous cliquerez sur <span style={{"background": "rgb(123, 158, 245)", "padding": "0 4px"}}>Inviter</span>, le membre choisi recevra un mail d'authentification avec un lien de type "cliquez ici" qui lui permettra d'initialiser son compte. Faites lui éventuellement consulter les spams de sa messagerie.<br></br>
                                Il sera prudent que ce nouveau membre commence par <strong>personnaliser son mot de passe</strong>.<br></br>
                                La colonne "actif" vous indique 1 si le nouveau membre s'est bien authentifié.
                            </p>
                        </div>
                    </div>
                    { (currentUser.teamUid !== "" && currentUser.teamUid !== "none" && currentUser.role === "ROLE_USER") && (
                        
                        <div>
                            <button 
                                className="buttonType1"
                                onClick={teamCreation}>
                                Cliquer ici pour créer une équipe
                            </button>
                        </div>
                    )}
                    { (currentUser.teamUid !== "" && currentUser.teamUid !== "none" && currentUser.role === "ROLE_MANAGER") && (
                        <>
                            <p><span style={{"marginLeft": "30px", "lineHeight": "0.1em"}}></span>Ajouter un membre</p>
                            <div className="teamAddMemberForm">
                                <div className="accountFormUnity">
                                    <strong style={{"color": "red"}}>* </strong>
                                    <label htmlFor="teamNewMember" id="teamNewMemberLb">Email: </label>
                                    <input type="email" name="teamNewMember" id="newMemberEmail" placeholder="john.doe@nouveau.fr" />
                                </div>
                                <div className="accountFormUnity">
                                    <strong style={{"color": "red"}}>* </strong>
                                    <label htmlFor="teamNewMember" id="teamNewMemberLb">Prénom: </label>
                                    <input type="text" name="teamNewMember" id="newMemberFirstname" placeholder="John" />
                                </div>
                                <div className="accountFormUnity">
                                    <strong style={{"color": "red"}}>* </strong>
                                    <label htmlFor="teamNewMember" id="teamNewMemberLb">Nom: </label>
                                    <input type="text" name="teamNewMember" id="newMemberLastname" placeholder="Doe" />
                                </div>
                            </div>
                            <button className="addMemberBut" onClick={addMember}>Ajouter</button>
                            <br/>
                            <div className="memberTable">
                                <table>
                                    <thead>
                                        <tr>
                                            <td className="memberTd"></td>
                                            <td className="memberTd">Email</td>
                                            <td className="memberTd">Prénom</td>
                                            <td className="memberTd">Nom</td>
                                            <td className="memberTd">Rôle</td>
                                            <td className="memberTd">Actif</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        
                                        { team.map((member, index) => (
                                            <tr key={index}>
                                                <td className="memberTd" style={{"width": "112px", "display": "flex"}}>
                                                { !member.is_verified && (
                                                    <button id="callMember" onClick={() => callMember(member.email)}>Inviter</button>
                                                )}
                                                <button id="delMember" onClick={() => delMember(member.email)}>Supprimer</button>
                                                </td>
                                                <td className="memberTd" style={{"width": "80px"}}>{member.email.slice(0, 14)+"..."}</td>
                                                <td className="memberTd" style={{"width": "80px"}}>{member.firstname ? member.firstname.slice(0, 14) : ""}</td>
                                                <td className="memberTd" style={{"width": "80px"}}>{member.lastname ? member.lastname.slice(0, 14) : ""}</td>
                                                <td className="memberTd" style={{"width": "80px"}}>{member.role.slice(5, 14)}</td>
                                                <td className="memberTd" style={{"width": "30px"}}>{member.is_verified}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    )}
                </div>
            )}
            { (currentUser.teamUid && currentUser.teamUid !== "" && currentUser.teamUid !== "none" && currentUser.role === "ROLE_MANAGER") && (
           
                <div 
                    className="managerCreation noselect"
                    id="dataManagement"
                    style={{
                            "--accountFormBgColor": globalState.theme.themes.accountFormBgColor[ globalState.themeNb]
                            }}>
                    <div className="managerCreationTitle">
                        <span 
                            className="accountExpand material-symbols-outlined"
                            onClick={expandDataManagement}>
                                expand
                        </span>
                        <h5>Gérer vos données</h5>
                        <div className="accountHelp">
                            <span className="material-symbols-outlined">contact_support</span>
                            <p>
                                <span style={{"marginLeft": "30px"}}></span>Attention aux 
                                conditions de reconnaissance de votre fichier <strong>mon_fichier.xlsx</strong>. Le nom des colonnes doit respecter l'orthographe suivant (accents, majuscules etc...) :<br></br>
                                <span style={{"color": "blue", "fontWeight": "600", "fontSize": "14px"}}>Référence - Désignation - Catégorie - Quantité - Quantité minimum - Délai - Fournisseur</span><br></br>
                                Les quantités et délais doivent contenir uniquement des caractères numériques.<br></br>Les références sont uniques. Si vous importez un autre fichier,
                                les références existantes seront écrasées.
                            </p>
                        </div>
                    </div>
                    <div className="importTemplate noselect">
                        <a 
                            href={process.env.REACT_APP_SERVER_NAME.slice(0, 33)+ "zDbSources/TemplateImportStock.xlsx"} 
                            id="downloadTemplate"
                            download>
                            Télécharger un format en .xlsx
                        </a>
                    </div>
                    { (loadDb && !loading) && (
                        <div className="importExportStock noselect">
                            <div className="importStock">
                                <label htmlFor="loadDataXlsx">Importer mes données (.xlsx / .xlsm)</label>
                                <input type="file" name="loadDataXlsx" onChange={(e) => sendExcelDb(e)} />
                            </div>
                        </div>
                    )}
                    { (loadDb && loading) && (
                        <div className="importExportStock noselect">
                            <div className="importStock">
                                <p id="animLoadingXlsx">En cours ...</p>
                            </div>
                        </div>
                    )} 
                    { (!loadDb && !loading) && (
                        <div className="importExportStock noselect">
                            <div className="importStock">
                                <p>Fichier importé avec succés</p>
                            </div>
                        </div>
                    )} 
                    <div className="importExportStock noselect">
                        <div className="importStock">
                            <button name="loadDataXlsx" onClick={downloadDbInXlsx}>
                                Exporter la base de données
                            </button>
                            { waitFile && (
                                <p id="animLoadingXlsx">En cours ...</p>
                            )}
                            { (downloadFile !== "") && (
                                <a href={process.env.REACT_APP_SERVER_NAME.slice(0,-14) + downloadFile} download>Exporter</a>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
