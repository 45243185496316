import React from "react";

export default function DeployButton({expand, taskState}) {
  
    return (
        <div>
              <div className="deployZone" onClick={expand}>
                  <span className="material-symbols-outlined deployIcon" translate="no">
                      {(taskState.deploy === "90px") ? "step_into" : "step_out"}
                  </span>
              </div>
        </div>
    )
}
