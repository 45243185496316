import React, { useState, useEffect, useContext, useRef } from "react";
import AddStockFunction from "./AddStockFunction";
import CommandForm from "./CommandForm";
import ManagProvider from "./ManagProvider";
import PartTable from "./PartTable";
import Assemblages from "./Assemblages";
import controlText from './controlText';
import getArticles from './stockRequest/getArticles';
import CommandInfo from './CommandInfo';
import { UserContext } from '../context/userContext';

export default function Stock({globalState, setGlobalState}) {

    const { currentUser } = useContext(UserContext);
    const [quantChange, setQuantChange] = useState(0);
    const [paginatorAutoriz, setPaginatorAutoriz] = useState(true);
    const [stockType, setStockType] = useState("pieces");
    const [currentId, setCurrentId] = useState(0);
    const [currentProvider, setCurrentProvider] = useState("");
    const [openCommandForm, setOpenCommandForm] = useState(false);
    const [stockState, setStockState] = useState({
        totalParts: 0,
        reverseStock: false,
        quantAlert: 0,
        refValues: [],
        refTextValues: [],
        desValues: [],
        desTextValues: [],
        catValues: [],
        catTextValues: [],
        proValues: [], 
        proTextValues: [], 
        filter: {
                column: "",
                value: "",
                minQuant: false
            },
        page: 1,
        perPage: 10,
        addMode: false,
        stock: [],
        message: "-",
        old_message: ""
    });
    const [updateDate, setUpdateDate] = useState("_");
    const [managProvider, setManagProvider] = useState({
        displayProvider: false,
        providerSelected: {},
        providerList: []
    });
    const [newData, setNewData] = useState({tradeName: ""});
    const [textFetch, setTextFetch] = useState({
        refTextFetch: "",
        desTextFetch: "",
        catTextFetch: "",
        proTextFetch: "",
    });
    const [buildCommandInfo, setBuildCommandInfo] = useState({
        commandInfoForm: false,
        commandInfoId: 0,
        commandInfoAuthorAvt: "",
        commandInfoRef: "",
        commandInfoDes: "",
        commandInfoCat: "",
        commandInfoOrderRef: "",
        commandInfoOrderDate: "",
        commandInfoShipDate: "",
        commandInfoGetDate: "",
        commandInfoQuantity: ""
    });
    const [userHoverTable, setUserHoverTable] = useState(false);
    const autoRefresh = useRef(null);
    
    useEffect(() => {
        if (paginatorAutoriz) {
            getArticles (setPaginatorAutoriz, currentUser, stockState, setStockState, setUpdateDate, stockState.filter.minQuant);
        }

        return () => {
            setCurrentId(null);
            if(autoRefresh.current) {
                clearInterval(autoRefresh.current);
            }
        };
    }, [quantChange, stockState.perPage, stockState.page, stockState.filter.value, stockState.filter.minQuant, textFetch])

    function manageRefreshing () {
        if (!stockState.filter.minQuant &&
            !userHoverTable && 
            !stockState.addMode && 
            !managProvider.displayProvider && 
            !buildCommandInfo.commandInfoForm &&
            !openCommandForm) {

            setUserHoverTable(true);
            if (paginatorAutoriz) {
                autoRefresh.current = setInterval(
                    () => {
                            getArticles (setPaginatorAutoriz, currentUser, stockState, setStockState, setUpdateDate, stockState.filter.minQuant);
                        }
                    , 4000
                ); 
            } else {
                setTimeout( () => {
                    getArticles (setPaginatorAutoriz, currentUser, stockState, setStockState, setUpdateDate, stockState.filter.minQuant);
                }, 1000)
            }
        }
    }
    function stopRefreshing () {
        setUserHoverTable(false);
        clearInterval(autoRefresh.current);
    }
    function changeStockType () {
        setStockType(stockType === "pieces" ? "assemblages" : "pieces")
    }
    
    return (
        <>
            { stockType === "pieces" && stockState.old_message !== "" && (
                <p className="apiCommunicationOldMessage">action d'avant: {stockState.old_message}</p>
            )}
            { stockType === "pieces" && stockState.message === "Loading ..." && (
                <p className="apiCommunicationMessage loadAnimation">Action: {stockState.message}</p>
            )}
            { stockType === "pieces" && stockState.message !== "Loading ..." && (
                <p className="apiCommunicationMessage">Action: {stockState.message}</p>
            )}
            
            { (true) && (
                <div className="stockType noselect">
                    <span 
                        className="showMountage" 
                        onClick={() => changeStockType()}>
                        { stockType === "pieces" ? "Consulter les assemblages" : "Consulter les pieces"}
                    </span>
                </div>
            )}
            <div>
                <div className="stockContainer">
                    { stockState.addMode && (
                        <AddStockFunction 
                            id={currentId}
                            stockState={stockState} 
                            setStockState={setStockState}
                            stopRefreshing={stopRefreshing} />
                    )}
                    { managProvider.displayProvider && (
                        <ManagProvider 
                            newData={newData}
                            setNewData={setNewData}
                            currentUser={currentUser}
                            stockState={stockState}
                            setStockState={setStockState}
                            controlText={controlText}
                            managProvider={managProvider} 
                            setManagProvider={setManagProvider} />
                    )}
                    { buildCommandInfo.commandInfoForm && (
                        <CommandInfo
                            currentUser={currentUser}
                            stockState={stockState}
                            setStockState={setStockState}
                            buildCommandInfo={buildCommandInfo}
                            setBuildCommandInfo={setBuildCommandInfo} />
                    )}
                    { stockType === "pieces" && (
                        <PartTable 
                            manageRefreshing={manageRefreshing}
                            stopRefreshing={stopRefreshing}
                            currentUser={currentUser}
                            stockState={stockState}
                            setStockState={setStockState}
                            userHoverTable={userHoverTable}
                            updateDate={updateDate}
                            setCurrentId={setCurrentId}
                            textFetch={textFetch}
                            setTextFetch={setTextFetch}
                            quantChange={quantChange}
                            setQuantChange={setQuantChange}
                            setUserHoverTable={setUserHoverTable}
                            buildCommandInfo={buildCommandInfo}
                            setBuildCommandInfo={setBuildCommandInfo}
                            openCommandForm={openCommandForm}
                            setOpenCommandForm={setOpenCommandForm}
                            setCurrentProvider={setCurrentProvider}
                            setManagProvider={setManagProvider} />
                    )}
                    { (stockType === "assemblages") && ( 
                        <Assemblages 
                            currentUser={currentUser} />
                    )}
                    { openCommandForm && (
                        <CommandForm 
                            currentUser={currentUser}
                            currentProvider={currentProvider}
                            openCommandForm={openCommandForm} 
                            setOpenCommandForm={setOpenCommandForm} 
                            currentId={currentId}
                            setCurrentId={setCurrentId}
                            stockState={stockState} 
                            setStockState={setStockState} /> )}
                </div>
            </div>
        </>
    )
}